import A from '../../constants/actions';
import M from '../../constants/mutations';
import Features from '../../config/features';
import ThemeConfig from '../../config/themeConfig';
import GeoserverLayersService from "@/services/geoserverLayersService";

const processTheme = (theme) => {
  const mapFeatures = Features.getMapFeatures();
  theme.dynamicFeatureStylingConfigs = theme.stylingConfigs
    .filter(stylingConfig => !mapFeatures.some(mapFeature => mapFeature.id === stylingConfig.layer));
  theme.stylingConfigs = Features.getMapFeatures().map((layer) => {
    let layerStyle = theme.stylingConfigs.find((layerStyle) => {
      return layerStyle.layer === layer.id;
    });
    const feature = Features.getFeature(layer.id);
    return (layerStyle) ? layerStyle : {
      layer: layer.id,
      ...JSON.parse(JSON.stringify((feature?.defaultStyle) ? feature.defaultStyle() : ThemeConfig.defaultStyling)),
    };
  });
  return theme;
};

const defaultTheme = processTheme({
  baseMap: '0',
  id: 'Default',
  name: "SmartSIS Default",
  dayTheme: true,
  editable: false,
  stylingConfigs: [],
});

const sortFunction = (themeA, themeB) => {
  return (themeA.name === themeB.name) ? 0 : (themeA.name > themeB.name) ? 1 : -1;
};

const state = {
  themes: [
    defaultTheme,
  ],
  currentTheme: defaultTheme,
  originalTheme: JSON.parse(JSON.stringify(defaultTheme)),
  defaultThemeForUser: null,
};

const actions = {

  [A.THEME_READ_ALL_THEMES]({commit}, themes) {
    commit(M.THEME_READ_ALL_THEMES, themes);
    commit(M.THEME_UPDATE_DYNAMIC_LAYER_CONFIGS);
  },

  [A.THEME_UPDATE_DYNAMIC_LAYER_CONFIGS]({commit}) {
    commit(M.THEME_UPDATE_DYNAMIC_LAYER_CONFIGS);
  },

  [A.THEME_CHANGE_DEFAULT_THEME]({commit}, defaultTheme) {
    commit(M.THEME_CHANGE_DEFAULT_THEME, defaultTheme);
  },

  [A.THEME_SELECT_THEME]({dispatch, commit, state}, themeId) {
    commit(M.THEME_SELECT_THEME, themeId);
    state.currentTheme.stylingConfigs.forEach((config) => {
      dispatch(A.THEME_UPDATE_LAYER_STYLING, config);
    });
    dispatch(A.UPDATE_BASE_MAP, state.currentTheme.baseMap);
  },

  [A.THEME_UPDATE_LAYER_STYLING]({commit}, stylingConfig) {
    commit(M.THEME_UPDATE_LAYER_STYLING, stylingConfig);
  },

  [A.THEME_SAVE_THEME]({commit}) {
    commit(M.THEME_SAVE_THEME);
  },

  [A.THEME_SAVE_THEME_AS]({commit}, newTheme) {
    commit(M.THEME_SAVE_THEME_AS, newTheme);
  },

  [A.THEME_CANCEL_EDIT]({dispatch, commit, state}) {
    commit(M.THEME_CANCEL_EDIT);
    state.currentTheme.stylingConfigs.forEach(stylingConfig => dispatch(M.THEME_UPDATE_LAYER_STYLING, stylingConfig));
    dispatch(A.UPDATE_BASE_MAP, state.currentTheme.baseMap);
  },

  [A.THEME_DELETE_THEME]({commit}, themeId) {
    commit(M.THEME_DELETE_THEME, themeId);
  },

  [A.THEME_UPDATE_NAME]({commit}, name) {
    commit(M.THEME_UPDATE_NAME, name);
  },

  [A.THEME_SWITCH_STYLE]({commit}) {
    commit(M.THEME_SWITCH_STYLE);
  }

};

const mutations = {

  [M.THEME_CANCEL_EDIT](state) {
    const index = state.themes.findIndex((theme) => theme.id === state.currentTheme.id);
    const originalTheme = JSON.parse(JSON.stringify(state.originalTheme));

    state.themes.splice(index, 1, originalTheme);
    state.currentTheme = state.themes[index];
  },

  [M.THEME_READ_ALL_THEMES](state, themes) {
    themes.sort(sortFunction);
    state.themes = themes.map((theme) => {
      return processTheme(theme);
    });
    state.currentTheme = defaultTheme;

    if (state.themes.length === 0) {
      state.themes.push(defaultTheme);
    }
  },

  [M.THEME_UPDATE_DYNAMIC_LAYER_CONFIGS](state) {
    state.themes = GeoserverLayersService.updateThemesWithGeoserverLayersStylingConfigs(state.themes);
    if (state.currentTheme.id === defaultTheme.id) {
      state.currentTheme = GeoserverLayersService.updateThemesWithGeoserverLayersStylingConfigs([defaultTheme])[0];
      state.originalTheme = JSON.parse(JSON.stringify(state.currentTheme));
    }
  },

  [M.THEME_CHANGE_DEFAULT_THEME](state, defaultTheme) {
    state.defaultThemeForUser = defaultTheme ? defaultTheme : null;
  },

  async [M.THEME_SELECT_THEME](state, themeId) {
    const currentThemeIndex = state.themes.findIndex((theme) => theme.id === state.currentTheme.id);
    state.themes[currentThemeIndex] = JSON.parse(JSON.stringify(state.originalTheme));
    const selectedTheme = state.themes.find((theme) => {
      return theme.id === themeId;
    });
    if (selectedTheme !== undefined) {
      state.currentTheme = selectedTheme;
      state.originalTheme = JSON.parse(JSON.stringify(state.currentTheme));
    }
  },

  [M.THEME_UPDATE_LAYER_STYLING](state, stylingConfig) {
    const index = state.currentTheme.stylingConfigs.findIndex(sc => sc.layer === stylingConfig.layer);

    state.currentTheme.stylingConfigs[index].colors = stylingConfig.colors;
    state.currentTheme.stylingConfigs[index].iconSize = stylingConfig.iconSize;
    state.currentTheme.stylingConfigs[index].textSize = stylingConfig.textSize;
    state.currentTheme.stylingConfigs[index].showText = stylingConfig.showText;
  },

  [M.THEME_SAVE_THEME](state) {
    state.originalTheme = JSON.parse(JSON.stringify(state.currentTheme));
    state.themes.sort(sortFunction);
  },

  [M.THEME_SAVE_THEME_AS](state, theme) {
    const index = state.themes.findIndex((theme) => theme.id === state.currentTheme.id);
    state.themes[index] = JSON.parse(JSON.stringify(state.originalTheme));

    const defaultIndex = state.themes.findIndex((theme) => theme.id === defaultTheme.id);
    if (defaultIndex > -1) {
      state.themes.splice(defaultIndex, 1);
    }

    state.themes.push(theme);
    state.currentTheme = theme;
    state.originalTheme = JSON.parse(JSON.stringify(theme));

    state.themes.sort(sortFunction);
  },

  [M.THEME_DELETE_THEME](state, themeId) {
    const indexOfTheme = state.themes.findIndex(theme => theme.id === themeId);
    state.themes.splice(indexOfTheme, 1);

    if (state.themes.length === 0) {
      state.themes.push(defaultTheme);
      state.currentTheme = defaultTheme;
      state.originalTheme = JSON.parse(JSON.stringify(defaultTheme));
    } else {
      state.currentTheme = state.themes[0];
      state.originalTheme = JSON.parse(JSON.stringify(state.themes[0]));
    }
  },

  [M.THEME_UPDATE_NAME](state, name) {
    state.currentTheme.name = name;
  },

  [M.UPDATE_BASE_MAP](state, baseMap) {
    state.currentTheme.baseMap = baseMap.id;
  },

  [M.THEME_SWITCH_STYLE](state) {
    state.currentTheme.dayTheme = !state.currentTheme.dayTheme;
  }

};

const getters = {
  getStyleConfigForLayer: (state) => (layerId) => {
    return state.currentTheme.stylingConfigs.find(s => s.layer === layerId);
  },
  getIsDayThemeSelected: (state) => {
    return state.currentTheme.dayTheme === true;
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
};