import turf from "turf";
import pointToLineDistance from '@turf/point-to-line-distance';
import TimeService from "../../services/timeService";
import Store from "../../store";
import turfPolygon from "turf-polygon";
import intersect from '@turf/intersect';
import Polygon from "ol/geom/Polygon";
import MultiPolygon from "ol/geom/MultiPolygon";
import GeoJSON from "ol/format/GeoJSON";
import booleanEqual from "@turf/boolean-equal";

export default class OpGeometryUtils {

  static getIndexOfTheNearestCoordinate(coordinates, coordinate) {
    const distances = coordinates.slice(0, coordinates.length - 1)
      .map((point, index) => buildPointPair(coordinates, index))
      .map(pointPair => calculateDistanceFromPoints(pointPair, coordinate));
    return distances.indexOf(Math.min(...distances)) + 1;
  }

  static constructTrajectoryElementFromPoint(point) {
    return {
      longitude: point.flatCoordinates[0],
      latitude: point.flatCoordinates[1],
      altitude: 0,
      time: TimeService.currentUtcTimeISOFormatted()
    };
  }

  static getPolygonIntersectingViewportWithGeometry(geometry) {
    const intersection = getIntersectionWithViewPort(geometry);
    return intersection ? getBiggestIntersectionPolygon(intersection) : null;
  }

  static constructPolygonFromOperationGeography(operationGeography) {
    return new GeoJSON().readGeometry(operationGeography).transform('EPSG:4326', 'EPSG:3857');
  }

  static createAlertGeometryForOperation(opGufi) {
    const operationPolygons = getOperationPolygons(opGufi);
    if (operationPolygons) {
      const mergedPolygons = operationPolygons
        .map(opPolygon => turf.polygon(opPolygon.getCoordinates()))
        .reduce((previousPolygon, currentPolygon) => turf.union(previousPolygon, currentPolygon));
      return new Polygon(mergedPolygons.geometry.coordinates);
    }
    return null;
  }

  static getIntersectionBetweenPointAndCurrentViewPort(point) {
    const currentViewPortPolygon = Store.getters.getCurrentViewPortPolygon();
    return turf.intersect(turfPolygon(currentViewPortPolygon.getCoordinates()), turf.point(point.getCoordinates()));
  }

  static getOuterBoxGeometryCoordinates(feature) {
    return feature.volumes
      .filter(v => v.outerBoxGeometry)
      .filter(v => areDifferentGeometries(v.outerBoxGeometry, v.geometry))
      .map(v => v.outerBoxGeometry.coordinates);
  }

  static arePointsEquals(point1, point2) {
    return point1 && point2 && booleanEqual(turf.point(point1), turf.point(point2));
  }

  static areCoordinatesNotANumber(coords) {
    return isNaN(coords[0]) || isNaN(coords[1]);
  }

  static geometryCentersAreEqual(geometry1, geometry2) {
    return geometry1[0] === geometry2[0] && geometry1[1] === geometry2[1];
  }
}

function areDifferentGeometries(outerBoxGeometry, geometry) {
  const opOuterBoxGeometryVolume = turfPolygon(outerBoxGeometry?.coordinates);
  const opGeometryVolume = turfPolygon(geometry.coordinates);
  return opOuterBoxGeometryVolume ? !booleanEqual(opGeometryVolume, opOuterBoxGeometryVolume) : false;
}

function sortPolygonsByArea(polygon1, polygon2) {
  return polygon2.getArea() - polygon1.getArea();
}

function buildPointPair(coordinates, index) {
  return {
    firstPoint: coordinates[index],
    secondPoint: coordinates[index + 1]
  };
}

function calculateDistanceFromPoints(pointPair, coordinate) {
  const lineToTheFirstPoint = turf.lineString([
    pointPair.firstPoint, pointPair.secondPoint
  ]);
  const point = turf.point(coordinate);
  return pointToLineDistance(point, lineToTheFirstPoint, {units: 'meters'});
}

function getIntersectionWithViewPort(geometry) {
  const currentViewPortPolygon = Store.getters.getCurrentViewPortPolygon();
  const turfGeometry = geometry.getType() === 'MultiPolygon' ?
    turf.multiPolygon(geometry.getCoordinates()) : turfPolygon(geometry.getCoordinates());
  return intersect(turfGeometry, turfPolygon(currentViewPortPolygon.getCoordinates()));
}

function getBiggestIntersectionPolygon(intersection) {
  if (intersection.geometry.type === "Polygon") {
    return new Polygon(intersection.geometry.coordinates);
  } else {
    const multiPolygon = new MultiPolygon(intersection.geometry.coordinates);
    return multiPolygon.getPolygons().sort(sortPolygonsByArea)[0];
  }
}

function getOperationPolygons(opGufi) {
  const operationPlan = Store.state.informationStore.messages.find(m => m.id === opGufi);
  return operationPlan ? operationPlan.operationPolygons?.getPolygons() : null;
}