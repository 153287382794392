<template>
  <div v-if="isOpen" class="dialogContainer" id="restrict-aor-confirmation-container-id" style="display: block">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="restrict-aor-dialog-modal-content" class="modal-content smartSISPanel">
          <div class="restrict-eor-country-header form-inline" id="restrict-aor-confirmation-header-id">
            <h5 class="modal-title col-6">{{ $t('confirmationMessages.title') }}</h5>
            <div v-if="displayCountryDropdown" class="col-6">
            <span id="property-drop-down-title" class="restrict-eor-country-title">
              <strong>
               {{ $t('labels.geozone.country') }}<strong class="text-danger">*</strong>:
              </strong>
            </span>
              <PropertyDropDown class="restrict-eor-country-dropdown-list col-4"
                                prop-name="country"
                                :is-mandatory="true"
                                :get-value="getCountry"
                                :set-value="setCountry"
                                :is-valid="validateCountry"
                                :options="options"
                                :disabled="false"
                                id="restriction-aor-country-property"/>
            </div>
          </div>
          <div class="modal-body errorBody" id="restrict-aor-confirmation-body-id">
            <p v-html="message"></p>
          </div>
          <div class="modal-footer" id="restrict-aor-confirmation-footer-id">
            <button type="button"
                    id="restrict-aor-confirmation-yes-button"
                    :class="['btn', 'btn-danger'].join(' ')"
                    :disabled="!validateCountry"
                    @click="restrictAors"
            >
              {{ $t('labels.common.yes') }}
            </button>
            <button type="button"
                    id="restrict-aor-confirmation-no-button"
                    :class="['btn', 'btn-primary'].join(' ')"
                    @click="close"
            >
              {{ $t('labels.common.no') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import A from '../../constants/actions';
import PropertyDropDown from "../../common/PropertyDropDown";
import AppConfig from '../../config/appConfig';
import GeozoneFormUtils from "../../utils/geozones/GeozoneFormUtils";
import GeozoneConfigUtils from "../../utils/geozones/GeozoneConfigUtils";
import Store from "../../store";
import Logger from "../../utils/LoggerUtils";
import DialogUtils from "../../utils/DialogUtils";
import GeozoneService from "../../services/geozoneService";
import PopupUtils from "../../utils/PopupUtils";
import AjaxUtils from "../../utils/AjaxUtils";

export default {
  name: 'RestrictionAorConfirmation',
  components: {
    PropertyDropDown
  },
  data() {
    return {
      country: AppConfig.geozoneCountries.length > 1 ? '' : AppConfig.geozoneCountries[0]
    }
  },
  computed: {
    message() {
      return this.$store.getters.aorRestrictionDialogMessage;
    },
    isOpen() {
      return this.$store.getters.isAorRestrictionDialogOpen;
    },
    options() {
      return AppConfig.geozoneCountries.map(value => {
        return {name: value, value: value};
      });
    },
    displayCountryDropdown() {
      return this.options.length > 1;
    },
    validateCountry() {
      return this.country && this.country !== '';
    },
  },

  methods: {
    async restrictAors() {
      const geozone = await this.getGeozoneForCurrentValidAors();
      if (geozone && GeozoneConfigUtils.hasValidAuthorityDetails(geozone)) {
        this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
        this.saveGeozone(geozone);
      } else {
        const errorMessage = geozone === null ?
          this.$i18n.t('errorMessages.restrictAorInvalidAorsError', {
            respAreas: this.$store.getters.getCurrentResponsibilityAreas.join(',')
          }) : this.$i18n.t('errorMessages.geozoneValidationError');
        Logger.error(errorMessage);
        DialogUtils.errorNotification(errorMessage);
      }
      this.close();
    },
    close() {
      this.country = AppConfig.geozoneCountries.length > 1 ? '' : AppConfig.geozoneCountries[0];
      return this.$store.dispatch(A.AOR_RESTRICTION_DIALOG_CLOSE);
    },
    getCountry() {
      return this.country;
    },
    setCountry(propName, value) {
      this.country = value;
    },
    saveGeozone(geozone) {
      GeozoneService.saveGeozone(geozone)
        .then(() => this.successCallback())
        .catch(error => this.errorCallback(AjaxUtils.parseErrorMessage(error)));
    },
    successCallback() {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      Logger.info(this.$i18n.t('logMessages.geozoneCreated'));
      PopupUtils.success(this.$i18n.t('popupMessages.geozoneCreated'));
      Store.dispatch(A.FORM_WRAPPER_CLOSE);
      const invalidAors = this.getInvalidAorsForCurrentView();
      if (invalidAors.length !== 0) {
        DialogUtils.errorNotification(
          this.$i18n.t('errorMessages.restrictAorInvalidAorsError', {respAreas: invalidAors.join(',')})
        );
      }
    },
    errorCallback(error) {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const errorMessage = this.$i18n.t('errorMessages.geozoneCreateError', {error});
      Logger.error(errorMessage);
      DialogUtils.errorNotification(errorMessage);
    },
    getInvalidAorsForCurrentView() {
      return this.$store.getters.getCurrentResponsibilityAreas.filter(designator => !this.isValidAorByDesignator(designator));
    },
    isValidAorByDesignator(aorDesignator) {
      return this.isValidAor(this.$store.getters.getResponsibilityAreaByDesignator(aorDesignator));
    },
    isValidAor(aor) {
      return aor !== undefined && aor.geometry !== undefined;
    },
    async getGeozoneForCurrentValidAors() {
      const validResponsibilityAreas = this.$store.getters.getCurrentResponsibilityAreas
        .map(designator => this.$store.getters.getResponsibilityAreaByDesignator(designator))
        .filter(aor => this.isValidAor(aor));
      return validResponsibilityAreas.length !== 0 ?
        await GeozoneFormUtils.createGeozoneForResponsibilityAreas(validResponsibilityAreas, this.country) : null;
    }
  }
}
</script>
<style src="./restrictAorDialog.css"/>