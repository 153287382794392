import UomConfig from "../../../../config/uomConfig";
import AjaxUtils from "../../../../utils/AjaxUtils";
import AppConfig from "../../../../config/appConfig";

export const createAor = ({aorName, minAltitude, maxAltitude, geometry}) => {
  const currentUom = UomConfig.getCurrentUomDimension();
  return AjaxUtils.fetchEndpoint('/responsibility-area', {
    providerId: AppConfig.providerId,
    name: aorName,
    geometry: geometry,
    minAltitude: minAltitude,
    maxAltitude: maxAltitude,
    altitudeUom: currentUom,
    verticalReferenceType: AppConfig.ui.globalCrs
  }, [], 'POST');
}