<template>
  <div :id="`${id}`" class="form-inline row w-100">
    <span class="fixedSizeTitle" v-if="this.title">
      <strong>
        {{ $t(title) }}<strong v-if="this.isMandatory" class="text-danger">*</strong>:
      </strong>
    </span>
    <DropDown
      :class="['form-control', 'm-1', 'col', (this.isValid ? '' : 'invalid')].join(' ')"
      :name="this.propName"
      :value="getValue(this.propName)"
      :options="this.options"
      :onChange="this.onChange"
      :disabled="disabled"
      :optionIsDisabled="isOptionDisabled"
      :id="`${id}-value`"
    >
    </DropDown>
  </div>
</template>

<script>

import DropDown from './base/DropDown.vue';

export default {
  name: 'PropertyDropdown',
  components: {
    DropDown
  },
  props: ['title', 'propName', 'options', 'disabled', 'getValue', 'setValue', 'isMandatory', 'isValid', 'id', 'isOptionDisabled'],
  methods: {
    onChange(value) {
      this.setValue(this.propName, value);
    },
  }
}
</script>