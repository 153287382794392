<template>
  <div id="information-panel-feature-container" class="smartSISPanel informationPanel">
    <div class="informationPanelContent">
      <div id="information-panel-feature-controls" class="informationPanelControls">
        <div class="list">
          <OpOutlookTabFilter :messages="getMessagesForTab"
                              v-if="activeTab.featureType === featureType.OPERATION_PLAN_OUTLOOK"/>
          <FeatureSubtypes v-if="activeTab.featureType.includes(featureType.OPERATION_PLAN)"
                           :features="getFeatureSubtypesFor(featureType.OPERATION_PLAN)"/>
          <MetDataSearch v-if="isNotUTMFeature" :activeTab="activeTab.featureType"/>
          <NotamFilters v-if="activeTab.featureType === featureType.EVENT"/>
          <FeatureSubtypes v-if="activeTab.featureType.startsWith(featureType.GEOZONE)"
                           :features="getFeatureSubtypesFor(featureType.GEOZONE_BASE_FEATURE)"/>
          <FeatureSubtypes v-if="activeTab.featureType.includes(featureType.DRONE_FLIGHT)"
                           :features="getFeatureSubtypesFor(featureType.DRONE_FLIGHT)"/>
          <GeozoneFilters v-if="activeTab.featureType === featureType.GEOZONE"/>
          <Search v-if="isTextSearchableFeature"
                  :activeTab="activeTab.featureType"
                  :searchInput="searchInput"
                  @updateSearchInput="updateSearchInput($event)"
          />
        </div>
      </div>
      <MessageContainer v-model:messages="getMessagesForTab"
                        :displayMessagesPaginated="shouldBeDisplayedWithPagination"/>
      <div v-if="activeTabHasCustomControls" id="message-controls">
        <component v-bind:is="activeTabCustomControls"
                   v-model:message="focusedMessageByIdAndActiveTab"
                   :activeTab="activeTab"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Features from '../../config/features';
import FeatureType from '../../constants/featureType';
import MessageContainer from './MessageContainer.vue';
import NotamFilters from './NotamFilters.vue';
import MetDataSearch from './MetDataSearch.vue';
import OpOutlookTabFilter from './operation-plan/opOutlookTabFilter';
import FeatureSubtypes from '../left-panel/FeatureSubtypes';
import FeatureSubtypesUtils from '../../utils/FeatureSubtypesUtils';
import GeozoneFilters from './geozone/GeozoneFilters';
import CustomMessageControlsConfig from "../../config/customMessageControlsConfig";
import Search from "./Search.vue";

export default {
  name: 'InformationPanel',

  data() {
    return {
      searchInput: null
    }
  },

  watch: {
    'activeTab.featureType': function () {
      this.searchInput = null;
    }
  },

  components: {
    OpOutlookTabFilter,
    MessageContainer,
    NotamFilters,
    MetDataSearch,
    FeatureSubtypes,
    GeozoneFilters,
    Search
  },

  computed: {
    ...mapGetters({
      activeTab: 'activeInformationTab',
      messages: 'getMessages',
    }),
    featureType() {
      return FeatureType;
    },
    isNotUTMFeature() {
      return [
        FeatureType.GEOZONE,
        FeatureType.GEOZONE_OUTLOOK,
        FeatureType.OPERATION_PLAN,
        FeatureType.OPERATION_PLAN_PROPOSED,
        FeatureType.OPERATION_PLAN_ACCEPTED,
        FeatureType.OPERATION_PLAN_ACTIVATED,
        FeatureType.OPERATION_PLAN_OUTLOOK,
        FeatureType.DRONE_ALERT,
        FeatureType.DRONES,
        FeatureType.DRONE_FLIGHT,
        FeatureType.DRONE_FLIGHT_UNIDENTIFIED,
        FeatureType.LARA_AIRSPACE,
        FeatureType.ICAO_FLIGHT_PLANS,
        FeatureType.WEATHER_DATA,
        FeatureType.AIRSPACE_RESERVATION_REQUEST].indexOf(this.activeTab.featureType) === -1;
    },
    isTextSearchableFeature() {
      return [FeatureType.ICAO_FLIGHT_PLANS, FeatureType.WEATHER_DATA].indexOf(this.activeTab.featureType) !== -1;
    },
    getMessagesForTab() {
      const feature = Features.getFeature(this.activeTab.featureType);
      const messageFilter = feature.filterMessages || this.defaultMessageFilter;
      let messagesForTab = messageFilter(this.messages, feature).filter(this.activeTab.filter);
      messagesForTab = this.searchInput ? messagesForTab.filter(m => m.message.includes(this.searchInput)) : messagesForTab;
      if (feature.sortInfoPanelMessageList) {
        messagesForTab.sort(feature.sortInfoPanelMessageList);
      }

      return feature.groupMessages ? feature.groupMessages(messagesForTab) : messagesForTab;
    },
    shouldBeDisplayedWithPagination() {
      const feature = Features.getFeature(this.activeTab.featureType);
      return feature.displayWithPagination;
    },
    focusedMessageByIdAndActiveTab() {
      const selectedMessageId = this.$store.getters.selectedFeatureId;
      return this.$store.getters.getMessageByIdAndFeatureType(selectedMessageId, this.activeTab.originalFeatureType)[0];
    },
    activeTabHasCustomControls() {
      const activeTab = this.activeTab.featureType;
      return activeTab ? CustomMessageControlsConfig.hasCustomControls(activeTab) : false;
    },
    activeTabCustomControls() {
      return CustomMessageControlsConfig.getCustomControls(this.activeTab.featureType);
    }
  },

  methods: {
    getLocations(locationType) {
      if (locationType === FeatureType.AIRPORT_HELIPORT) {
        return this.$store.getters.getCurrentAirports;
      }
      if (locationType === FeatureType.AIRSPACE) {
        return this.$store.getters.getCurrentFirs;
      }
      if (locationType === FeatureType.RESPONSIBILITY_AREA) {
        return this.$store.getters.getCurrentResponsibilityAreas;
      }
      return [];
    },
    sortMessagesByLocation(messages, locations) {
      return messages.sort((a, b) => locations.findIndex(loc => loc === b.station) - locations.findIndex(loc => loc === a.station));
    },
    defaultMessageFilter(messages, feature) {
      const filteredMessages = messages.filter((msg) => {
        return msg.featureType === this.activeTab.featureType;
      });

      const locations = this.getLocations((feature.staticDataType && feature.staticDataType.length > 0) ? feature.staticDataType[0] : null);

      return this.sortMessagesByLocation(filteredMessages, locations);
    },
    getFeatureSubtypesFor(baseFeatureId) {
      return FeatureSubtypesUtils.getFeatureSubtypesSortedByConfigOrder(baseFeatureId);
    },
    updateSearchInput(value) {
      this.searchInput = value;
    }
  }
}
</script>
<style src="./informationPanel.css"/>