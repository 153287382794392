import A from '../../constants/actions';
import M from '../../constants/mutations';
import AppConfig from '../../config/appConfig';
import Store from '../../store';
import {i18n} from '../../internationalization/index';

const state = {
  clientServerOffset: 0,
  webSocket: null,
  isConnected: false,
  reConnectCounter: 0,
  heartbeatCache: [],
};

const actions = {

  [A.WEBSOCKET_CONNECT]({commit}, callbacks) {
    commit(M.WEBSOCKET_CONNECT, callbacks);
  },

  [A.WEBSOCKET_SET_CONFIG]({dispatch, commit}, config) {
    if (state.webSocket && state.webSocket.readyState === state.webSocket.OPEN) {
      console.log(i18n.global.t('logMessages.wsSetConfig'), config);
      dispatch(A.DISABLE_PARTIAL_SUBSCRIBE);
      dispatch(A.NOTIFICATION_STATUS_TOGGLE, false);
      commit(M.WEBSOCKET_SEND_WITH_TOKEN, 'subscribe|' + JSON.stringify(config));
    }
  },

  [A.WEBSOCKET_PARTIAL_SUBSCRIBE]({dispatch, commit}, config) {
    if (state.webSocket && state.webSocket.readyState === state.webSocket.OPEN) {
      console.log(i18n.global.t('logMessages.wsPartialSubscribe'), config);
      dispatch(A.NOTIFICATION_STATUS_TOGGLE, false);
      commit(M.WEBSOCKET_SEND_WITH_TOKEN, 'partialSubscribe|' + JSON.stringify(config));
    }
  },

  [A.WEBSOCKET_HEARTBEAT]({state}) {
    if (state.webSocket && state.webSocket.readyState === state.webSocket.OPEN) {
      const heartbeat = 'heartbeat|' + Date.now();
      state.heartbeatCache.push(heartbeat);
      state.webSocket.send(heartbeat);
    }
  },

  [A.WEBSOCKET_INVALID_TOKEN]({commit}) {
    commit(M.WEBSOCKET_INVALID_TOKEN);
  },

  [A.WEBSOCKET_CONNECTED]({commit}) {
    commit(M.WEBSOCKET_CONNECTED);
  },

  [A.WEBSOCKET_CLOSED]({commit}) {
    commit(M.WEBSOCKET_CLOSED);
  },

  [A.WEBSOCKET_SET_CLIENT_SERVER_OFFSET]({commit}, clientServerOffsetInMilliseconds) {
    commit(M.WEBSOCKET_SET_CLIENT_SERVER_OFFSET, clientServerOffsetInMilliseconds);
  },

  [A.WEBSOCKET_REMOVE_HEARTBEAT_FROM_CACHE]({commit}, heartbeat) {
    commit(M.WEBSOCKET_REMOVE_HEARTBEAT_FROM_CACHE, heartbeat);
  },

};

const getters = {
  getClientServerOffset: state => {
    return state.clientServerOffset;
  },
  getHeartbeatCache: state => {
    return state.heartbeatCache;
  },
  webSocketIsConnected: state => {
    return state.isConnected;
  }
};

const mutations = {

  [M.WEBSOCKET_CONNECT](state, callbacks) {
    if (state.webSocket && state.webSocket.readyState === state.webSocket.OPEN) {
      state.webSocket.close();
    }
    state.webSocket = new WebSocket(AppConfig.server.websocketUrl);
    state.webSocket.onmessage = callbacks.onMessage;
    state.webSocket.onerror = callbacks.onError;
    state.webSocket.onclose = callbacks.onClose;
    state.webSocket.onopen = callbacks.onOpen;
    state.heartbeatCache = [];
  },

  [M.WEBSOCKET_CONNECTED](state) {
    state.isConnected = true;
    state.reConnectCounter += 1;
  },

  [M.WEBSOCKET_CLOSED](state) {
    state.isConnected = false;
  },

  [M.WEBSOCKET_INVALID_TOKEN](state) {
    state.webSocket.close();
  },

  [M.WEBSOCKET_SET_CLIENT_SERVER_OFFSET](state, clientServerOffsetInMilliseconds) {
    state.clientServerOffset = clientServerOffsetInMilliseconds;
  },

  [M.WEBSOCKET_SEND_WITH_TOKEN](state, payload) {
    payload = payload + '|token|' + Store.state.userStore.authInstance.token;
    state.webSocket.send(payload);
  },

  [M.WEBSOCKET_REMOVE_HEARTBEAT_FROM_CACHE](state, heartbeat) {
    state.heartbeatCache = state.heartbeatCache.filter(hb => !hb.includes(heartbeat));
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
};
