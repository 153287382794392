<template>
  <div class="time-interval-filter-drop-down-container">
    <DropDown
      :on-change="newVal => onValueChange(newVal, 'lower')"
      :options="filteredLowerBoundOptions"
      :value="timeIntervalFilterValue.lowerBound"
      class="time-interval-filter-lower-bound-drop-down"
      name="interval-lower-bound"
      id="interval-lower-bound"
    />
    {{ $t('labels.operationPlan.and') }}
    <DropDown
      :on-change="newVal => onValueChange(newVal, 'upper')"
      :options="filteredUpperBoundOptions"
      :value="timeIntervalFilterValue.upperBound"
      class="time-interval-filter-upper-bound-drop-down"
      name="interval-upper-bound"
      id="interval-upper-bound"
    />
  </div>
</template>

<script>
import DropDown from "../../../common/base/DropDown";

export default {
  name: "TimeIntervalFilter",
  components: {
    DropDown
  },
  emits: ['update:timeIntervalFilterValue'],
  model: {
    prop: 'timeIntervalFilterValue',
    event: 'change'
  },
  props: [
    'timeIntervalFilterValue',
    'lowerBoundOptions',
    'upperBoundOptions',
    'feature'
  ],
  computed: {
    filteredLowerBoundOptions() {
      return this.lowerBoundOptions.filter(o => o.value < this.timeIntervalFilterValue.upperBound);
    },
    filteredUpperBoundOptions() {
      return this.upperBoundOptions.filter(o => o.value > this.timeIntervalFilterValue.lowerBound);
    }
  },
  methods: {
    onValueChange(newVal, boundName) {
      this.$emit('update:timeIntervalFilterValue', {
        lowerBound: boundName === 'lower' ? newVal : this.timeIntervalFilterValue.lowerBound,
        upperBound: boundName === 'upper' ? newVal : this.timeIntervalFilterValue.upperBound
      });
    }
  }
}
</script>

<style src="./timeIntervalFilter.css"/>
