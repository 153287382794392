<template>
  <div id="altitudeSlider" ref="altitudeSlider" class="sliderContainer">
    <div v-if="showZoomLevel" class="zoomLevel">
      <span>{{ zoomLevel }}</span>
    </div>
    <div
      v-if="showAltitudeSlider"
      class="altitudeSlider"
      v-on:click="click"
      v-on:touchend="touchEnd"
      v-on:dblclick="resetAltitudeToOriginal()">
      <vueSlider
        ref="FLSlider"
        v-bind:class="getClass()"
        v-model="altitudeInMeters"
        :min="minimumHeightLimit"
        :max="maximumHeightLimit"
        :interval="interval"
        :dotSize="16"
        :show="true"
        :width="4"
        :tooltipPlacement="'right'"
        :tooltip-formatter="format"
        :tooltip="'always'"
        :eventType="'auto'"
        :direction="'btt'"
        :enable-cross="false"
      >
      </vueSlider>
    </div>
  </div>

</template>

<script>
import A from './../../constants/actions';
import vueSlider from 'vue-3-slider-component';
import Features from '../../config/features';
import ApplicationConfig from '../../config/appConfig';
import Logger from '../../utils/LoggerUtils';
import UomConfig from '../../config/uomConfig';
import UomUtils from '../../utils/UomUtils';
import HudTabName from "../../constants/hudTabName";
import SubscriptionUtils from "../../utils/SubscriptionUtils";
import DronesMessageProcessor from "../websocket/DroneMessageProcessor";
import Store from "../../store";
import FeatureType from "../../constants/featureType";

export default {
  name: 'Slider',
  props: ['forcedRefresh'],
  components: {
    vueSlider
  },
  data() {
    return {
      value: '',
      timeout: '',
    }
  },
  mounted: function () {
    this.$refs['altitudeSlider'].style.zoom = 1 / ApplicationConfig.ui.applicationZoomLevel;
  },
  computed: {
    altitudeInMeters: {
      get() {
        return [
          this.$store.getters.getMinFilteringAltitude,
          this.$store.getters.getMaxFilteringAltitude
        ];
      },
      set(altitudes) {
        this.value = altitudes;
        if (this.hasAltitudeChanged(altitudes)) {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => this.setAltitude(null), 1000);
        }
      }
    },
    minimumHeightLimit() {
      return ApplicationConfig.heightSlider.minimumHeightLimitInMeters;
    },
    maximumHeightLimit() {
      const intervalInCurrentUom = UomUtils.convertAltitudeFromCurrentUomToTargetUom(
        ApplicationConfig.heightSlider.interval, UomConfig.UOM.METERS);
      const roundedSteps = Math.floor((ApplicationConfig.heightSlider.maximumHeightLimitInMeters
        - ApplicationConfig.heightSlider.minimumHeightLimitInMeters) / intervalInCurrentUom);
      return roundedSteps * intervalInCurrentUom;
    },
    interval() {
      return UomUtils.convertAltitudeFromCurrentUomToTargetUom(ApplicationConfig.heightSlider.interval, UomConfig.UOM.METERS);
    },
    zoomLevel() {
      return Math.round(this.$store.state.viewStore.currentView.viewPort.zoom);
    },
    showAltitudeSlider() {
      return ApplicationConfig.heightSlider.heightSliderEnabled &&
        !this.$store.getters.isGivenTabOpened(HudTabName.UTM_PANEL) &&
        this.$store.getters.getAltitudeFilteredFeatures.length > 0;
    },
    showZoomLevel() {
      return ApplicationConfig.ui.zoomLevel.showZoomLevel;
    }
  },
  methods: {
    getClass() {
      return {
        'sliderInput': true,
        'modified': this.wasModified(),
        'vue-slider-tooltip': true
      }
    },
    wasModified() {
      return this.$store.getters.getOriginalMinFilteringAltitude !== this.$store.getters.getMinFilteringAltitude
        || this.$store.getters.getOriginalMaxFilteringAltitude !== this.$store.getters.getMaxFilteringAltitude;
    },
    hasAltitudeChanged(altitude) {
      return this.$store.getters.getMinFilteringAltitude !== altitude[0]
        || this.$store.getters.getMaxFilteringAltitude !== altitude[1];
    },
    format(value) {
      const valueInCurrentUOM = UomUtils.convertValueToCurrentUom(value, UomConfig.UOM.METERS);
      const minLabelValueInCurrentUOM = Math.ceil(UomUtils.convertValueToCurrentUom(ApplicationConfig.heightSlider.minimumHeightLimitForLabel, UomConfig.UOM.METERS));
      const maxLabelValueInCurrentUOM = Math.floor(UomUtils.convertValueToCurrentUom(ApplicationConfig.heightSlider.maximumHeightLimitForLabel, UomConfig.UOM.METERS));
      const displayedValue = valueInCurrentUOM < minLabelValueInCurrentUOM ?
        ('<' + minLabelValueInCurrentUOM) : (valueInCurrentUOM > maxLabelValueInCurrentUOM) ? ('>' + maxLabelValueInCurrentUOM) : valueInCurrentUOM;
      return displayedValue + UomConfig.getCurrentlyConfiguredUomDisplayValue();
    },
    resetAltitudeToOriginal() {
      clearTimeout(this.timeout);
      setTimeout(() => this.setAltitude([
        this.$store.state.viewStore.originalView.minAltitudeInMeters,
        this.$store.state.viewStore.originalView.maxAltitudeInMeters
      ]), 500);
    },
    touchEnd() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setAltitude(null), 200);
    },
    click() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setAltitude(null), 200);
    },
    setAltitude(altitudes) {
      this.value = altitudes || this.value !== '' ? this.value : [
        this.$store.getters.getMinFilteringAltitude,
        this.$store.getters.getMaxFilteringAltitude
      ];
      Logger.info(this.$i18n.t('logMessages.flightLevelChanged',
        {minAltitude: this.format(this.value[0]), maxAltitude: this.format(this.value[1])}));
      this.$store.dispatch(A.VIEW_EDITOR_ADJUST_MIN_ALTITUDE_SIZE, this.value[0]);
      this.$store.dispatch(A.VIEW_EDITOR_ADJUST_MAX_ALTITUDE_SIZE, this.value[1]);
      const affectedFeatures = Features.getConfiguredAltitudeBasedFeatureTypes();
      const partialSubscribe = SubscriptionUtils.constructPartialSubscriptionConfig(affectedFeatures);
      const messagesToRemove = this.$store.state.informationStore.messages.filter(m => affectedFeatures.includes(m.featureType));

      if (Store.getters.getAltitudeFilteredFeatures.includes(FeatureType.DRONES)) {
        DronesMessageProcessor.wipeAllCachedDrones();
      }

      this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, messagesToRemove);
      this.$store.dispatch(A.MAP_REMOVE_FEATURES, affectedFeatures);
      this.$store.dispatch(A.WEBSOCKET_PARTIAL_SUBSCRIBE, partialSubscribe);
      this.$store.dispatch(A.MAP_REFRESH_GEOSERVER_LAYERS);
    },
  }
}
</script>

<style src="./slider.css"></style>