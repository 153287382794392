import FeatureType from "../constants/featureType";
import {defineAsyncComponent} from "vue";

const CUSTOM_MESSAGE_CONTROLS_CONFIGS = [
  {
    featureType: FeatureType.OPERATION_PLAN,
    shouldBeUsed: featureType => featureType.startsWith(FeatureType.OPERATION_PLAN),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/controls/OperationPlanControls'))
  },
  {
    featureType: FeatureType.DRONES,
    shouldBeUsed: featureType => featureType.startsWith(FeatureType.DRONES) ||
      featureType.startsWith(FeatureType.DRONE_FLIGHT_UNIDENTIFIED),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/controls/DroneControls'))
  },
  {
    featureType: FeatureType.DRONE_ALERT,
    shouldBeUsed: featureType => featureType.startsWith(FeatureType.DRONE_ALERT),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/controls/DroneAlertControls'))
  },
  {
    featureType: FeatureType.GEOZONE_BASE_FEATURE,
    shouldBeUsed: featureType => featureType.startsWith(FeatureType.GEOZONE_BASE_FEATURE),
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/controls/GeozoneControls'))
  },
  {
    featureType: FeatureType.DRONE_FLIGHT,
    shouldBeUsed: featureType => featureType === FeatureType.DRONE_FLIGHT,
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/controls/DroneFlightControls'))
  },
  {
    featureType: FeatureType.LARA_AIRSPACE,
    shouldBeUsed: featureType => featureType === FeatureType.LARA_AIRSPACE,
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/controls/LaraAirspaceControls'))
  },
  {
    featureType: FeatureType.AIRSPACE_RESERVATION_REQUEST,
    shouldBeUsed: featureType => featureType === FeatureType.AIRSPACE_RESERVATION_REQUEST,
    component: defineAsyncComponent(() => import('../components/information-panel/custom-message-cards/controls/AirspaceReservationRequestControls'))
  },
];

function getCustomControls(featureType) {
  return CUSTOM_MESSAGE_CONTROLS_CONFIGS.find(config => config.shouldBeUsed(featureType))?.component;
}

function hasCustomControls(activeTab) {
  return CUSTOM_MESSAGE_CONTROLS_CONFIGS.some(config => config.shouldBeUsed(activeTab));
}

export default {
  getCustomControls,
  hasCustomControls
};