<template>
  <select
    :value="value"
    :disabled="disabled"
    class="form-control select dropdown-select"
    @change="onValueChange"
  >
    <option
      hidden
      disabled
      selected
      value
      style="display:none">
    </option>
    <option
      v-for="option in options"
      :disabled="isDisabledOption(option)"
      :value="option.value"
      :key="option.name"
      :id="name + option.name"
      class="form-control select-option">
      {{ $t(option.name) }}
    </option>
  </select>
</template>

<script>

export default {
  name: 'DropDown',
  props: ['name', 'options', 'value', 'disabled', 'onChange', 'optionIsDisabled'],
  methods: {
    onValueChange(evt) {
      this.onChange(evt.target.value);
    },
    isDisabledOption(value) {
      return this.optionIsDisabled ? this.optionIsDisabled(value) : false;
    }
  }
}

</script>
