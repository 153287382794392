<template>
  <div
    :class="[this.isDisabled ? 'disabled-action-button' : 'action-button']"
    id="takeoff-request-button">
    <a @click="requestTakeOff">
      <div>
        <span class="button-icon">
          <img class="infoPanelButton" alt="T/O Request button" v-bind:src="getImg('takeOffRequest')"></span>
      </div>
      <span class="button-text">{{ getText }}</span>
    </a>
  </div>
</template>

<script>

import FeatureType from "../../../../constants/featureType";
import PermissionUtils from "../../../../utils/PermissionUtils";
import DronePlanUtils from "../../../../utils/dronePlans/DronePlanUtils";
import A from "../../../../constants/actions";
import ActivationPrecheckForm from "../../operation-plan/ActivationPrecheckForm";
import {shallowRef} from "vue";

export default {
  name: 'TakeOffRequestButton',
  props: ['message', 'getImg', 'getText'],

  computed: {
    isOperationPlan() {
      return this.message && this.message.featureType.indexOf(FeatureType.OPERATION_PLAN) !== -1;
    },
    isDisabled() {
      return DronePlanUtils.isTakeOffRequestDisabledForDronePlan(this.message);
    },
  },

  methods: {
    requestTakeOff() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.performTakeOffRequestAction);
    },
    performTakeOffRequestAction() {
      if (this.$store.getters.taskTabIsOpened) {
        this.$store.dispatch(A.INFORMATION_PANEL_CLOSE_TASK_TAB);
      }
      this.$store.dispatch(A.FORM_WRAPPER_OPEN, {
        component: shallowRef(ActivationPrecheckForm),
        model: this.message
      });
    }
  }
}
</script>