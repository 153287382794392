import AjaxUtils from "../utils/AjaxUtils";
import Store from "../store";
import A from "../constants/actions";
import Logger from "../utils/LoggerUtils";
import DialogUtils from "../utils/DialogUtils";
import {i18n} from '../internationalization/index';
import geozoneWhitelistedIdType from "../constants/geozoneWhitelistedIdType";
import AppConfig from "../config/appConfig";

const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';

const CREATE_GEOZONE_ENDPOINT = "/ras/geozone";
const TERMINATE_GEOZONE_ENDPOINT = "/ras/geozone/terminate";
const ACTIVATE_DEACTIVATE_GEOZONE_ENDPOINT = "/ras/geozone/activate-deactivate";
const CHECK_GEOZONE_UNIQUENESS_ENDPOINT = "/ras/geozone/check-uniqueness";

const constructAllowListEndpoint = (type, country, identifier) => {
  switch (type) {
    case geozoneWhitelistedIdType.WHITELISTED_DRONES:
      return "/uas-zone/" + country + '/' + identifier + "/allowlist-drone";
    case geozoneWhitelistedIdType.WHITELISTED_OPERATORS:
      return "/uas-zone/" + country + '/' + identifier + "/allowlist-operator";
    case geozoneWhitelistedIdType.WHITELISTED_OPS:
      return "/uas-zone/" + country + '/' + identifier + "/allowlist-drone-plan";
  }
}

function saveGeozone(geozoneInstance) {
  let geozone = JSON.parse(JSON.stringify(geozoneInstance));
  return AjaxUtils.fetchEndpoint(CREATE_GEOZONE_ENDPOINT, geozone, [], POST);
}

function addIdToAllowlist(type, country, identifier, body) {
  const endpoint = constructAllowListEndpoint(type, country, identifier);
  return AjaxUtils.fetchEndpoint(endpoint, body, [], POST);
}

function removeIdFromAllowlist(type, country, identifier, body) {
  const endpoint = constructAllowListEndpoint(type, country, identifier);
  return AjaxUtils.fetchEndpoint(endpoint, body, [], DELETE);
}

function closeGeozone(providerId, country, identifier) {
  const closeConfigParams = [
    AjaxUtils.getParamObjectFor('provider_id', providerId),
    AjaxUtils.getParamObjectFor('country', country),
    AjaxUtils.getParamObjectFor('identifier', identifier),
  ];
  return AjaxUtils.fetchEndpoint(TERMINATE_GEOZONE_ENDPOINT, null, closeConfigParams, POST);
}

function activateDeactivateGeozone(country, identifier, providerId, changeActiveTo) {
  const params = [
    AjaxUtils.getParamObjectFor('country', country),
    AjaxUtils.getParamObjectFor('identifier', identifier),
    AjaxUtils.getParamObjectFor('provider_id', providerId),
    AjaxUtils.getParamObjectFor('change_active_to', changeActiveTo),
  ];
  return AjaxUtils.fetchEndpoint(ACTIVATE_DEACTIVATE_GEOZONE_ENDPOINT, null, params, PUT);
}

function updateGeozone(editGeozoneDto) {
  const updateConfigParams = [
    AjaxUtils.getParamObjectFor('country', editGeozoneDto.country),
    AjaxUtils.getParamObjectFor('identifier', editGeozoneDto.identifier),
  ];
  return AjaxUtils.fetchEndpoint(CREATE_GEOZONE_ENDPOINT, editGeozoneDto, updateConfigParams, PUT);
}

async function checkUasZoneUniqueness(config) {
  const response = await fetch(
    AjaxUtils.setParameters(AppConfig.server.serviceUrlRoot + CHECK_GEOZONE_UNIQUENESS_ENDPOINT, [
      AjaxUtils.getParamObjectFor('identifier', config.identifier),
      AjaxUtils.getParamObjectFor('country', config.country)
    ]),
    {
      method: POST,
      headers: AjaxUtils.getHeaders()
    });

  if (response.status !== 200) {
    Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
    const errorMessage = i18n.global.t('errorMessages.geozoneGenerateIdentificationError');
    Logger.error(errorMessage);
    DialogUtils.errorNotification(errorMessage);
    throw new Error(errorMessage);
  } else {
    return await response.text();
  }
}

export default {
  saveGeozone,
  closeGeozone,
  updateGeozone,
  checkUasZoneUniqueness,
  addIdToAllowlist,
  removeIdFromAllowlist,
  activateDeactivateGeozone,
}