import A from '../../constants/actions';
import M from '../../constants/mutations';
import TimeService from "../../services/timeService";
import Store from "../";

function arrayMove(array, oldIndex, newIndex) {
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
}

function arrayAdd(array, index, element) {
  array.splice(index, 0, element);
}

const state = {
  currentIndex: 0,
  trajectoryElements: null,
  corridorHorizontalBuffer: 0
};

const actions = {
  [A.TRAJECTORY_INIT_ELEMENTS]({commit}, defaultTrajectoryElement) {
    commit(M.TRAJECTORY_INIT_ELEMENTS, defaultTrajectoryElement);
    commit(M.TRAJECTORY_UPDATE_CORRIDOR_HORIZONTAL_BUFFER, 0);
  },
  [A.TRAJECTORY_CLEAR_ELEMENTS]({commit}) {
    commit(M.TRAJECTORY_CLEAR_ELEMENTS);
  },
  [A.TRAJECTORY_INCREASE_CURRENT_INDEX]({commit}) {
    commit(M.TRAJECTORY_INCREASE_CURRENT_INDEX);
  },
  [A.TRAJECTORY_DECREASE_CURRENT_INDEX]({commit}) {
    commit(M.TRAJECTORY_DECREASE_CURRENT_INDEX);
  },
  [A.TRAJECTORY_UPDATE_ELEMENT]({commit}, trajectoryElement) {
    commit(M.TRAJECTORY_UPDATE_ELEMENT, trajectoryElement);
  },
  [A.TRAJECTORY_UPDATE_CORRIDOR_HORIZONTAL_BUFFER]({commit}, value) {
    commit(M.TRAJECTORY_UPDATE_CORRIDOR_HORIZONTAL_BUFFER, value);
  },
  [A.TRAJECTORY_CONSTRUCT_ELEMENTS_FROM_GEOMETRY]({commit}, drawnGeometry) {
    commit(M.TRAJECTORY_CONSTRUCT_ELEMENTS_FROM_GEOMETRY, drawnGeometry);
  },
  [A.TRAJECTORY_UPDATE_ELEMENTS_POSITIONS]({commit}, editedGeometry) {
    commit(M.TRAJECTORY_UPDATE_ELEMENTS_POSITIONS, editedGeometry);
  },
  [A.TRAJECTORY_UPDATE_WAYPOINT_TYPE]({commit}, newWaypointType) {
    commit(M.TRAJECTORY_UPDATE_WAYPOINT_TYPE, newWaypointType);
  },
  [A.TRAJECTORY_SET_ELEMENTS]({commit}, trajectoryElements) {
    commit(M.TRAJECTORY_SET_ELEMENTS, trajectoryElements);
  },
  [A.TRAJECTORY_ADD_ELEMENT_TO_INDEX]({commit}, config) {
    commit(M.TRAJECTORY_ADD_ELEMENT_TO_INDEX, config);
    if (config.index <= state.currentIndex) {
      commit(M.TRAJECTORY_INCREASE_CURRENT_INDEX);
    }
  },
  [A.TRAJECTORY_REMOVE_WAYPOINT]({commit}) {
    commit(M.TRAJECTORY_REMOVE_WAYPOINT);
  },
};

const mutations = {
  [M.TRAJECTORY_INIT_ELEMENTS](state, defaultTrajectoryElement) {
    state.trajectoryElements = defaultTrajectoryElement;
  },
  [M.TRAJECTORY_CLEAR_ELEMENTS](state) {
    state.trajectoryElements = null;
    state.currentIndex = 0;
  },
  [M.TRAJECTORY_INCREASE_CURRENT_INDEX](state) {
    state.currentIndex++;
  },
  [M.TRAJECTORY_DECREASE_CURRENT_INDEX](state) {
    state.currentIndex--;
  },
  [M.TRAJECTORY_UPDATE_ELEMENT](state, trajectoryElement) {
    state.trajectoryElements.splice(state.currentIndex, 1, trajectoryElement);
  },
  [M.TRAJECTORY_CONSTRUCT_ELEMENTS_FROM_GEOMETRY](state, drawnGeometry) {
    state.trajectoryElements = drawnGeometry.getCoordinates()
      .map(coordinate => {
        return {
          longitude: coordinate[0],
          latitude: coordinate[1],
          altitude: 0,
          time: TimeService.getUtcStartTimeForCreationForms()
        };
      });
  },
  [M.TRAJECTORY_UPDATE_ELEMENTS_POSITIONS](state, editedGeometry) {
    editedGeometry.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates().forEach((coordinate, index) => {
      const currentTrajectoryElement = state.trajectoryElements[index];
      currentTrajectoryElement.longitude = coordinate[0];
      currentTrajectoryElement.latitude = coordinate[1];
      state.trajectoryElements.splice(index, 1, currentTrajectoryElement);
    });
  },
  [M.TRAJECTORY_UPDATE_WAYPOINT_TYPE](state, newWaypointType) {
    const waypointTypeTransitions = {
      'Start': 0,
      'Land': Store.getters.getNumberOfTrajectoryElements - 1,
      'Waypoint': state.currentIndex + (state.currentIndex === 0 ? 1 : -1),
    };
    const newIndex = waypointTypeTransitions[newWaypointType];
    arrayMove(state.trajectoryElements, state.currentIndex, newIndex);
    state.currentIndex = newIndex;
  },
  [M.TRAJECTORY_UPDATE_CORRIDOR_HORIZONTAL_BUFFER](state, corridorHorizontalBuffer) {
    state.corridorHorizontalBuffer = corridorHorizontalBuffer;
  },
  [M.TRAJECTORY_SET_ELEMENTS](state, trajectoryElements) {
    state.trajectoryElements = trajectoryElements;
    state.currentIndex = 0;
  },
  [M.TRAJECTORY_ADD_ELEMENT_TO_INDEX](state, config) {
    arrayAdd(state.trajectoryElements, config.index, config.trajectoryElement);
  },
  [M.TRAJECTORY_REMOVE_WAYPOINT](state) {
    state.trajectoryElements.splice(state.currentIndex, 1);
    if (state.currentIndex === Store.getters.getNumberOfTrajectoryElements) {
      state.currentIndex--;
    }
  },
};

const getters = {
  getCurrentTrajectoryElementWaypointType: state => {
    if (state.currentIndex === 0) {
      return "Start";
    }
    if (state.currentIndex === state.trajectoryElements.length - 1) {
      return "Land";
    }
    return "Waypoint";
  },
  getCorridorHorizontalBuffer: state => {
    return state.corridorHorizontalBuffer;
  },
  getCurrentTrajectoryElement: state => {
    return state.trajectoryElements[state.currentIndex];
  },
  getCurrentIndex: state => {
    return state.currentIndex;
  },
  getNumberOfTrajectoryElements: state => {
    return state.trajectoryElements?.length || 0;
  },
  getTrajectoryElements: state => {
    return state.trajectoryElements;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};