import Store from "../../store";
import CommonStyleFunction from "../CommonStyleFunction";
import ModelUtils from "../../utils/ModelUtils";
import UomUtils from "../../utils/UomUtils";
import {Fill, Stroke, Style} from "ol/style";

export default class GeozoneStyleFunction {

  static buildGeozoneStyleFunction(stylingConfig, isEmbeddedMapFeature) {
    return function (feature, resolution) {
      if (!isEmbeddedMapFeature && !feature.getProperties().isActive) {
        return [];
      }
      const geozoneColorConfigByRestrictionType =
        Store.getters.getGeozoneColorConfigByRestrictionType(feature.getProperties().type);
      const colorConfig = geozoneColorConfigByRestrictionType ? geozoneColorConfigByRestrictionType : stylingConfig.colors;
      const layerTextStyleConfig = feature.getProperties().selected ? getGeozoneLayerTextStyleConfig(feature, stylingConfig, resolution) : undefined;
      const strokeConfig = getGeozoneStrokeConfig(feature, colorConfig, false);
      const fillColor = getFillColor(feature, colorConfig, false);
      return [
        buildGeometryStyle(feature, resolution, strokeConfig, fillColor),
        CommonStyleFunction.getLayerTextStylingInTheVisualCenterOfGeometry(stylingConfig, layerTextStyleConfig, feature.getGeometry())
      ];
    };
  }

}

function getGeozoneLayerTextStyleConfig(feature, stylingConfig, resolution) {
  const minAltitudeInCurrentUom = ModelUtils.roundToNearestInteger(
    UomUtils.convertAltitudeToCurrentUom(feature.getProperties().minAltitude, feature.getProperties().altitudeType));
  const maxAltitudeInCurrentUom = ModelUtils.roundToNearestInteger(
    UomUtils.convertAltitudeToCurrentUom(feature.getProperties().maxAltitude, feature.getProperties().altitudeType));
  const displayedMinAltitude = feature.getProperties().minAltitude !== 0 ? minAltitudeInCurrentUom + '' : '';
  const altitudesConnector = displayedMinAltitude ? '-' : '';
  const text = displayedMinAltitude + altitudesConnector + maxAltitudeInCurrentUom;
  const offsets = {
    x: 0,
    y: 0
  };
  const shouldDisplayText = CommonStyleFunction.shouldDisplayFeatureText(feature.getGeometry(), resolution);
  return shouldDisplayText ? CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets) : undefined;
}

function getGeozoneStrokeConfig(feature, colorConfig, isEmbeddedMapFeature) {
  const isHighlightedOnEmbeddedMap = isEmbeddedMapFeature && feature.getProperties().selected;
  const embeddedMapHighlightedStrokeColor = CommonStyleFunction.getEmbeddedMapHighlightedColor(colorConfig.borderColor);
  const color = isHighlightedOnEmbeddedMap ? embeddedMapHighlightedStrokeColor : colorConfig.borderColor;
  const width = feature.getProperties().selected || isHighlightedOnEmbeddedMap ? 5 : 1.5;
  return {color, width};
}

function getFillColor(feature, color, isEmbeddedMapFeature) {
  const isHighlightedOnEmbeddedMap = isEmbeddedMapFeature && feature.getProperties().selected;
  const embeddedMapHighlightedFillColor = CommonStyleFunction.getEmbeddedMapHighlightedColor(color.fillColor);
  return isHighlightedOnEmbeddedMap ? embeddedMapHighlightedFillColor : color.fillColor;
}

function buildGeometryStyle(feature, resolution, strokeConfig, fillColor) {
  const geometry = feature.getProperties().geometries?.length > 0 ?
    getGeometryForResolution(feature.getProperties().geometries, resolution) : feature.getGeometry();

  return new Style({
    fill: new Fill({color: CommonStyleFunction.toRgba(fillColor)}),
    stroke: new Stroke({
      color: CommonStyleFunction.toRgba(strokeConfig.color),
      width: strokeConfig.width
    }),
    geometry: geometry,
    zIndex: 30
  });
}

function getGeometryForResolution(geometries, resolution) {
  return resolution < 25 ? geometries[0] : resolution < 75 ? geometries[1] : geometries[2];
}