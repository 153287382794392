import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils';
import ThemeElements from '../../constants/theme-elements';
import Feature from "ol/Feature";
import ModelUtils from "../../utils/ModelUtils";
import GeoJSON from "ol/format/GeoJSON";

export default {

  id: FeatureType.AIRSPACE_RESERVATION_REQUEST,
  name: 'features.airspaceReservationRequest.name',
  abbreviation: 'features.airspaceReservationRequest.abbreviation',
  icon: 'pending_plan',
  staticDataType: [],
  isViewPortBased: false,
  isFlightLevelBased: false,
  maxNrOfFeaturesPerLocation: 100,
  getReplaceableMessages: FeatureUtils.Filters.filterById,
  zIndex: 1,
  displayOnMap: true,
  displayInMessages: true,

  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
      ThemeElements.ICON_SIZE,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
    ]
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 143, g: 20, b: 58, a: 1},
        fillColor: {r: 143, g: 20, b: 58, a: 0.3},
        textColor: {r: 143, g: 20, b: 58, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 255, g: 255, b: 255, a: 1},
      }
    }
  },

  geoJsonMapper: (feature) => {
    return new Feature({
      id: feature.id,
      name: feature.name,
      geometry: new GeoJSON().readGeometry(feature.geometry),
      featureType: feature.featureType,
      altitudeType: "M",
      minAltitude: feature.minAltitudeMeters,
      maxAltitude: feature.maxAltitudeMeters,
      selected: false,
      isActive: true
    });
  },

  getMinifiedObject(feature) {
    return {
      id: feature.id,
      featureType: feature.featureType,
      name: feature.name,
      surname: feature.surname,
      description: feature.description,
      email: feature.email,
      phone: feature.phone,
      startDateTime: feature.startDateTime,
      endDateTime: feature.endDateTime,
      activityType: feature.activityType,
      natureOfOperation: feature.natureOfOperation,
      minAltitude: {
        altitude_value: ModelUtils.roundToTwoDecimals(feature.minAltitudeMeters),
        units_of_measure: 'M'
      },
      maxAltitude: {
        altitude_value: ModelUtils.roundToTwoDecimals(feature.maxAltitudeMeters),
        units_of_measure: 'M'
      },
      publicPhoneNumber: feature.publicPhoneNumber,
      state: feature.state
    };
  },

}