export default {

  ADSB: 'adsb',

  DELETE: 'delete',

  DOCUMENTS: 'documents',

  DRONES: 'drones',

  AIRCRAFT: 'aircraft',

  ERROR: 'error',

  ERROR_POPUP: 'errorpopup',

  HEARTBEAT: 'heartbeat',

  INVALID_TOKEN: 'invalidToken',

  LOADING_COMPLETE: 'loadingComplete',

  PRODUCT: 'product',

  UPDATE: 'update',

  WSQUERY: 'wsquery',

  QUERY_ERROR: 'queryerror',

  HEALTH_CHECK: 'healthcheck',

  WARNING_POPUP: 'warning',

  CLEAN_FEATURES: 'cleanFeatures',

  REFRESH_GEOSERVER_LAYER: 'refreshGeoserverLayer',

}