<template>
  <div :id="`${id}`" :class=" this.inputClass ? this.inputClass : 'form-inline row w-100'">
    <span class="fixedSizeTitle" v-if="title">
      <strong>
        {{ $t(internalTitle) }}<strong v-if="isMandatory" class="text-danger">*</strong>:
      </strong>
    </span>
    <input
      :id="`${id}-value`"
      :type="this.type || 'text'"
      :disabled="disabled === true"
      :class="['form-control', 'col', (this.isValid || this.validationError ? '' : 'invalid')].join(' ')"
      @keyup="onChange"
      @change="onChange"
      :value="getValue(this.propName)"
    >
    <div id="property-input-validation-error" v-if="this.validationError" class="validation-message">
      {{ validationError }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'PropertyInput',
  props: [
    'title',
    'propName',
    'disabled',
    'getValue',
    'setValue',
    'validationError',
    'type',
    'isMandatory',
    'isValid',
    'inputClass',
    'id'
  ],
  computed: {
    internalTitle() {
      return typeof this.title === 'function' ? this.title(this.propName) : this.title;
    }
  },
  methods: {
    onChange(evt) {
      this.setValue(this.propName, evt.target.value);
    },
  }
}
</script>