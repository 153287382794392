import GeozoneValidationUtils from "./GeozoneValidationUtils";
import TimeService from "../../services/timeService";
import Store from "../../store";
import A from "../../constants/actions";
import GeozoneService from "../../services/geozoneService";
import Logger from "../LoggerUtils";
import PopupUtils from "../PopupUtils";
import DialogUtils from "../DialogUtils";
import UomConfig from "../../config/uomConfig";
import globalCrsConfig from "../../config/globalCrsConfig";
import AppConfig from '../../config/appConfig';
import GeozoneConfigUtils from "./GeozoneConfigUtils";
import {i18n} from '../../internationalization/index';
import turf from "turf";
import Polygon from "ol/geom/Polygon";
import AjaxUtils from "../AjaxUtils";

export default class GeozoneFormUtils {

  static getInitialGeozone() {
    return generateInitialGeozone([constructInitialAirspaceVolume()]);
  }

  static async saveGeozoneCallback(model) {
    if (GeozoneConfigUtils.hasValidAuthorityDetails(model)) {
      Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const identifier = await generateUniqueIdentifier(model.country);
      saveGeozone(model, identifier);
    } else {
      const errorMessage = i18n.global.t('errorMessages.geozoneValidationError');
      Logger.error(errorMessage);
      DialogUtils.errorNotification(errorMessage);
    }
  }

  static validateGeozoneModel(geozoneInstance) {
    return geozoneInstance ? GeozoneValidationUtils.applyGeozoneValidations(geozoneInstance) : false;
  }

  static async createGeozoneForResponsibilityAreas(responsibilityAreas, country) {
    const responsibilityAreaDesignators = responsibilityAreas.map(resp => resp.designator);
    const volumesForResponsibilityAreas = responsibilityAreas
      .map((ra) => constructGeozoneVolumesForResponsibilityArea(ra))
      .flat(1);
    const geozone = generateInitialGeozone(volumesForResponsibilityAreas);
    geozone.country = country;
    geozone.identifier = await generateUniqueIdentifier(country);
    geozone.name = 'Restricted AOR ' + responsibilityAreaDesignators;
    geozone.message = 'Restrict AOR with designator: ' + responsibilityAreaDesignators;
    geozone.reason = 'OTHER';
    return geozone;
  }

  static constructEditDtoForGeozone(geozone) {
    let editDto = {};
    editDto.id = geozone.id;
    editDto.featureType = geozone.featureType;
    editDto.country = geozone.country;
    editDto.identifier = geozone.identifier;
    editDto.name = geozone.name;
    editDto.type = geozone.type;
    editDto.reason = geozone.reasons.length > 0 ? geozone.reasons[0] : '';
    editDto.otherReason = geozone.otherReasonInfo;
    editDto.isApplicabilityEditable = geozone.isApplicabilityEditable;
    editDto.isGeometryEditable = geozone.volumes.length === 1;
    editDto.minAltitude = geozone.minAltitude.altitude_value;
    editDto.maxAltitude = geozone.maxAltitude.altitude_value;
    editDto.altitudeUom = geozone.minAltitude.units_of_measure;
    editDto.startDateTime = geozone.applicability[0].startDateTime;
    editDto.endDateTime = geozone.applicability[0].endDateTime ? geozone.applicability[0].endDateTime : geozone.endDateTime;
    editDto.geometry = geozone.volumes[0].horizontalProjection;
    editDto.originalGeometry = geozone.volumes[0].originalGeometry;

    return editDto
  }

  static constructGeozoneGeometry(geozone) {
    return geozone.volumes
      .map(volume => {
        const coordinates = volume?.horizontalProjection?.coordinates;
        return coordinates ? new Polygon(coordinates) : null;
      })
      .filter(polygon => polygon !== null);
  }
}

const generateInitialGeozone = (volumes) => {
  return {
    providerId: AppConfig.providerId,
    country: AppConfig.geozoneCountries[0],
    reason: null,
    otherReasonInfo: null,
    name: 'Temporary no flight zone',
    message: null,
    volumes: volumes,
    applicability: {
      startDateTime: TimeService.getUtcStartTimeForCreationForms(),
      endDateTime: TimeService.anHourFromCurrentTimeISOFormatted()
    },
    authorityDetails: AppConfig.geozoneAuthorityDetails
  };
};

const constructInitialAirspaceVolume = () => {
  const currentCrsTypeDisplayValue = globalCrsConfig.getConfigForCrsType(AppConfig.ui.globalCrs).rasModelValue;
  return {
    uomDimensions: null,
    lowerLimit: null,
    lowerVerticalReference: currentCrsTypeDisplayValue,
    upperLimit: null,
    upperVerticalReference: currentCrsTypeDisplayValue,
    horizontalProjection: null
  }
}

async function generateUniqueIdentifier(country) {
  const config = {
    identifier: generateIdentifier(),
    country: country
  };
  let isUniqueIdentifier = await GeozoneService.checkUasZoneUniqueness(config);
  while (isUniqueIdentifier !== 'true') {
    config.identifier = generateIdentifier();
    isUniqueIdentifier = await GeozoneService.checkUasZoneUniqueness(config);
  }
  return config.identifier;
}

function constructGeozoneVolumesForResponsibilityArea(responsibilityArea) {
  const geozoneGeometry = constructGeozoneGeometry(responsibilityArea);
  return geozoneGeometry.map(geometry => constructAirspaceVolume(responsibilityArea, geometry));
}

function generateIdentifier() {
  let rand4LetterString = Math.random().toString(36).substring(2, 6);
  return "DNF" + rand4LetterString;
}

function saveGeozone(model, identifier) {
  model.identifier = identifier;
  model.volumes[0].uomDimensions = UomConfig.getCurrentlyConfiguredUomDisplayValue();
  let geozoneCoordinates = model.volumes[0].horizontalProjection.coordinates;
  if (geozoneCoordinates && !GeozoneValidationUtils.isFirstCoordinateEqualWithLastCoordinate(geozoneCoordinates)) {
    geozoneCoordinates[0][geozoneCoordinates[0].length] = geozoneCoordinates[0][0];
  }
  const successCallback = () => {
    Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
    Logger.info(i18n.global.t('logMessages.geozoneCreated'));
    PopupUtils.success(i18n.global.t('popupMessages.geozoneCreated'));
    Store.dispatch(A.FORM_WRAPPER_CLOSE);
  };
  const errorCallback = (error) => {
    Store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
    const errorMessage = i18n.global.t('errorMessages.geozoneCreateError', {error});
    Logger.error(errorMessage);
    DialogUtils.errorNotification(errorMessage);
  }
  GeozoneService.saveGeozone(model)
    .then(() => successCallback())
    .catch(error => errorCallback(AjaxUtils.parseErrorMessage(error)));
}

function constructGeozoneGeometry(responsibilityArea) {
  const geozoneGeometry = responsibilityArea?.geometry.type === 'MultiPolygon' ?
    mergeMultiPolygon(responsibilityArea?.geometry) : responsibilityArea?.geometry;
  return geozoneGeometry.geometry?.type === 'MultiPolygon' ?
    createPolygonsFromCoordinates(geozoneGeometry.geometry.coordinates) :
    geozoneGeometry.type === 'Feature' ? [geozoneGeometry.geometry] : [geozoneGeometry];
}

function constructAirspaceVolume(responsibilityArea, geometry) {
  const respAreaCrsType = responsibilityArea?.verticalReferenceType;
  let airspaceVolume = constructInitialAirspaceVolume();
  airspaceVolume.lowerLimit = Math.round(responsibilityArea?.minimumAltitude);
  airspaceVolume.upperLimit = Math.round(responsibilityArea?.maximumAltitude);
  airspaceVolume.uomDimensions = responsibilityArea?.altitudeUom;
  airspaceVolume.lowerVerticalReference = globalCrsConfig.getConfigForCrsType(respAreaCrsType).rasModelValue;
  airspaceVolume.upperVerticalReference = globalCrsConfig.getConfigForCrsType(respAreaCrsType).rasModelValue;
  airspaceVolume.horizontalProjection = geometry;
  return airspaceVolume;
}

function mergeMultiPolygon(multipolygon) {
  return multipolygon.coordinates
    .map(polygonCoordinates => turf.polygon(polygonCoordinates))
    .reduce((previousPolygon, currentPolygon) => turf.union(previousPolygon, currentPolygon));
}

function createPolygonsFromCoordinates(coordinates) {
  return coordinates.map(coordinates => turf.polygon(coordinates).geometry);
}