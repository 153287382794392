export default {
  PROHIBITED: 'PROHIBITED',

  REQ_AUTHORISATION: 'REQ_AUTHORISATION',

  CONDITIONAL: 'CONDITIONAL',

  NO_RESTRICTION: 'NO_RESTRICTION',

  USPACE: 'USPACE'
}