<template>
  <div class="h-100">
    <div class="h-100" v-if="selectedElem">
      <MessageDetailedViewForm
        :all-messages="filteredMessages"
        :messageId="selectedElem"
        :model="model"
      />
    </div>
    <div v-else>
      <div class="counterButtonsContainer pt-4">
        <button
          id="geozone-active-button" key="uaszone" type="button"
          :class="[messageTypeIsSelected('uaszone') ? 'selected' : '', 'messageFilterButton', 'btn-primary',
                  'mr-2'].join(' ')"
          @click="updateMessageTypeText('uaszone')"
        >
          {{ $t('labels.geozone.effective') }}
        </button>
        <button
          id="geozone-upcoming-button" key="uaszone_outlook" type="button"
          :class="[messageTypeIsSelected('uaszone_outlook') ? 'selected' : '', 'messageFilterButton',
                  'btn-primary', 'mr-2'].join(' ')"
          @click="updateMessageTypeText('uaszone_outlook')"
        >
          {{ $t('labels.geozone.upcoming') }}
        </button>
        <button
          v-if="userHasGeozoneActivationRole" id="geozone-inactive-button" key="uaszone_my_inactive" type="button"
          :class="[messageTypeIsSelected('uaszone_my_inactive') ? 'selected' : '', 'messageFilterButton',
                  'btn-primary', 'mr-2'].join(' ')"
          @click="updateMessageTypeText('uaszone_my_inactive')"
        >
          {{ $t('labels.geozone.myInactive') }}
        </button>
        <button
          v-if="userHasGeozoneActivationRole" id="geozone-my-active-button" key="uaszone_my_active" type="button"
          :class="[messageTypeIsSelected('uaszone_my_active') ? 'selected' : '', 'messageFilterButton',
                  'btn-primary'].join(' ')"
          @click="updateMessageTypeText('uaszone_my_active')"
        >
          {{ $t('labels.geozone.myActive') }}
        </button>
      </div>
      <div class="search pt-4">
        <input
          class="form-control form-control-lg form-control-borderless"
          :placeholder="$t('labels.common.search')"
          type="text"
          v-model="searchText"
        >
      </div>
      <List
        :elements="filteredMessages"
        :listHeight="'100'"
        :model="model"
        :onSelect="this.onSelect"
        :onSort="this.onSort"
        :reverse="this.reverse"
        :sortCriteria="this.lastSortCriteria"
      />
    </div>
  </div>
</template>

<script>
import List from "./common/List";
import GeozoneModel from '../../../model/geozone';
import FeatureType from "../../../constants/featureType";
import MessageDetailedViewForm from "../detailed-view-form-components/MessageDetailedViewForm";
import A from "../../../constants/actions";
import PermissionUtils from "../../../utils/PermissionUtils";
import AppConfig from "../../../config/appConfig";

export default {
  name: "Geozones",
  components: {
    List,
    MessageDetailedViewForm
  },
  data() {
    return {
      reverse: false,
      lastSortCriteria: null,
      messageFilterText: '',
      messageTypeText: '',
    }
  },
  beforeMount() {
  },
  computed: {
    messages() {
      return this.$store.state.informationStore.messages
        .filter(msg => msg.featureType.startsWith(FeatureType.GEOZONE))
        .filter(msg => this.userHasGeozoneActivationRole ? true : msg.isActive)
        .filter(msg => msg.isActive || AppConfig.providerId === msg.providerId);
    },
    model() {
      return GeozoneModel;
    },
    filters: {
      get() {
        return [
          {
            shouldApply: this.messageTypeText !== '',
            apply: messages => this.filterMessagesByFilterType(messages),
          },
          {
            shouldApply: this.messageFilterText.length > 0,
            apply: messages => this.filterMessagesByQueryInputText(messages),
          },
        ];
      }
    },
    filteredMessages() {
      let filteredMessages = this.messages;
      this.filters
        .filter(filter => filter.shouldApply)
        .forEach(filter => filteredMessages = filter.apply(filteredMessages));

      if (this.lastSortCriteria) {
        const index = this.model.fields.findIndex(field => field.propertyName === this.lastSortCriteria);
        const sortMethod = this.model.fields[index].sort;
        let sortedMessages = filteredMessages.sort(sortMethod);
        return this.reverse ? sortedMessages.reverse() : sortedMessages;
      }
      return filteredMessages;
    },
    searchText: {
      get() {
        return this.messageFilterText;
      },
      set(value) {
        this.messageFilterText = value;
      }
    },
    selectedElem() {
      return this.$store.state.utmStore.selectedId;
    },
    userHasGeozoneActivationRole() {
      return PermissionUtils.hasGeozoneActivationRole();
    }
  },
  methods: {
    onSelect(element) {
      this.$store.dispatch(A.UTM_PANEL_SELECT_ELEM, element.id);
    },
    onSort(propertyName) {
      const modelFieldIndex = this.model.fields.findIndex(field => field.propertyName === propertyName);
      if (modelFieldIndex > -1) {
        if (this.wasLastSortedAndNotReversedBy(propertyName)) {
          this.reverseFilteredMessages();
        } else {
          this.sortFilteredMessages(modelFieldIndex);
        }
        this.lastSortCriteria = propertyName;
        this.$store.state.informationStore.forceRenderCount++;
      }
    },
    reverseFilteredMessages() {
      this.filteredMessages.reverse();
      this.reverse = true;
    },
    sortFilteredMessages(modelFieldIndex) {
      this.reverse = false;
      let sortMethod = this.model.fields[modelFieldIndex].sort;
      this.filteredMessages.sort(sortMethod);
    },
    wasLastSortedAndNotReversedBy(headerName) {
      return this.lastSortCriteria === headerName && !this.reverse;
    },
    filterMessagesByQueryInputText(messages) {
      return messages.filter(message => this.messageContainsQueryInputText(message));
    },
    messageContainsQueryInputText(message) {
      return this.model.fields.some(
        field => field.getter(message)?.toUpperCase().includes(this.messageFilterText.toUpperCase())
      );
    },
    messageTypeIsSelected(type) {
      return this.messageTypeText === type;
    },
    updateMessageTypeText(type) {
      this.messageTypeText = this.messageTypeText === type ? '' : type;
    },
    filterMessagesByFilterType(messages) {
      switch (this.messageTypeText) {
        case 'uaszone':
        case 'uaszone_outlook':
          return messages.filter(message => message.featureType === this.messageTypeText);
        case 'uaszone_my_inactive':
          return messages.filter(message => !message.isActive && AppConfig.providerId === message.providerId);
        case 'uaszone_my_active':
          return messages.filter(message => message.isActive && AppConfig.providerId === message.providerId);
      }
    },
  }
}
</script>