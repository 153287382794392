import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import FeatureUtils from '../../utils/FeatureUtils';
import Uas from './uas';

export default {

  id: FeatureType.GEOZONE_OUTLOOK,
  name: 'features.uasZoneOutlook',
  icon: 'sigmet',
  abbreviation: 'features.uasZoneOutlook',
  displayOnMap: false,
  displayInMessages: true,
  staticDataType: [FeatureType.AIRSPACE, FeatureType.RESPONSIBILITY_AREA],
  isViewPortBased: false,
  isFlightLevelBased: true,
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterByFeatureTypeAndId,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.SHOW_TEXT,
      ThemeElements.BORDER_COLOR,
      ThemeElements.FILL_COLOR,
      ThemeElements.TEXT_COLOR,
      ThemeElements.TEXT_SIZE,
      ThemeElements.TEXT_BG_COLOR,
      ThemeElements.IMAGE_COLOR,
    ]
  },

  geoJsonMapper: (feature) => {
    return Uas.geoJsonMapper(feature);
  },

  geoJsonVolumeMapper: (feature, volume, index) => {
    return Uas.geoJsonVolumeMapper(feature, volume, index);
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 248, g: 231, b: 28, a: 1},
        fillColor: {r: 248, g: 231, b: 28, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 248, g: 231, b: 28, a: 1},
      }
    }
  },

  getNotificationMessage() {
    return 'Upcoming ED-269';
  },

  getMinifiedObject(feature, minifiedObject) {
    return Uas.getMinifiedObject(feature, minifiedObject);
  },

  sortFeaturesList(a, b) {
    return Uas.sortFeaturesList(a, b);
  },

  filterMessages: (messages) => {
    return messages.filter((msg) => msg.featureType === FeatureType.GEOZONE_OUTLOOK && msg.isActive);
  }

}