import GeozoneCreationDetails from "../../model/geozoneCreationDetails";
import MapUtils from "../MapUtils";
import {intersect} from "turf";
import Polygon from "ol/geom/Polygon";
import circleToPolygon from "circle-to-polygon";
import GeozoneEditDetails from "@/model/geozoneEditDetails";
import FormMapLayersUtils from "@/utils/FormMapLayersUtils";

export default class GeozoneValidationUtils {

  static applyGeozoneValidations(geozoneInstance) {
    return GEOZONE_VALIDATIONS
      .every(validate => validate(geozoneInstance))
  }

  static validateGeozoneFields(geozoneInstance) {
    return isEveryFieldValid(GeozoneEditDetails.geozoneEditDetailsFields, geozoneInstance)
      && GeozoneValidationUtils.hasValidGeometry(geozoneInstance.geometry)
      && !GeozoneValidationUtils.hasSelfIntersectingGeometry(geozoneInstance.geometry)
      && GeozoneValidationUtils.intersectsWithViewGeometry(geozoneInstance.geometry);
  }

  static intersectsWithViewGeometry(geometry) {
    const gzPolygon = constructPolygon(geometry);
    return gzPolygon && MapUtils.getViewGeometries().some(geometry => geometriesIntersect(gzPolygon, geometry));
  }

  static isFirstCoordinateEqualWithLastCoordinate(coordinates) {
    const firstCoordinate = coordinates[0][0];
    const lastCoordinate = coordinates[0][coordinates[0].length - 1];
    return areTwoCoordinateEqual(firstCoordinate, lastCoordinate);
  }

  static isValidIntegerNumber(val) {
    const isInteger = /^\d+$/.test(val);
    return !isNaN(parseFloat(val)) && isInteger;
  }

  static hasSelfIntersectingGeometry(geometry) {
    const gzPolygon = constructPolygon(geometry);
    return gzPolygon && FormMapLayersUtils.existSelfIntersectingGeometry([gzPolygon])
  }

  static hasValidGeometry(geometry) {
    const coordinates = hasCircleGeometry(geometry) ? convertCircleToPolygon(geometry) : geometry?.coordinates;
    return coordinates && areCoordinatesInAllowedInterval(coordinates[0]) &&
      getNrOfDistinctCoordinates(coordinates[0]) >= 3;
  }
}

const GEOZONE_VALIDATIONS = [
  geozoneInstance => isEveryFieldValid(GeozoneCreationDetails.geozoneCreationDetailsFields, geozoneInstance),
  geozoneInstance => GeozoneValidationUtils.hasValidGeometry(geozoneInstance.volumes[0].horizontalProjection),
  geozoneInstance => !GeozoneValidationUtils.hasSelfIntersectingGeometry(geozoneInstance.volumes[0].horizontalProjection),
  geozoneInstance => GeozoneValidationUtils.intersectsWithViewGeometry(geozoneInstance.volumes[0].horizontalProjection)
];

function isEveryFieldValid(fieldConfigs, instance) {
  return fieldConfigs.every(fieldConfig => fieldConfig.isValid(fieldConfig.getValue(instance), instance));
}

function constructPolygon(geometry) {
  const coordinates = hasCircleGeometry(geometry) ? convertCircleToPolygon(geometry) : geometry?.coordinates;

  if (!GeozoneValidationUtils.isFirstCoordinateEqualWithLastCoordinate(coordinates)) {
    coordinates[0][coordinates[0].length] = coordinates[0][0];
  }

  return new Polygon(coordinates);
}

function areTwoCoordinateEqual(coord1, coord2) {
  return coord1[0] === coord2[0] && coord1[1] === coord2[1];
}

function areCoordinatesInAllowedInterval(coordinates) {
  return coordinates.every(coord => isValidLongitude(coord[0]) && isValidLatitude(coord[1]));
}

function isValidLongitude(longitude) {
  return longitude !== null && longitude >= -180 && longitude < 180;
}

function isValidLatitude(latitude) {
  return latitude !== null && latitude > -90 && latitude < 90;
}

function getNrOfDistinctCoordinates(coordinates) {
  let distinctCoordinates = [];
  coordinates.forEach(coordinate => {
    if (!distinctCoordinates.some(dc => areTwoCoordinateEqual(coordinate, dc))) {
      distinctCoordinates.push(coordinate);
    }
  })
  return distinctCoordinates.length;
}

function geometriesIntersect(geozone, viewGeometry) {
  return intersect(
    {type: geozone.getType(), coordinates: geozone.getCoordinates()},
    {type: viewGeometry.getType(), coordinates: viewGeometry.getCoordinates()}
  );
}

function hasCircleGeometry(geometry) {
  return geometry?.type === 'Circle';
}

function convertCircleToPolygon(geometry) {
  return circleToPolygon(geometry.center, geometry.radius).coordinates;
}