<template>
  <div>
    <div class="card smartSISPanel h-100">
      <h5 class="card-header">{{ $t('labels.configurationPanel.view.information') }}</h5>
      <div class="card-body">
        <div class="list-group viewList" v-bind:style="{height: heightSize}">
          <template v-for="productType in productTypes" :key="productType.id">
            <button
              type="btn btn-primary"
              :id="'information_' + productType.id"
              :class="['list-group-item', 'smartSISPanel', isSelected(productType.id) ? 'selected' : ''].join(' ')"
              @click="toggleInformationType(productType.id)"
            >
              {{ $t(productType.name) }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import Features from '../../../../config/features';
import FeatureType from '../../../../constants/featureType';

export default {
  name: 'Information',
  components: {},
  computed: {
    productTypes() {
      const excludedFeatures = [
        FeatureType.AIRSPACE,
        FeatureType.AIRPORT_HELIPORT,
        FeatureType.RESPONSIBILITY_AREA,
        FeatureType.OPERATION_PLAN,
        FeatureType.GEOZONE_BASE_FEATURE,
        FeatureType.DRONE_FLIGHT_UNIDENTIFIED
      ];
      return Features.getAvailableFeaturesForViewConfiguration()
        .filter(f => !excludedFeatures.includes(f.id));
    },
    heightSize() {
      return this.$store.state.configurationStore.listViewInformationHeight + 'px';
    }
  },
  methods: {
    isSelected(productType) {
      return this.$store.state.viewStore.currentView.productTypes.includes(productType);
    },
    toggleInformationType(productType) {
      this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_INFORMATION_TYPE, productType);
      if (productType === FeatureType.DRONE_FLIGHT) {
        this.$store.dispatch(A.VIEW_EDITOR_TOGGLE_INFORMATION_TYPE, FeatureType.DRONE_FLIGHT_UNIDENTIFIED);
      }
    }
  },
}
</script>
<style src="./viewEditor.css"></style>