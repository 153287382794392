import A from '../constants/actions';
import Store from '../store';
import Features from '../config/features';
import FeatureType from '../constants/featureType';
import FeatureUtils from '../utils/FeatureUtils';
import MessageUtils from '../utils/MessageUtils';
import OperationPlanService from './operationPlanService';
import TimeService from "./timeService";
import LayerUtils from '../utils/LayerUtils';
import LayersWithMultipleVolumesConfig from '../config/LayersWithMultipleVolumesConfig';

function processESResponse(esResponse) {
  let features = [];

  if (esResponse && esResponse.hits && esResponse.hits.hits) {
    const hits = esResponse.hits.hits.map(hit => getHitWithFeatureType(hit));
    features = [...features, ...hits];
  }
  if (esResponse && esResponse.aggregations && esResponse.aggregations.group && esResponse.aggregations.group.buckets) {
    esResponse.aggregations.group.buckets.forEach((bucket) => {
      const hits = (bucket.group_docs) ? bucket.group_docs.hits.hits.map(hit => getHitWithFeatureType(hit)) : [];
      features = [...features, ...hits];
    });
  }
  if (Array.isArray(esResponse) && esResponse.length > 0) {
    if (typeof esResponse[0] === 'string' || esResponse[0] instanceof String) {
      let feats = esResponse.map(f => JSON.parse(f));
      features = [...features, ...feats];
    } else {
      features = [...features, ...esResponse];
    }
  }
  return features;
}

function getHitWithFeatureType(hit) {
  return {
    ...hit._source,
    featureType: hit._source.featureType || hit._index.substring(0, hit._index.indexOf('_')) || hit._index,
  }
}

function processFeatures(features, config) {
  if (features && features.length > 0) {
    const feature = Features.getFeature(features[0].featureType);
    if (feature && feature.sortFeaturesList) {
      features.sort(feature.sortFeaturesList);
    }
  }

  features.forEach((feature) => processFeatureExec(feature, config));
}

function processAssocFeaturesCache(gufi, config) {
  const operationPlan = Store.getters.getOperationPlanByOpGufi(gufi);
  const assocFeatures = Store.getters.getAssociatedFeaturesByGufi(gufi);
  if (assocFeatures && !isAssociatedOperationPlanOutlook(operationPlan)) {
    Store.dispatch(A.ASSOC_FEATURE_REM_FROM_CACHE, gufi);
    assocFeatures.forEach((feature) => processFeature(feature, config));
    return true;
  } else {
    return false;
  }
}

function doubleCheckAssocFeatureCache(gufi, config) {
  const operationPlans = Store.getters.getOperationPlanByOpGufi(gufi);
  if (operationPlans.length > 0) {
    processAssocFeaturesCache(gufi, config);
  }
}

function processFeatureExec(message, config) {
  if (isAssociatedFeature(message)) {
    processAssociatedFeature(message, config);
  } else if (isOperationPlan(message)) {
    processOperationPlanFeature(message, config);
  } else {
    processFeature(message, config);
  }
}

function processAssociatedFeature(message, config) {
  const operationPlans = Store.getters.getOperationPlanByOpGufi(message.operationPlanGufi);
  if (isSailDetails(message)) {
    setMaxSailToOperationPlan(message, operationPlans);
  }
  if (operationPlans.length <= 0 || Store.getters.associatedFeatureExists(operationPlans, message) || isAssociatedOperationPlanOutlook(operationPlans)) {
    Store.dispatch(A.ASSOC_FEATURE_ADD_TO_CACHE, message);
  } else if (!isOperationPlanClosed(operationPlans[0])) {
    processAssocFeaturesCache(message.operationPlanGufi, config);
    processFeature(message, config);
  }
}

function processOperationPlanFeature(message, config) {
  processFeature(message, config);
  if (isOperationPlanClosed(message)) {
    Store.dispatch(A.ASSOC_FEATURE_REM_FROM_CACHE, message.id);
  } else {
    if (!processAssocFeaturesCache(message.id, config)) {
      // To eliminate a race condition, double-check the associated features cache once more after a while
      setTimeout(doubleCheckAssocFeatureCache, 500, message.id, config);
    }
  }
}

function isOperationPlanClosed(operationPlan) {
  return operationPlan.state === "CLOSED";
}

function isAssociatedOperationPlanOutlook(operationPlans) {
  return operationPlans.length > 0 && operationPlans[0].featureType === FeatureType.OPERATION_PLAN_OUTLOOK;
}

function isOperationPlan(message) {
  return (message.featureType?.indexOf('operationplan') !== -1);
}

function isSailDetails(message) {
  return (message.featureType?.indexOf('saildetails') !== -1);
}

function setMaxSailToOperationPlan(message, operationPlans) {
  if (operationPlans.length === 1) {
    operationPlans[0].maxSail = message.maxSail;
  }
}

function isAssociatedFeature(message) {
  return message.operationPlanGufi;
}

function isFeatureConfiguredForViewOrStatic(featureType) {
  const isFeatureConfiguredForView = Store.getters.getFeaturesConfiguredForCurrentView.includes(featureType);
  const isStaticFeatureType = Features.getAvailableFeatures()
    .find(feature => feature.isStaticDataType && feature.id === featureType);
  return isFeatureConfiguredForView || isStaticFeatureType;
}

function processFeature(message, config) {
  MessageUtils.getGroupObjects(message).forEach((msg) => {
    const feature = Features.getFeature(FeatureUtils.Mapping.getFeatureType(msg));
    if (!feature) return;
    const olFeature = FeatureUtils.Mapping.mapFeatureToGeoJson(msg, feature);
    const minifiedMsg = (feature.isStaticDataType || feature.displayInMessages || feature.createMinifiedObject) ? FeatureUtils.Mapping.getMinifiedObject(msg) : {};
    let ignoreMessageFunction = feature.ignoreMessage ? feature.ignoreMessage : () => false;
    if (ignoreMessageFunction(minifiedMsg, Store.state.informationStore.messages, Store)) {
      return;
    }

    if (feature.isStaticDataType) {
      Store.dispatch(A.STATIC_DATA_RECEIVED, minifiedMsg);
    }


    // check this logic
    if (TimeService.isInHistoryMode() &&
      minifiedMsg.featureType && minifiedMsg.featureType?.indexOf('operationplan') !== -1 &&
      minifiedMsg.history && minifiedMsg.history.length > 0) {
      if (isOperationPlanLastUpdatedAfterTimeSliderDateSelection(minifiedMsg)) {
        return;
      }
      const closestHistoryToSelectedTime = OperationPlanService.getOpClosestHistoryForCurrentTime(minifiedMsg.history);
      if (minifiedMsg.featureType === FeatureType.OPERATION_PLAN_OUTLOOK) {
        minifiedMsg.state = closestHistoryToSelectedTime.state;
        olFeature.set('state', minifiedMsg.state);
      } else {
        minifiedMsg.state = closestHistoryToSelectedTime.state;
        minifiedMsg.featureType = OperationPlanService.getFeatureTypeByCurrentState(minifiedMsg.state);
        olFeature.set('featureType', minifiedMsg.featureType);
        olFeature.set('state', minifiedMsg.state);
      }
    }

    if (TimeService.isInHistoryMode() &&
      minifiedMsg.featureType && minifiedMsg.featureType?.indexOf(FeatureType.DRONE_ALERT) !== -1 &&
      minifiedMsg.history && minifiedMsg.history.length > 0) {
      if (isAlertMessageCreatedAfterTimeSliderDateSelection(minifiedMsg) ||
        isAlertMessageCreatedAfterCurrentlySelectedTime(minifiedMsg)) {
        return;
      }
      const alertHistoryAtSelectedTime = getAlertMessageHistoryForCurrentTime(minifiedMsg.history);
      minifiedMsg.state = alertHistoryAtSelectedTime[alertHistoryAtSelectedTime.length - 1].state;
      olFeature.set('history', alertHistoryAtSelectedTime);
    }

    if (minifiedMsg.featureType?.indexOf(FeatureType.GEOZONE_BASE_FEATURE) !== -1 &&
      Store.getters.getGeozoneId === minifiedMsg.id) {
      Store.dispatch(A.WHITELIST_PANEL_UPDATE_MONITORED_GEOZONE, minifiedMsg);
    }

    if (feature.displayInMessages || feature.createMinifiedObject) {
      addMessageToInformationPanel(olFeature, minifiedMsg, feature, config);
    }

    if (olFeature && !olFeature.getProperties().refrainFromMap) {
      if (LayerUtils.isLayerWithMultipleVolumes(olFeature.getProperties().featureType || minifiedMsg.featureType)) {
        addMessageVolumesToMap(minifiedMsg, feature);
      } else {
        addMessageToMap(olFeature);
      }
    }
  })
}

function isOperationPlanLastUpdatedAfterTimeSliderDateSelection(operationPlan) {
  return operationPlan && operationPlan.updateTime ?
    !TimeService.isBeforeDateSelectionTimeStamp(TimeService.asMoment(operationPlan.updateTime)) :
    true;
}

function isAlertMessageCreatedAfterCurrentlySelectedTime(alertMessage) {
  const currentTime = TimeService.currentTimeSliderTime().toDate();
  return alertMessage && alertMessage.validTime.from ? new Date(alertMessage.validTime.from) > currentTime :
    true;
}

function isAlertMessageCreatedAfterTimeSliderDateSelection(alertMessage) {
  return alertMessage && alertMessage.validTime.from ?
    !TimeService.isBeforeDateSelectionTimeStamp(TimeService.asMoment(alertMessage.validTime.from)) : true;
}

function getAlertMessageHistoryForCurrentTime(alertMessageHistory) {
  const currentTime = TimeService.currentTimeSliderTime();
  return alertMessageHistory.filter(he => TimeService.asMoment(he.time_stamp).isSameOrBefore(currentTime));
}

function addMessageToInformationPanel(olFeature, minifiedMsg, featureConfig, config) {
  if (olFeature.getProperties().color) {
    minifiedMsg.color = olFeature.getProperties().color;
  }
  minifiedMsg.src = config.src;
  const toRemove = getReplaceableMessagesForFeature(minifiedMsg, featureConfig);
  Store.dispatch(A.INFORMATION_PANEL_MSG_RECEIVED, minifiedMsg);
  Store.dispatch(A.EMBEDDED_MAP_NEW_MESSAGE_HANDLER, {newMessage: minifiedMsg, toRemove});
}

function addMessageVolumesToMap(message, feature) {
  const multipleVolumesField = LayersWithMultipleVolumesConfig.getMultipleVolumesField(message.featureType);
  let volumes = getVolumes(message, multipleVolumesField);
  volumes.forEach((volume, index) => {
    const volFeature = FeatureUtils.Mapping.mapFeatureToVolumeGeoJson(message, volume, feature, index);
    if (volFeature) {
      addMessageToMap(volFeature);
    }
  });
}

function addMessageToMap(olFeature) {
  const fType = olFeature.getProperties().featureType;
  const layerConfig = Features.getAvailableMapFeatures().find(l => l.id === fType);
  if (layerConfig && isFeatureConfiguredForViewOrStatic(fType)) {
    (Array.isArray(olFeature) ? olFeature : [olFeature]).forEach(featureMap => {
      Store.dispatch(A.MAP_ADD_MESSAGE, featureMap);
    });
  }
}

function generateNilMessages() {
  const currentView = Store.state.viewStore.currentView;
  const productsWithTimeout =
    Features.getAvailableFeatures()
      .filter(prodType => prodType.timeoutHandler && prodType.generateNil !== false && currentView.productTypes.includes(prodType.id));
  productsWithTimeout.forEach(productType => {
    const messages = Store.state.informationStore.messages;
    const stations = (productType.staticDataType.includes(FeatureType.AIRSPACE)) ? Store.getters.getCurrentFirs : Store.getters.getCurrentAirports;
    stations.filter(station => {
      const message = messages.find(msg => msg.station === station && msg.featureType === productType.id);
      return !message;
    }).forEach(station => {
      Store.dispatch(A.INFORMATION_PANEL_MSG_RECEIVED, productType.timeoutHandler.nilMessage(generateEmptyMessage(station, productType.id)));
    });
  });
}

function generateEmptyMessage(station, featureType) {
  const currentTime = TimeService.currentTimeSliderTime().valueOf();
  const id = currentTime + station + featureType;
  return {
    station,
    featureType,
    validTime: {
      to: currentTime + 6 * 60 * 1000,
    },
    id,
    featureId: id,
  };
}

function getVolumes(message, path) {
  let current = message;
  path.split('.').forEach(function (p) {
    current = current[p];
  });
  return current;
}

function getReplaceableMessagesForFeature(minifiedMsg, featureConfig) {
  const messages = Store.state.informationStore.messages;
  return (featureConfig && featureConfig.getReplaceableMessages) ?
    featureConfig.getReplaceableMessages(minifiedMsg, messages) : [];
}

export default {
  processESResponse,
  processFeatures,
  generateNilMessages,
  addMessageToMap,
}