<template>
  <div id="drone-plan-import-dialog-container">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="drone-plan-import-dialog-modal-content" class="modal-content smartSISPanel">
          <div id="drone-plan-import-dialog-modal-header" class="modal-header">
            <h5 id="drone-plan-import-dialog-modal-title" class="modal-title">{{ $t('labels.common.importGeometry') }}</h5>
          </div>
          <div id="drone-plan-import-dialog-modal-body" class="modal-body errorBody">
            <div class="op-import-geometry-import-label">
              <strong>
                {{ $t('labels.common.geometry') }}:
              </strong>
            </div>
            <textarea
              id="op-geometry"
              type="text"
              :class="['op-import-geometry-import-input', (this.isValid ? '' : 'invalid-import-input')].join(' ')"
              :value="data"
              @input="updateOpGeometryData"
            />
            <div class="op-import-geometry-import-file">
              <input type="file" accept=".json" @change="importAction" ref="geometryImportFile">
            </div>
            <div v-if="!isValid" class="invalid-import-text">
              {{ $t('errorMessages.geoJSONGeometryError') }}
            </div>
          </div>
          <div id="drone-plan-import-dialog-modal-footer" class="modal-footer">
            <div>
              <button id="import-op-geometry" class="btn btn-danger" @click="importOpGeometry" :disabled="!isValid">
                {{ $t('labels.common.import') }}
              </button>
              <button id="op-geometry-import-cancel" class="btn btn-primary" @click="cancelGeometryImport">
                {{ $t('labels.common.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImportedGeometryValidationConfig from "../../../config/ImportedGeometryValidationConfig";

export default {
  name: "GeometryImportForm",
  props: {
    onSave: Function,
    onClose: Function,
  },
  data() {
    return {
      data: null,
      isValid: false,
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        try {
          const jsonData = JSON.parse(this.data);
          this.isValid = ImportedGeometryValidationConfig.isValidGeoJsonConfig(jsonData);
        } catch (error) {
          this.isValid = false;
        }
      }
    },
  },
  methods: {
    importOpGeometry() {
      const jsonData = JSON.parse(this.data);
      const opGeometry = ImportedGeometryValidationConfig.getGeometryFromGeoJsonConfig(jsonData);
      this.onSave(opGeometry);
      this.onClose();
    },
    cancelGeometryImport() {
      this.onClose();
    },
    importAction(event) {
      const fileReader = new FileReader();
      try {
        fileReader.readAsText(event.target.files[0]);
      } catch (error) {
        this.data = null;
      }
      fileReader.onload = () => {
        this.data = JSON.stringify(JSON.parse(fileReader.result), undefined, 4);
      }
    },
    updateOpGeometryData(event) {
      this.data = event.target.value;
      this.$refs.geometryImportFile.value = null;
    }
  }
}
</script>

<style src="./geometryImportForm.css" scoped/>