import AppConfig from "../config/appConfig";
import AjaxUtils from "../utils/AjaxUtils";

const POST = 'POST';

const APPROVE_AIRSPACE_RESERVATION_REQUEST_ENDPOINT = (requestId) => `/airspace-reservation-requests/${requestId}/approve`;
const REJECT_AIRSPACE_RESERVATION_REQUEST = (requestId) => `/airspace-reservation-requests/${requestId}/reject`;

function approveAirspaceReservationRequest(requestId, restrictionType) {
  const url = APPROVE_AIRSPACE_RESERVATION_REQUEST_ENDPOINT(requestId);
  const body = {
    providerId: AppConfig.providerId,
    restrictionType: restrictionType
  };
  return AjaxUtils.fetchEndpoint(url, body, [], POST);
}

function rejectAirspaceReservationRequest(requestId) {
  const url = REJECT_AIRSPACE_RESERVATION_REQUEST(requestId);
  const body = {
    providerId: AppConfig.providerId,
  };
  return AjaxUtils.fetchEndpoint(url, body, [], POST);
}

export default {
  approveAirspaceReservationRequest,
  rejectAirspaceReservationRequest
}