import adsb from './adsb';
import adwarning from './adwarning';
import airmet from './airmet';
import airportheliport from './airportheliport';
import airspace from './airspace';
import aircraft from './aircraft';
import apronelement from './apronelement';
import aspoc from './aspoc';
import briefing from './briefing';
import flightObject from './flight-object';
import flightTrack from './flight-track';
import cat from './cat';
import ctr from './ctr';
import designatedpoint from './designatedpoint';
import drones from './drones';
import droneAlert from './drone-alert';
import dust from './dust';
import event from './event';
import gamet from './gamet';
import groundhazard from './groundhazard';
import icing from './icing';
import lightning from './lightning';
import metar from './metar';
import rdt from './rdt';
import routesegment from './routesegment';
import runwaydirection from './runwaydirection';
import runwayelement from './runwayelement';
import sfo from './sfo';
import serviceinstance from './serviceinstance';
import sigmet from './sigmet';
import taf from './taf';
import taxiwayelement from './taxiwayelement';
import tca from './tca';
import temppoint from './temppoint';
import vaa from './vaa';
import vaisala from './vaisala';
import windbarb from './windbarb';
import wswarning from './wswarning';
import responsibilityarea from './responsibilityarea';
import terrain from './terrain';
import operationPlanProposed from './operationplan-proposed';
import operationPlan from './operationplan';
import operationPlanAccepted from './operationplan-accepted';
import operationPlanActivated from './operationplan-activated';
import operationPlanOutlook from './operationplan-outlook';
import saildetails from './saildetails';
import networkcoverage from "./networkcoverage";
import meteoprobe from "./meteoprobe";
import LayerUtils from '../../utils/LayerUtils';
import uas from './uas';
import uasZone from './uasZone';
import uasZoneOutlook from './uasZone-outlook';
import alertreply from './alertreply';
import Store from './../../store';
import DataFormat from './../../constants/dataFormat';
import droneFlight from './drone-flight';
import droneFlightUnidentified from './drone-flight-unidentified';
import laraAirspace from "./lara-airspace";
import icaoFlightPlans from "./icao-flight-plans";
import weatherData from "./weather-data";
import airspaceReservationRequests from "./airspace-reservation-request";

export default class Features {

  static allFeatures = [
    adsb,
    adwarning,
    airmet,
    airportheliport,
    airspace,
    aircraft,
    apronelement,
    aspoc,
    briefing,
    flightObject,
    flightTrack,
    cat,
    ctr,
    designatedpoint,
    drones,
    droneAlert,
    dust,
    event,
    gamet,
    groundhazard,
    icing,
    lightning,
    metar,
    rdt,
    routesegment,
    runwaydirection,
    runwayelement,
    sfo,
    serviceinstance,
    sigmet,
    taf,
    taxiwayelement,
    tca,
    temppoint,
    vaa,
    vaisala,
    windbarb,
    wswarning,
    responsibilityarea,
    terrain,
    operationPlan,
    operationPlanProposed,
    operationPlanAccepted,
    operationPlanActivated,
    operationPlanOutlook,
    saildetails,
    uas,
    uasZone,
    uasZoneOutlook,
    networkcoverage,
    meteoprobe,
    alertreply,
    droneFlight,
    droneFlightUnidentified,
    laraAirspace,
    icaoFlightPlans,
    weatherData,
    airspaceReservationRequests
  ].map(feature => {
    const layerConfig = LayerUtils.getAllLayersFromTopics().find(l => l.id === feature.id);
    return {...feature, ...layerConfig,}
  });

  static allTopicFeatures = null;
  static availableFeatures = null;
  static infoPanelFeatures = null;
  static dynamicFeatures = null;
  static allMapFeatures = null;
  static availableMapFeatures = null;

  static getFeatures() {
    if (Features.allTopicFeatures === null) {
      Features.allTopicFeatures = LayerUtils.getAllLayersFromTopics()
        .map(layer => Features.allFeatures.find(feature => feature.id === layer.id));
    }

    return Features.allTopicFeatures;
  }

  static getAllFeaturesForView() {
    return [...this.getFeatures(), ...Store.getters.getGeoserverFeatures]
  }

  static getAvailableFeatures() {
    if (Features.availableFeatures === null) {
      Features.availableFeatures = LayerUtils.getAvailableLayersFromTopics()
        .map(layer => Features.allFeatures.find(feature => feature.id === layer.id));
    }
    return Features.availableFeatures;
  }

  static getAvailableFeaturesForViewConfiguration() {
    const availableFeatures = this.getAvailableFeatures()
      .map(feature => {
        return {'id': feature.id, name: feature.name}
      });
    return [...availableFeatures, ...Store.getters.getGeoserverFeatures];
  }

  static getDynamicFeatures() {
    if (Features.dynamicFeatures === null) {
      Features.dynamicFeatures = Features.getAvailableFeatures().filter(feature => feature.isStaticDataType !== true);
    }

    return Features.dynamicFeatures;
  }

  static getFeature(id) {
    return Features.getFeatures().find(feature => feature.id === id);
  }

  static getFeatureById(id) {
    return Features.getAvailableFeatures().find(feature => feature.id === id);
  }

  static getFeatureStartingWith(id) {
    return Features.getFeatures().find(feature => feature.id.startsWith(id));
  }

  static getInfoPanelFeatures() {
    if (Features.infoPanelFeatures === null) {
      Features.infoPanelFeatures = Features.getAvailableFeatures().filter(feature => feature.displayInMessages);
    }

    return Features.infoPanelFeatures;
  }

  static getMapFeatures() {
    if (Features.allMapFeatures === null) {
      Features.allMapFeatures = Features.getFeatures().filter(feature => feature.displayOnMap);
    }
    return Features.allMapFeatures;
  }

  static getAvailableMapFeatures() {
    if (Features.availableMapFeatures === null) {
      Features.availableMapFeatures = Features.getAvailableFeatures().filter(feature => feature.displayOnMap);
    }
    return Features.availableMapFeatures;
  }

  static getFeaturesWithDefaultTheme() {
    let mapFeatures = this.getAvailableMapFeatures();
    const geoserverFeatures = Store.getters.getGeoserverLayers.filter(layer => layer.dataFormat === DataFormat.WFS)
      .map(layer => {
        return LayerUtils.constructDefaultStyleConfigForGeoserverLayer(layer);
      });
    return [...mapFeatures, ...geoserverFeatures];
  }

  static isFeatureAvailable(featureTypeId) {
    return Features.getAvailableFeatures().some(feature => feature.id === featureTypeId);
  }

  static isFeatureTypeDisplayableOnMap(featureType) {
    return Features.getAvailableMapFeatures().map(l => l.id).includes(featureType);
  }

  static getConfiguredAltitudeBasedFeatureTypes() {
    const flightLevelBasedFeatures = Features.getAvailableFeatures().filter(f => f.isFlightLevelBased
      && Store.getters.getFeaturesConfiguredForCurrentView.includes(f.id)).map(f => f.id);
    let layerIdsForSelectedTopics = LayerUtils.getLayerIdsByTopicNames(Store.state.informationStore.selectedTopics);
    let affectedFeatures = flightLevelBasedFeatures;
    if (Store.state.informationStore.selectedTopics.length > 0) {
      affectedFeatures = affectedFeatures.filter(p => layerIdsForSelectedTopics.includes(p));
    }
    return affectedFeatures;
  }

  static constructAvailableFeatures(featureIds) {
    return featureIds
      .filter(f => this.isFeatureAvailable(f) && Store.getters.getFeaturesConfiguredForCurrentView.includes(f))
      .map(feature => this.getFeature(feature));
  }
}