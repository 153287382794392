import TimeService from "../services/timeService";
import GeozoneValidationUtils from "@/utils/geozones/GeozoneValidationUtils";

const geozoneEditDetailsFields = [
  {
    propName: 'name',
    isValid(name) {
      return name && name !== '';
    },
    getValue(model) {
      return model.name;
    }
  },
  {
    propName: 'type',
    isValid(type) {
      return type && type !== '';
    },
    getValue(model) {
      return model.type;
    },
  },
  {
    propName: 'reason',
    isValid(reason) {
      return reason && reason !== '';
    },
    getValue(model) {
      return model.reason;
    },
  },
  {
    propName: 'otherReason',
    isValid(otherReason, model) {
      return model.reason !== 'OTHER' || (otherReason && otherReason !== '' && otherReason.length <= 200);
    },
    getValue(model) {
      return model.otherReason;
    }
  },
  {
    propName: 'minAltitude',
    isValid(lowerLimit, model) {
      return validateAltitudeField(lowerLimit, model);
    },
    getValue(model) {
      return model.minAltitude;
    },
  },
  {
    propName: 'maxAltitude',
    isValid(upperLimit, model) {
      return validateAltitudeField(upperLimit, model);
    },
    getValue(model) {
      return model.maxAltitude;
    }
  },
  {
    propName: 'startDateTime',
    isValid(timeBegin) {
      return timeBegin !== null && timeBegin !== '';
    },
    getValue(model) {
      return model.startDateTime;
    }
  },
  {
    propName: 'endDateTime',
    isValid(timeEnd, model) {
      return timeEnd !== null && timeEnd !== '' && TimeService.asMoment(timeEnd).toISOString > model.startDateTime;
    },
    getValue(model) {
      return model.endDateTime;
    }
  }
]

const getFieldByPropName = (propName) => {
  return getGeozoneEditDetailsFieldByPropName(propName);
}

const getGeozoneEditDetailsFieldByPropName = (propName) => {
  return geozoneEditDetailsFields.find(gdf => gdf.propName === propName);
}

const isMinAltitudeLowerThanMaxAltitude = (model) => {
  return Number.parseInt(model.minAltitude) < Number.parseInt(model.maxAltitude);
}

const areMinAltitudeAndMaxAltitudeIntegers = (model) => {
  return GeozoneValidationUtils.isValidIntegerNumber(model.minAltitude) &&
    GeozoneValidationUtils.isValidIntegerNumber(model.maxAltitude);
}

const validateAltitudeField = (value, model) => {
  return value === '' ? false :
    GeozoneValidationUtils.isValidIntegerNumber(value) ? value !== null && value >= 0
      && isMinAltitudeLowerThanMaxAltitude(model) : false;
}

export default {
  geozoneEditDetailsFields,
  getFieldByPropName,
  getGeozoneEditDetailsFieldByPropName,
  isMinAltitudeLowerThanMaxAltitude,
  areMinAltitudeAndMaxAltitudeIntegers,
  validateAltitudeField
}