import AppConfig from "../../config/appConfig";
import OperationPlanState from "../../constants/operationPlanState";
import {configForAuthorization, configForActivation, configList} from "../../config/OpApprovalResultConfigs";

export default class OpApprovalResultUtils {
  static getConfigForOp(operationPlan) {
    if (operationPlan.state === OperationPlanState.CLOSED.state) {
      return configForActivation.getResults(operationPlan)?.length > 0 ?
        configForActivation : configForAuthorization;
    }
    return configList.find(config => config.operationPlanStates.includes(operationPlan.state));
  }

  static getResults(config, operationPlan) {
    let approvalResults = config?.getResults(operationPlan) || [];
    if (this.isCurrentUserUsspForOperationPlan(operationPlan.usspProviderId)) {
      return this.getResultsToBeDisplayedForUssp(operationPlan.state, approvalResults);
    }
    return this.getResultsToBeDisplayedForCurrentUser(operationPlan.state, approvalResults);
  }

  static getResultsToBeDisplayedForUssp(opState, approvalResults) {
    if (opState === OperationPlanState.PROPOSED.state || opState === OperationPlanState.AUTHORIZED.state) {
      return approvalResults.filter(c => c.storedByProcess === 'AUTHORIZATION');
    }
    if (opState === OperationPlanState.TAKEOFFREQUESTED.state || opState === OperationPlanState.ACTIVATED.state) {
      return approvalResults.filter(c => c.storedByProcess === 'ACTIVATION');
    }
    if (opState === OperationPlanState.CLOSED.state) {
      return approvalResults.filter(c => c.storedByProcess === 'AUTHORIZATION' || c.storedByProcess === 'ACTIVATION');
    }
    return approvalResults;
  }

  static getResultsToBeDisplayedForCurrentUser(opState, approvalResults) {
    if (opState === OperationPlanState.PROPOSED.state || opState === OperationPlanState.AUTHORIZED.state) {
      return approvalResults.filter(c => c.authorityId === AppConfig.providerId && c.storedByProcess === 'APPROVAL');
    }
    if (opState === OperationPlanState.TAKEOFFREQUESTED.state || opState === OperationPlanState.ACTIVATED.state) {
      return approvalResults.filter(c => c.authorityId === AppConfig.providerId && c.storedByProcess === 'CLEARANCE');
    }
    if (opState === OperationPlanState.CLOSED.state) {
      return approvalResults.filter(c => c.authorityId === AppConfig.providerId &&
        (c.storedByProcess === 'APPROVAL' || c.storedByProcess === 'CLEARANCE'));
    }
    return approvalResults;
  }

  static isCurrentUserUsspForOperationPlan(opUsspProviderId) {
    return AppConfig.providerId === opUsspProviderId;
  }

  static getResultStateDisplayValue(config, result) {
    return config?.resultStatesDisplayValues
      .find(resultStatesDisplayValue => resultStatesDisplayValue.resultStates.includes(result.state))
      ?.displayValue || '';
  }
}
