<template>
  <div class="modal-content smartSISPanel">
    <div id="create-aor-dialog-modal-header" class="modal-header">
      <h5 id="create-aor-dialog-modal-title" class="modal-title">
        {{ $t('labels.configurationPanel.utm.createAor') }}
      </h5>
    </div>
    <div id="create-aor-dialog-modal-body" class="modal-body errorBody">
<!--      <span>AOR Name must be upper case letters and must start with 'RA_'</span>-->
      <PropertyInput
        id="aor-name"
        is-mandatory="true"
        prop-name="aorName"
        class="create-aor-dialog-input"
        :get-value="getProperty"
        :set-value="setAorName"
        :title="$t('labels.common.name')"
        :is-valid="isValidAorName"
      />
      <PropertyInput
        id="aor-min-altitude"
        is-mandatory="true"
        prop-name="minAltitude"
        type="number"
        class="create-aor-dialog-input"
        :get-value="getProperty"
        :set-value="setProperty"
        :title="$t('labels.common.minAltitude') + unitOfMeasureLabel"
        :is-valid="isValidMinAltitude"
      />
      <PropertyInput
        id="aor-max-altitude"
        is-mandatory="true"
        prop-name="maxAltitude"
        type="number"
        class="create-aor-dialog-input"
        :get-value="getProperty"
        :set-value="setProperty"
        :title="$t('labels.common.maxAltitude') + unitOfMeasureLabel"
        :is-valid="isValidMaxAltitude"
      />
    </div>
    <div id="create-aor-dialog-modal-footer" class="modal-footer">
      <button class="btn btn-primary" @click="onNext"
              :disabled="!(isValidAorName && isValidMinAltitude && isValidMaxAltitude)">
        {{ $t('labels.common.next') }}
      </button>
      <button class="btn btn-primary" @click="onClose">
        {{ $t('labels.common.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import PropertyInput from "../../../../common/PropertyInput.vue";
import ModelUtils from "../../../../utils/ModelUtils";
import GeometryImportForm from "../../../information-panel/geometry-import/GeometryImportForm.vue";
import DialogUtils from "../../../../utils/DialogUtils";
import {createAor} from "./CreateAorDialogService";
import A from "../../../../constants/actions"
import Logger from "../../../../utils/LoggerUtils";
import PopupUtils from "../../../../utils/PopupUtils";

export default {
  name: "CreateAorDialog",
  components: {PropertyInput},
  props: {
    onClose: Function,
  },
  data: () => ({
    aorName: 'RA_',
    minAltitude: undefined,
    maxAltitude: undefined,
  }),
  computed: {
    unitOfMeasureLabel() {
      return ' (' + ModelUtils.constructDisplayedUomAndCrs() + ')'
    },
    isValidAorName() {
      return this.aorName?.match(/^RA_[A-Z_]+$/);
    },
    isValidMinAltitude() {
      return this.minAltitude >= 0;
    },
    isValidMaxAltitude() {
      return this.maxAltitude > 0 && this.minAltitude <= this.maxAltitude;
    }
  },
  methods: {
    onNext() {
      DialogUtils.showCustomComponentDialog(GeometryImportForm, {
        onSave: (geometry) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          createAor({
            aorName: this.aorName,
            minAltitude: this.minAltitude,
            maxAltitude: this.maxAltitude,
            geometry
          })
            .then(this.onAorCreated)
            .catch(this.onAorCreationError)
            .finally(() => this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE))
        },
        onClose: this.onClose,
      });
    },
    setAorName(propName, value) {
      this.aorName = value?.toUpperCase();
    },
    onAorCreated() {
      PopupUtils.success(this.$root.$t('popupMessages.aorCreated'));
    },
    onAorCreationError(error) {
      Logger.error(this.$root.$t('errorMessages.aorCreationError') + error);
      DialogUtils.errorNotification(this.$root.$t('errorMessages.aorCreationError'));
    },
    getProperty(propName) {
      return this[propName];
    },
    setProperty(propName, value) {
      this[propName] = value;
    }
  }
}
</script>

<style>
.create-aor-dialog-input {
  padding-left: 1em;
  padding-bottom: 1em;
}

.create-aor-dialog-input > span {
  width: 50%;
}
</style>