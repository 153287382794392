<template>
  <div class="smartSISPanel utmPanel">
    <div id="information-panel-list-view-container" class="informationPanelContent">
      <div class="utmNavigationContainer w-100">
        <ul class="nav nav-tabs">
          <li
            :key="tab.id"
            @click="selectTab(tab.id)"
            class="shadow-style mr-2 mb-1"
            v-for="tab in availableTabs"
          >
            <a :class="['nav-link', (tab.id === selectedTab) ? 'active' : '']" href="#">{{ tab.name }}</a>
          </li>
        </ul>
        <div class="p-2 w-75 droneLinkInformationMessage">
          <a v-if="isLinkedDroneSelected()">
            {{ $t('logMessages.selectOperationPlanToBeLinked') }}
          </a>
        </div>
        <button id="utm-page-close-button" @click="closeUtmPage" class="btn btn-info closeButton">
          {{ $t('labels.common.close') }}
        </button>
      </div>
      <component :is="selectedTab"></component>
    </div>
  </div>
</template>

<script>
import DronePlans from './tabs/OperationPlans';
import DroneFlights from './tabs/DroneFlights';
import Drones from './tabs/Drones';
import Geozones from "./tabs/Geozones";
import LaraAirspaces from "./tabs/LaraAirspaces.vue";
import A from '../../constants/actions';
import featureType from '../../constants/featureType';
import FeatureUtils from "../../utils/FeatureUtils";

export default {
  name: 'UtmPanel',
  components: {
    DronePlans,
    DroneFlights,
    Drones,
    Geozones,
    LaraAirspaces
  },
  computed: {
    selectedTab() {
      return this.$store.state.utmStore.activeTab;
    },
    availableTabs() {
      return this.allTabs
        .filter(t => t.isAccessible())
        .filter(t => t.isConfiguredOnView())
        .filter(t => t.showTab());
    },
    allTabs() {
      return [
        {
          id: 'DronePlans',
          name: this.$i18n.t('features.operationPlan.abbreviation'),
          isAccessible: () => FeatureUtils.Access.hasAccessToOperationPlanFeature(),
          isConfiguredOnView: () => this.areFeaturesConfiguredForView([
            featureType.OPERATION_PLAN_PROPOSED, featureType.OPERATION_PLAN_ACCEPTED,
            featureType.OPERATION_PLAN_ACTIVATED, featureType.OPERATION_PLAN_OUTLOOK
          ]),
          showTab: () => {
            return true;
          }
        },
        {
          id: 'DroneFlights',
          name: this.$i18n.t('features.droneFlight.tab'),
          isAccessible: () => FeatureUtils.Access.hasAccessToDroneFlightFeature(),
          isConfiguredOnView: () => this.areFeaturesConfiguredForView([featureType.DRONE_FLIGHT]),
          showTab: () => {
            return this.$store.getters.getDroneIdToLink === null;
          }
        },
        {
          id: 'Drones',
          name: this.$i18n.t('features.drones'),
          isAccessible: () => FeatureUtils.Access.hasAccessToDroneTelemetryFeature(),
          isConfiguredOnView: () => this.areFeaturesConfiguredForView([featureType.DRONES]),
          showTab: () => {
            return this.$store.getters.getDroneIdToLink === null;
          }
        },
        {
          id: 'Geozones',
          name: this.$i18n.t('features.uas.abbreviation'),
          isAccessible: () => FeatureUtils.Access.hasAccessToGeozoneFeature(),
          isConfiguredOnView: () => this.areFeaturesConfiguredForView(
            [featureType.GEOZONE, featureType.GEOZONE_OUTLOOK]
          ),
          showTab: () => {
            return this.$store.getters.getDroneIdToLink === null;
          }
        },
        {
          id: 'LaraAirspaces',
          name: this.$i18n.t('features.laraAirspace'),
          isAccessible: () => FeatureUtils.Access.hasAccessToLaraAirspaceFeature(),
          isConfiguredOnView: () => this.areFeaturesConfiguredForView([featureType.LARA_AIRSPACE]),
          showTab: () => {
            return this.$store.getters.getDroneIdToLink === null;
          }
        },
      ]
    }
  },
  methods: {
    selectTab(id) {
      this.$store.dispatch(A.UTM_PANEL_DESELECT_ELEM);
      this.$store.dispatch(A.UTM_PANEL_SELECT_TAB, id);
    },
    closeUtmPage() {
      this.$store.dispatch(A.UTM_PANEL_TOGGLE);
      this.$store.dispatch(A.UTM_PANEL_DESELECT_ELEM);
    },
    areFeaturesConfiguredForView(featureTypes) {
      const productTypes = this.$store.state.viewStore.currentView.productTypes;
      return featureTypes.some(ft => productTypes.includes(ft));
    },
    isLinkedDroneSelected() {
      return this.$store.getters.getDroneIdToLink;
    }
  }
}
</script>
<style src="../information-panel/informationPanel.css"></style>