<template>
  <div class="form-wrapper" id="operation-plan-form-wrapper">
    <div id="op-form-title" class="form-title form-title-with-pagination row m-0">
      <div>
        <span id="op-activation-precheck-title" class="form-title-for-precheck">
          {{ $t('labels.operationPlan.requestTo') }}  - {{ $t('labels.operationPlan.precheck') }}
      </span>
      </div>
    </div>
    <ConflictSuppressionPanel v-model:value="model" :disable-save-button="disableSaveButton"/>
    <div class="form-button-container activation-precheck-buttons-container">
      <div>
        <button id="form-wrapper-save" class="btn btn-primary m-2" @click="onSubmit"
                :disabled="isSaveButtonDisabled">
          {{ $t('labels.operationPlan.submit') }}
        </button>
        <button id="form-wrapper-cancel" class="btn btn-primary m-2" @click="onCancel">
          {{ $t('labels.common.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ConflictSuppressionPanel from "./panels/conflict-suppression-panel/ConflictSuppressionPanel";
import {mapGetters} from "vuex";
import A from "../../../constants/actions";
import OperationPlanServices from "../../../services/operationPlanService";
import Logger from "../../../utils/LoggerUtils";
import PopupUtils from "../../../utils/PopupUtils";
import DronePlanUtils from "../../../utils/dronePlans/DronePlanUtils";
import DialogUtils from "../../../utils/DialogUtils";
import Store from "../../../store";

export default {
  name: 'ActivationPrecheckForm',
  components: {
    ConflictSuppressionPanel
  },
  data: () => {
    return {
      saveButtonDisabled: true,
      model: null
    }
  },
  computed: {
    ...mapGetters({
      getFormWrapperModel: 'getFormWrapperModel'
    }),
    isSaveButtonDisabled() {
      return this.model.priority === 'PRIO_HIGH' && this.saveButtonDisabled;
    },
  },
  created() {
    this.model = this.getFormWrapperModel;
  },
  methods: {
    onSubmit() {
      this.requestTakeOff();
    },
    requestTakeOff() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      OperationPlanServices.sendTakeOffRequest({
        operationPlanId: this.model.id,
        suppressedConflicts: this.model.suppressedConflicts,
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.info(this.$i18n.t('logMessages.dronePlanActivationSent', {dronePlanId: this.model.id}));
          PopupUtils.success(this.$i18n.t('popupMessages.dronePlanActivationSent', {
            title: DronePlanUtils.getPublicTitleForPopUp(this.model)
          }));
          this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
          Store.dispatch(A.FORM_WRAPPER_CLOSE);
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('errorMessages.dronePlanActivationSendError', {
            dronePlanId: this.model.id,
            error
          }));
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.dronePlanActivationError'));
          this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
        },
      });
    },
    onCancel() {
      this.$store.dispatch(A.FORM_WRAPPER_CLOSE);
    },
    disableSaveButton(disabled) {
      this.saveButtonDisabled = disabled;
    },
  }

}
</script>
<style src="./operationPlanForm.css"></style>