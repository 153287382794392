import ModelUtils from "../utils/ModelUtils";
import Moment from "moment";
import UomUtils from "../utils/UomUtils";
import DateTimeUtils from "../utils/DateTimeUtils";
import {i18n} from '../internationalization/index';
import GeozoneRestrictionTypeConfig from "../config/geozone/geozoneRestrictionTypeConfig";

const fields = [
  {
    id: 'geozone-name',
    name: 'labels.common.name',
    propertyName: 'Name',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'name',
    type: 'text',
    sort: (a, b) => {
      return ModelUtils.sort(a.name, b.name);
    },
    getter(geozone) {
      return geozone.name || geozone.wrapperId;
    },
  },
  {
    id: 'geozone-identifier',
    name: 'labels.common.identifier',
    propertyName: 'Identifier',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'identifier',
    type: 'text',
    sort: (a, b) => {
      return ModelUtils.sort(a.wrapperId, b.wrapperId);
    },
    getter(geozone) {
      return geozone.wrapperId;
    }
  },
  {
    id: 'geozone-start-time',
    name: 'labels.common.startTime',
    propertyName: 'startTime',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'startTime',
    type: 'text',
    sort: (a, b) => {
      return ModelUtils.sort(a.validTime.from, b.validTime.from);
    },
    getter(geozone) {
      return Moment.utc(geozone.validTime.from).format('YYYY DD MMM HH:mm');
    },
    isTimeDependent: true,
  },
  {
    id: 'geozone-end-time',
    name: 'labels.common.endTime',
    propertyName: 'endTime',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'endTime',
    type: 'text',
    sort: (a, b) => {
      return ModelUtils.sort(a.validTime.to, b.validTime.to);
    },
    getter(geozone) {
      const endTime = geozone.validTime.to;
      return ModelUtils.timestampYearIs9999(endTime) ?
        i18n.global.t('labels.geozone.noEndTime') : DateTimeUtils.formatTimestampAsDateAndTime(endTime);
    },
  },
  {
    id: 'geozone-min-altitude',
    name: 'labels.common.minAltitude',
    propertyName: 'minAltitude',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'minAltitude',
    type: 'text',
    sort: (a, b) => {
      const aMinAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(a.minAltitude);
      const bMinAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(b.minAltitude);
      return ModelUtils.sort(aMinAltitudeValueInCurrentUom, bMinAltitudeValueInCurrentUom);
    },
    getter(geozone) {
      const minAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(geozone.minAltitude);
      return ModelUtils.roundToTwoDecimals(minAltitudeValueInCurrentUom) + " " + ModelUtils.constructDisplayedUomAndCrs();
    }
  },
  {
    id: 'geozone-max-altitude',
    name: 'labels.common.maxAltitude',
    propertyName: 'maxAltitude',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'maxAltitude',
    type: 'text',
    sort: (a, b) => {
      const aMaxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(a.maxAltitude);
      const bMaxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(b.maxAltitude);
      return ModelUtils.sort(aMaxAltitudeValueInCurrentUom, bMaxAltitudeValueInCurrentUom);
    },
    getter(geozone) {
      const maxAltitudeValueInCurrentUom = UomUtils.convertAltitudeObjectToCurrentUom(geozone.maxAltitude);
      return ModelUtils.roundToTwoDecimals(maxAltitudeValueInCurrentUom) + " " + ModelUtils.constructDisplayedUomAndCrs();
    }
  },
  {
    id: 'geozone-restriction-type',
    name: 'labels.geozone.restrictionType',
    propertyName: 'type',
    displayInList: true,
    displayInForm: true,
    tableStyle: 'type',
    type: 'text',
    sort: (a, b) => {
      return ModelUtils.sort(a.type, b.type);
    },
    getter(geozone) {
      return geozone.type;
    }
  },
  {
    id: 'geozone-reason',
    name: 'labels.common.reason',
    propertyName: 'reason',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'reason',
    type: 'text',
    getter(geozone) {
      return geozone.reasons.join('; ');
    }
  },
  {
    id: 'geozone-other-reason-info',
    name: 'labels.geozone.otherReasonInformation',
    propertyName: 'otherReasonInfo',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'otherReasonInfo',
    type: 'text',
    getter(geozone) {
      return geozone.otherReasonInfo;
    }
  },
  {
    id: 'geozone-country',
    name: 'labels.geozone.country',
    propertyName: 'country',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'country',
    type: 'text',
    getter(geozone) {
      return geozone.country;
    }
  },
  {
    id: 'geozone-message',
    name: 'labels.geozone.message',
    propertyName: 'message',
    displayInList: false,
    displayInForm: true,
    displayOnSeparateLine: true,
    tableStyle: 'message',
    type: 'text',
    getter(geozone) {
      return geozone.message;
    }
  },
  {
    id: 'geozone-authority-details',
    name: 'labels.geozone.authorityDetails',
    propertyName: 'authorityDetails',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'authorityDetails',
    displayOnSeparateLine: true,
    type: 'text',
    getter(geozone) {
      return geozone.authorityDetails
        .map(authority =>
          authority.name +
          (authority?.contactName ? ' || ' + authority.contactName : '') +
          (authority?.email ? ' || ' + authority.email : '') +
          (authority?.phone ? ' || ' + authority.phone : '') +
          (authority?.service ? ' || ' + authority.service : '') +
          (authority?.siteURL ? ' || ' + authority.siteURL : '')
        ).join('<br />');
    }
  },
  {
    id: 'geozone-applicability',
    name: 'labels.geozone.applicability',
    propertyName: 'applicability',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'applicability',
    type: 'dropdown',
    getter(geozone, index) {
      return geozone.applicability[index];
    },
    getOptions(geozone) {
      return geozone.applicability
        .map((applicability, index) => {
          const endTime = (applicability.permanent) ? i18n.global.t('labels.geozone.noEndTime') :
            DateTimeUtils.formatTimestampAsDateAndTime(applicability.endDateTime);
          return {
            value: index,
            name: DateTimeUtils.formatTimestampAsDateAndTime(applicability.startDateTime) + ' - ' + endTime
          }
        });
    }
  },
  {
    id: 'geozone-schedule',
    name: 'labels.geozone.schedule',
    propertyName: 'schedule',
    displayInList: false,
    displayInForm: true,
    tableStyle: 'schedule',
    displayOnSeparateLine: true,
    type: 'text',
    getter(geozone, name, index) {
      const schedules = geozone.applicability[index]?.schedule;
      if (schedules) {
        return schedules.map(schedule => {
          return schedule.days + ' ' + schedule.startTime + ' - ' + schedule.endTime
        }).join('<br />');
      }
      return null;
    }
  }
]

const restrictionTypes = [{
  name: "labels.configurationPanel.global.conditional",
  value: GeozoneRestrictionTypeConfig.CONDITIONAL
}, {
  name: "labels.configurationPanel.global.noRestriction",
  value: GeozoneRestrictionTypeConfig.NO_RESTRICTION
}, {
  name: "labels.configurationPanel.global.prohibited",
  value: GeozoneRestrictionTypeConfig.PROHIBITED
}, {
  name: "labels.configurationPanel.global.reqAuthorisation",
  value: GeozoneRestrictionTypeConfig.REQ_AUTHORISATION
}, {
  name: "labels.configurationPanel.global.uspace",
  value: GeozoneRestrictionTypeConfig.USPACE
}];

const reasonTypes = [
  {name: 'labels.geozone.airTraffic', value: 'AIR_TRAFFIC'},
  {name: 'labels.geozone.sensitive', value: 'SENSITIVE'},
  {name: 'labels.geozone.privacy', value: 'PRIVACY'},
  {name: 'labels.geozone.population', value: 'POPULATION'},
  {name: 'labels.geozone.nature', value: 'NATURE'},
  {name: 'labels.geozone.noise', value: 'NOISE'},
  {name: 'labels.geozone.dar', value: 'DAR'},
  {name: 'labels.geozone.emergency', value: 'EMERGENCY'},
  {name: 'labels.geozone.other', value: 'OTHER'},
]

export default {
  name: 'Geozone',
  fields,
  restrictionTypes,
  reasonTypes
}