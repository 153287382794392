<template>
  <div id="color-picker" class="colorPickerContainer w-100 h-100">
    <div class="colorPicker">
      <Sketch v-model="colors"></Sketch>
      <button id="color-picker-update" class="btn btn-primary m-2" @click="update()">
        {{ $t("labels.common.update") }}
      </button>
      <button id="color-picker-cancel" class="btn btn-primary m-2" @click="cancel()">
        {{ $t("labels.common.cancel") }}
      </button>
    </div>
  </div>
</template>
<script>

import {Sketch} from '@ckpack/vue-color';
import A from '../../constants/actions';

export default {
  name: 'ColorPicker',
  components: {
    Sketch,
  },
  computed: {
    colors: {
      get() {
        return this.$store.state.colorPickerStore.selectedColor;
      },
      set(colors) {
        if (colors) this.$store.dispatch(A.COLOR_PICKER_UPDATE_COLOR, colors);
      },
    },
  },
  methods: {
    cancel() {
      this.$store.dispatch(A.COLOR_PICKER_CANCEL);
    },
    update() {
      this.$store.dispatch(A.COLOR_PICKER_UPDATE);
    }
  }

};
</script>
<style src="./colorPicker.css"></style>