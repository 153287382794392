<template>
  <div class="table-row header">
    <div class="icon-column operationIcons" v-if="isDronePlanModel()"/>
    <div class="icon-column geozoneIcons" v-if="isGeozoneModel()"/>
    <div :class="getStyle(header)"
         :key="$t(header.name)"
         @click="onSort(header.propertyName)"
         v-for="header in headers">{{ $t(header.name) }}
      <img :src="getImg()" class="arrowClass" v-if="lastSortCriteria(header.propertyName)">
    </div>
    <div class="button-column" v-if="showButtons()"></div>
  </div>
</template>

<script>
import ImgUtils from '../../../../utils/ImgUtils';
import DronePlanModel from '../../../../model/operationPlan';
import GeozoneModel from '../../../../model/geozone';

export default {
  name: 'ListHeader',
  props: ['headers', 'onSort', 'elements', 'sortCriteria', 'reverse', 'buttons', 'model'],
  methods: {
    lastSortCriteria(property) {
      return property === this.sortCriteria;
    },
    getImg() {
      return this.reverse ? ImgUtils.getButtonImg('sort_descending', 'svg') :
        ImgUtils.getButtonImg('sort_ascending', 'svg');
    },
    getStyle(header) {
      const tableStyle = header.tableStyle ? header.tableStyle : 'text';
      return [tableStyle, 'cursor-type'].join(' ');
    },
    showButtons() {
      return this.buttons;
    },
    isDronePlanModel() {
      return this.model.name === DronePlanModel.name;
    },
    isGeozoneModel() {
      return this.model.name === GeozoneModel.name;
    }
  }
};
</script>
<style src="../../utm.css"></style>