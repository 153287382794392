import FeatureUtils from './FeatureUtils';
import {MultiPoint, Point} from 'ol/geom';
import {Circle as CircleStyle, Fill, Icon, Stroke, Style} from 'ol/style';
import Store from '../store';
import OpWaypointFormRenderingUtils from "./dronePlans/OpWaypointFormRenderingUtils";
import GeozoneRestrictionTypeConfig from "../config/geozone/geozoneRestrictionTypeConfig";

const DEFAULT_BORDER_WIDTH = 5;

export default class FormMapLayersStyleUtils {

  static buildOperationVolumeStyleFunction(stylingConfig) {
    return () => {
      return new Style({
        stroke: new Stroke({
          color: FeatureUtils.Styling.toRgba(stylingConfig.colors.borderColor),
          width: DEFAULT_BORDER_WIDTH
        }),
        fill: new Fill({
          color: FeatureUtils.Styling.toRgba(stylingConfig.colors.fillColor)
        }),
      });
    }
  }

  static buildGeozoneVolumeStyleFunction(stylingConfig) {
    return () => {
      return new Style({
        stroke: new Stroke({
          color: FeatureUtils.Styling.toRgba(stylingConfig.borderColor),
          width: DEFAULT_BORDER_WIDTH
        }),
        fill: new Fill({
          color: FeatureUtils.Styling.toRgba(stylingConfig.fillColor)
        }),
      });
    }
  }

  static buildOperationWaypointsStyleFunction(stylingConfig) {
    return (feature) => {
      return [
        getLineStringStyle(stylingConfig),
        getWaypointStyle(stylingConfig, feature),
        ...getClimbOrDescendStyles(stylingConfig, feature),
        getCurrentPointStyle(stylingConfig, feature)
      ];
    }
  }

  static buildOperationWaypointsDottedStyleFunction(stylingConfig) {
    return () => {
      return new Style({
        stroke: new Stroke({
          color: FeatureUtils.Styling.toRgba(stylingConfig.colors.borderColor),
          width: DEFAULT_BORDER_WIDTH,
          lineDash: [10, 15]
        }),
      });
    }
  }

  static buildGeozoneCoordinatesStyleFunction(stylingConfig, currentIndex) {
    return (feature) => {
      const geozoneProhibitedColorConfig = Store.getters.getGeozoneColorConfigByRestrictionType(
        GeozoneRestrictionTypeConfig.PROHIBITED);
      return [
        getGeozoneBaseStyle(geozoneProhibitedColorConfig),
        getGeozoneMultiPointStyle(geozoneProhibitedColorConfig, feature),
        getGeozonePointStyle(geozoneProhibitedColorConfig, feature),
        getGeozoneCurrentPointStyle(geozoneProhibitedColorConfig, feature, currentIndex)
      ]
    }
  }

  static buildGeozoneBaseStyleFunction() {
    return () => {
      const geozoneProhibitedColorConfig = Store.getters.getGeozoneColorConfigByRestrictionType(
        GeozoneRestrictionTypeConfig.PROHIBITED);
      return [
        getGeozoneBaseStyle(geozoneProhibitedColorConfig),
      ]
    }
  }
}

function getLineStringStyle(stylingConfig) {
  return new Style({
    stroke: new Stroke({
      color: FeatureUtils.Styling.toRgba(stylingConfig.colors.borderColor),
      width: DEFAULT_BORDER_WIDTH
    }),
  });
}

function getWaypointStyle(stylingConfig, feature) {
  return buildMultiPointStyle(stylingConfig.colors.borderColor, feature.getGeometry().getCoordinates());
}

function getGeozoneBaseStyle(stylingConfig) {
  return new Style({
    stroke: new Stroke({
      color: FeatureUtils.Styling.toRgba(stylingConfig.borderColor),
      width: DEFAULT_BORDER_WIDTH
    }),
    fill: new Fill({
      color: FeatureUtils.Styling.toRgba(stylingConfig.fillColor)
    }),
  })
}

function getGeozoneMultiPointStyle(stylingConfig, feature) {
  let coordinates = feature.getGeometry().getCoordinates();
  if (feature.getGeometry().getType() === 'Polygon') {
    coordinates[0].splice(-1, 1);
    coordinates = coordinates[0];
  }
  return buildMultiPointStyle(stylingConfig.borderColor, coordinates);
}

function getGeozonePointStyle(stylingConfig, feature) {
  return buildPointStyle(stylingConfig.borderColor, feature.getGeometry().getCoordinates());
}

function getGeozoneCurrentPointStyle(stylingConfig, feature, currentIndex) {
  let coordinates = feature.getGeometry().getCoordinates();
  coordinates = coordinates.length === 1 && coordinates[0] ? coordinates[0] : coordinates;
  const currentCoordinate = currentIndex < coordinates.length ? coordinates[currentIndex] : null;
  return currentCoordinate ? buildPointStyle(stylingConfig.borderColor, currentCoordinate) : new Style({});
}

function getClimbOrDescendStyles(stylingConfig, feature) {
  const geometryCoordinates = feature.getGeometry().getCoordinates();
  return geometryCoordinates.slice(0, geometryCoordinates.length - 1)
    .map((point, index) => buildPointPair(geometryCoordinates, index))
    .map(pointPair => buildClimbOrDescendStyle(stylingConfig, pointPair.firstPoint, pointPair.secondPoint))
    .filter(style => style !== null);
}

function getCurrentPointStyle(stylingConfig, feature) {
  const coordinates = feature.getGeometry().getCoordinates()[Store.getters.getCurrentIndex];
  return buildPointStyle(stylingConfig.colors.borderColor, coordinates);
}

function buildPointStyle(strokeColor, geometry) {
  const color = JSON.parse(JSON.stringify(strokeColor));
  color.a = color.a > 0.5 ? color.a / 1.5 : color.a * 2.5;
  return new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: FeatureUtils.Styling.toRgba(color),
        width: 3,
      }),
    }),
    geometry: new Point(geometry),
  })
}

function buildMultiPointStyle(color, coordinates) {
  return new Style({
    image: new CircleStyle({
      radius: 8,
      fill: new Fill({
        color: FeatureUtils.Styling.toRgba(color),
      }),
    }),
    geometry: new MultiPoint(coordinates),
  })
}

function buildPointPair(geometryCoordinates, index) {
  return {
    firstPoint: buildPointConfig(geometryCoordinates, index),
    secondPoint: buildPointConfig(geometryCoordinates, index + 1)
  };
}

function buildPointConfig(geometryCoordinates, index) {
  return {
    geometry: new Point(geometryCoordinates[index]),
    altitude: Store.getters.getTrajectoryElements[index].altitude
  }
}

function buildClimbOrDescendStyle(stylingConfig, firstPoint, secondPoint) {
  if (OpWaypointFormRenderingUtils.isClimbOrDescendOccurringBetweenPoints(firstPoint, secondPoint)) {
    return new Style({
      image: buildClimbOrDescendIcon(
        stylingConfig,
        OpWaypointFormRenderingUtils.getClimbOrDescendIconAnchor(firstPoint, secondPoint),
        OpWaypointFormRenderingUtils.getClimbOrDescendIconRotation(firstPoint, secondPoint)
      ),
      geometry: firstPoint.geometry,
    })
  }
  return null;
}

function buildClimbOrDescendIcon(stylingConfig, anchor, rotation) {
  return new Icon({
    color: FeatureUtils.Styling.toRgba(stylingConfig.colors.borderColor),
    anchor: anchor,
    src: 'icons/op_climb_descend_indicator_arrow.svg',
    rotation: rotation,
    scale: stylingConfig.iconSize / 150
  });
}