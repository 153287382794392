<template>
  <div id="geozone-filters-container-id">
    <div class="geozoneFiltersContainer mb-1">
      <button
        id="dynamic-geozone-filter-button"
        :class="['col-6', 'btn', 'btn-primary'].join(' ')"
        :style="getFilterButtonStyle(isDynamicFilterSelected)"
        @click="selectDynamicFilter()"
      >
        {{ $t('labels.geozone.dynamic') }}
      </button>
      <button
        id="static-geozone-filter-button"
        class="col-6 btn btn-primary"
        :class="['col-6', 'btn', 'btn-primary'].join(' ')"
        :style="getFilterButtonStyle(isStaticFilterSelected)"
        @click="selectStaticFilter"
      >
        {{ $t('labels.geozone.static') }}
      </button>
    </div>
  </div>
</template>

<script>

import GeozoneFilterType from "../../../constants/geozoneFilterType";
import {mapGetters} from "vuex";
import A from '../../../constants/actions';
import FeatureUtils from "../../../utils/FeatureUtils";
import FeatureType from '../../../constants/featureType';

export default {
  name: 'GeozoneFilters',
  computed: {
    ...mapGetters({
      activeFilter: 'geozoneActiveFilter'
    }),
    isDynamicFilterSelected() {
      return this.activeFilter === GeozoneFilterType.DYNAMIC;
    },
    isStaticFilterSelected() {
      return this.activeFilter === GeozoneFilterType.STATIC;
    },
  },
  methods: {
    selectDynamicFilter() {
      this.selectFilter(GeozoneFilterType.DYNAMIC);
    },
    selectStaticFilter() {
      this.selectFilter(GeozoneFilterType.STATIC);
    },
    selectFilter(filter) {
      this.$store.dispatch(A.INFORMATION_PANEL_TOGGLE_GEOZONE_FILTER, filter)
    },
    getFilterButtonStyle(isFilterSelected) {
      const geozoneFeatureTypeColor = FeatureUtils.Styling.getFeatureColor(FeatureType.GEOZONE)

      return isFilterSelected ? {
        'background-color': FeatureUtils.Styling.toRgba(geozoneFeatureTypeColor)
      } : {};
    },
  }
}
</script>