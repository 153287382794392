import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import FormMapLayersConfig from "../config/FormMapLayersConfig";
import Store from "../store";
import A from "../constants/actions";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import MapUtils from "./MapUtils";
import Collection from "ol/Collection";
import GeozoneGeometryConfig from '../config/geozone/geozoneGeometryConfig';
import {i18n} from '../internationalization/index';
import turf, {kinks, polygon} from "turf";
import uomConfig from "../config/uomConfig";
import UomUtils from "./UomUtils";
import FeatureType from "@/constants/featureType";
import Polygon from "ol/geom/Polygon";
import FormMapLayersStyleUtils from "@/utils/FormMapLayersStyleUtils";
import StyleFunctionService from "@/styleFunction/styleFunctionService";
import GeozoneRestrictionTypeConfig from "@/config/geozone/geozoneRestrictionTypeConfig";

export default class FormMapLayersUtils {

  static clearMapInteractionAndFormGeometryLayers() {
    clearMapInteraction();
    removeAllFormGeometryLayers();
  }

  static removeVolumeAndWaypointGeometryLayers() {
    Store.dispatch(A.MAP_REMOVE_ALL_LAYERS, Object.values(FormMapLayersConfig.CONFIG_IDS));
  }

  static removeDottedGeometryLayer() {
    Store.dispatch(A.MAP_REMOVE_ALL_LAYERS, [FormMapLayersConfig.DOTTED_WAYPOINTS]);
    clearMapInteraction();
  }

  static startCircleDrawing(geometryConsumer, formLayerId) {
    Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, i18n.global.t('logMessages.geometryStartDrawing'));
    const circleDrawingInteraction = buildCircleDrawingInteraction(geometryConsumer, formLayerId);
    Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, circleDrawingInteraction);
  }

  static startVolumeDrawing(geometryConsumer, formLayerId) {
    Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, i18n.global.t('logMessages.geometryStartDrawing'));
    const polygonDrawingInteraction = buildPolygonDrawingInteraction(geometryConsumer, formLayerId);
    Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, polygonDrawingInteraction);
  }

  static startWaypointDrawing(geometryConsumer) {
    Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, i18n.global.t('logMessages.geometryStartDrawing'));
    const linestringDrawingInteraction = buildLinestringDrawingInteraction(geometryConsumer);
    Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, linestringDrawingInteraction);
  }

  static startWaypointEditing() {
    Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, i18n.global.t('logMessages.dragAWaypoint'));
    Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, buildWaypointEditingInteraction());
  }

  static stopWaypointEditing() {
    clearMapInteraction();
    Store.dispatch(A.TRAJECTORY_UPDATE_ELEMENTS_POSITIONS, getDisplayedWaypointsFeature().getGeometry());
  }

  static startVolumeEditing() {
    Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, i18n.global.t('logMessages.dragAPoint'));
    Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, buildVolumeEditingInteraction());
  }

  static startGeozoneGeometryEditing(geozone) {
    Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, i18n.global.t('logMessages.editGeozoneOnMap'));
    Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, buildGeozoneEditingInteraction(geozone));
  }

  static stopVolumeEditing(geometryConsumer) {
    clearMapInteraction();
    geometryConsumer(getDisplayedVolumesFeature());
  }

  static addNewWaypoint(geometryConsumer) {
    Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, i18n.global.t('logMessages.addWaypoint'));
    const pointDrawingInteraction = buildPointDrawingInteraction(geometryConsumer);
    Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, pointDrawingInteraction);
  }

  static clearVolumeAndWaypointGeometries(formLayerId, geometries) {
    FormMapLayersUtils.removeVolumeAndWaypointGeometryLayers();
    FormMapLayersUtils.renderGeometries(formLayerId, geometries);
  }

  static clearMapAndRenderGeometries(formLayerId, geometries, currentIndex) {
    removeAllFormGeometryLayers();
    FormMapLayersUtils.renderGeometries(formLayerId, geometries, currentIndex);
  }

  static renderGeometries(formLayerId, geometries, currentIndex) {
    const formVectorLayer = createVectorLayer(formLayerId, currentIndex);
    Store.dispatch(A.MAP_ADD_FORM_LAYER, formVectorLayer);
    Store.dispatch(A.MAP_ADD_FORM_GEOMETRY_TO_LAYER, {layerId: formLayerId, geometries: geometries});
  }

  static constructCircleFromDrawnGeometry(drawnGeometry) {
    const center = drawnGeometry.getCenter();
    const radius = drawnGeometry.getRadius();
    const turfCenterPoint = turf.point([center[0], center[1]]);
    const turfEdgePoint = turf.point([center[0] + radius, center[1]]);
    const groundRadiusInMeters = turf.distance(turfCenterPoint, turfEdgePoint, 'meters');
    const groundRadius = UomUtils.convertValueToCurrentUom(groundRadiusInMeters, uomConfig.UOM.METERS);
    return {
      type: 'Circle',
      center: drawnGeometry.getCenter(),
      radius: groundRadius,
    };
  }

  static constructPolygonGeomFromDrawnGeometry(drawnGeometry) {
    return {
      type: 'Polygon',
      coordinates: drawnGeometry.getCoordinates()
    };
  }

  static constructPolygonGeomFromCoordinates(coordinates) {
    return {
      type: 'Polygon',
      coordinates: [coordinates.map(c => [parseFloat(c[0]), parseFloat(c[1])])]
    }
  }

  static constructGeozoneGeometryToDisplay(coordinates) {
    const coordinatesToDisplay = constructGeozoneCoordinatesToDisplay(coordinates);
    return GeozoneGeometryConfig.buildGeometryForCoordinates(coordinatesToDisplay);
  }

  static getEditedGeozoneGeometryFromMap(message) {
    const coordinates = getGeozoneOnMap(message.id)?.getGeometry().getCoordinates();
    const geometry = new Polygon(coordinates);
    const geometry4326 = geometry.transform('EPSG:3857', 'EPSG:4326');
    return {type: 'Polygon', coordinates: geometry4326.getCoordinates()};
  }

  static updateGeozoneGeometryOnMap(messageId, coordinates) {
    const polygon = new Polygon(coordinates);
    const geometry3857 = polygon.transform('EPSG:4326', 'EPSG:3857');
    getGeozoneOnMap(messageId)?.getGeometry().setCoordinates(geometry3857.getCoordinates());
  }

  static stopGeozoneGeometryEditing(geozoneId) {
    clearMapInteraction();
    resetGeozoneStyle(geozoneId);
  }

  static updateGeozoneStyle(messageId, index) {
    getGeozoneOnMap(messageId)?.setStyle(FormMapLayersStyleUtils.buildGeozoneCoordinatesStyleFunction(null, index));
  }

  static existSelfIntersectingGeometry(geometries) {
    return geometries ?
      geometries.some(geometry => geometry.getType() === 'Polygon' && isSelfIntersectingPolygon(geometry)) : false;
  }
}

function resetGeozoneStyle(geozoneId) {
  const stylingConfig = Store.getters.getStyleConfigForLayer(FeatureType.GEOZONE);
  const styleFunction = StyleFunctionService.createStyleFunction(stylingConfig);
  getGeozoneOnMap(geozoneId)?.setStyle(styleFunction);
}

function getGeozoneOnMap(geozoneId) {
  let geozoneLayerSource = MapUtils.getMapLayerSourceById(FeatureType.GEOZONE);
  return geozoneLayerSource.getFeatures().find(f => f.getProperties().associatedFeatureId === geozoneId);
}

function constructGeozoneCoordinatesToDisplay(coordinates) {
  return coordinates
    .filter(cord => cord[0] !== null && cord[1] !== null)
    .map(cord => [parseFloat(cord[0]), parseFloat(cord[1])]);
}

function createVectorLayer(formLayerId, currentIndex) {
  const config = FormMapLayersConfig.getConfigById(formLayerId);
  const stylingConfig = getFeatureTypeCurrentStylingConfig(config.stylingConfigFeatureType);
  const vectorLayer = new VectorLayer({
    source: new VectorSource(),
    style: config.getStyleFunction(stylingConfig, currentIndex),
  });
  vectorLayer.set('id', config.id);
  vectorLayer.setZIndex(config.zIndex);
  return vectorLayer;
}

function getFeatureTypeCurrentStylingConfig(featureType) {
  return featureType === FeatureType.GEOZONE ?
    Store.getters.getGeozoneColorConfigByRestrictionType(GeozoneRestrictionTypeConfig.PROHIBITED) :
    Store.state.themeStore.currentTheme.stylingConfigs.find(s => s.layer === featureType);
}

function buildPolygonDrawingInteraction(geometryConsumer, formLayerId) {
  const interaction = new Draw({source: new VectorSource(), type: 'Polygon'});
  interaction.on('drawend', buildPolygonDrawEndEventHandler(geometryConsumer, formLayerId));
  return interaction;
}

function buildCircleDrawingInteraction(geometryConsumer, formLayerId) {
  const interaction = new Draw({
    source: new VectorSource(),
    type: 'Circle'
  });
  interaction.on('drawend', buildPolygonDrawEndEventHandler(geometryConsumer, formLayerId));
  return interaction;
}

function buildLinestringDrawingInteraction(geometryConsumer) {
  const interaction = new Draw({source: new VectorSource(), type: 'LineString'});
  interaction.on('drawend', buildWaypointDrawEndEventHandler(geometryConsumer));
  return interaction;
}

function buildPointDrawingInteraction(geometryConsumer) {
  const interaction = new Draw({source: new VectorSource(), type: 'Point'});
  interaction.on('drawend', pointDrawEndEventHandler(geometryConsumer));
  return interaction;
}

function buildPolygonDrawEndEventHandler(geometryConsumer, formLayerId) {
  return (evt) => {
    const polygon = evt.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    FormMapLayersUtils
      .clearMapAndRenderGeometries(formLayerId, [polygon]);
    geometryConsumer(polygon);
    clearMapInteraction();
  };
}

function buildWaypointDrawEndEventHandler(geometryConsumer) {
  return (evt) => {
    const linestring = evt.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    FormMapLayersUtils
      .clearMapAndRenderGeometries(FormMapLayersConfig.CONFIG_IDS.OPERATION_PLAN_WAYPOINTS, [linestring]);
    geometryConsumer(linestring);
    clearMapInteraction();
  };
}

function buildWaypointEditingInteraction() {
  return new Modify({
    features: new Collection([getDisplayedWaypointsFeature()]),
    insertVertexCondition: () => false
  });
}

function getDisplayedWaypointsFeature() {
  return MapUtils.getMapLayerSourceById(FormMapLayersConfig.CONFIG_IDS.OPERATION_PLAN_WAYPOINTS).getFeatures()[0];
}

function clearMapInteraction() {
  Store.dispatch(A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, null);
  Store.dispatch(A.MAP_TOGGLE_DRAW_MODE, null);
}

function removeAllFormGeometryLayers() {
  Store.dispatch(A.MAP_REMOVE_ALL_LAYERS,
    [...Object.values(FormMapLayersConfig.CONFIG_IDS),
      FormMapLayersConfig.DOTTED_WAYPOINTS,
      FormMapLayersConfig.GEOZONE_COORDINATES]);
}

function pointDrawEndEventHandler(geometryConsumer) {
  return (evt) => {
    const point = evt.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    geometryConsumer(point);
  };
}

function buildVolumeEditingInteraction() {
  return new Modify({
    features: new Collection(getDisplayedVolumesFeature())
  });
}

function buildGeozoneEditingInteraction(geozone) {
  const features = MapUtils.getMapLayerSourceById(FeatureType.GEOZONE)
    .getFeatures().filter(f => f.getProperties().associatedFeatureId === geozone.id);
  return new Modify({
    features: new Collection(features)
  });
}

function getDisplayedVolumesFeature() {
  return MapUtils.getMapLayerSourceById(FormMapLayersConfig.CONFIG_IDS.OPERATION_PLAN_VOLUMES).getFeatures();
}

function isSelfIntersectingPolygon(geometry) {
  const kinkedPoly = polygon(geometry.getCoordinates());
  const unkinkedPoly = kinks(kinkedPoly);
  return unkinkedPoly.features.length > 1
}