export default {

  AUTH_UPDATE_TOKEN: 'authUpdateToken',

  AUTH_SET_USER_PROFILE: 'authSetUserProfile',

  ASSOC_FEATURE_ADD_TO_CACHE: 'assocFeatureAddToCache',

  ASSOC_FEATURE_REM_FROM_CACHE: 'assocFeatureRemFromCache',

  AOR_RESTRICTION_DIALOG_OPEN: 'aorRestrictionDialogOpen',

  AOR_RESTRICTION_DIALOG_CLOSE: 'aorRestrictionDialogClose',

  AIRSPACE_RESERVATION_DIALOG_OPEN: 'airspaceReservationDialogOpen',

  AIRSPACE_RESERVATION_DIALOG_CLOSE: 'airspaceReservationDialogClose',

  AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_OPEN: 'airspaceReservationRequestApproveDialogOpen',

  AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_CLOSE: 'airspaceReservationRequestApproveDialogClose',

  DRONE_ALERT_DETAILS_SHOW: 'droneAlertDetailsShow',

  DRONE_ALERT_DETAILS_CANCEL: 'droneAlertDetailsCancel',

  DRONE_ALERT_DETAILS_SAVE: 'droneAlertDetailsSave',

  DRONE_ALERT_DETAILS_UPDATE_FIELD: 'droneAlertDetailsUpdateField',

  DRONE_ID_TO_LINK_SET: 'droneIdToLinkSet',

  DIRECTORY_SELECT_TOPIC: 'directorySelectTopic',

  DIRECTORY_ADD_TOPIC: 'directoryAddTopic',

  UPDATE_USERNAME: 'updateUserName',

  UPDATE_TOKEN: 'updateToken',

  INIT_MAP_HANDLER: 'initMapHandler',

  UPDATE_BASE_MAP: 'updateBaseMap',

  FLY_TO_FEATURE: 'flyToFeature',

  ZOOM_TO_VIEWPORT: 'zoomToViewport',

  ZOOM_TO_EXTENT: 'zoomToExtent',

  MAP_ADD_MESSAGE: 'mapAddMessage',

  MAP_ADD_GEOSERVER_LAYERS: 'mapAddGeoserverLayers',

  MAP_REFRESH_LAYER_DATA_BY_ID: 'mapRefreshLayerDataById',

  MAP_MESSAGE_CLEANUP: 'mapMessageCleanup',

  MAP_CHANGE_LAYER_VISIBILITY: 'mapChangeLayerVisibility',

  MAP_REMOVE_FEATURES: 'mapRemoveFeatures',

  MAP_REFRESH: 'mapRefresh',

  MAP_VIEWPORT_CHANGED: 'mapViewportChanged',

  MAP_VIEWPORT_SNAPSHOT: 'mapViewPortSnapshot',

  MAP_WIPE: 'mapWipe',

  MAP_TOGGLE_DRAW_MODE: 'mapToggleDrawMode',

  MAP_RESET_ORIENTATION: 'mapResetOrientation',

  MAP_DESELECT_ALL_FEATURES: 'mapDeselectAllFeatures',

  MAP_SET_CLICKED_FEATURES: 'mapSetClickedFeatures',

  MAP_UPDATE_LAYER_FEATURE_GEOMETRY: 'mapUpdateLayerFeatureGeometry',

  INFORMATION_WIPE: 'informationWipe',

  CHANGE_LAST_RENDER_TIME: 'changeLastRenderTime',

  CLOSE_SERVICES_STATES: 'closeServicesStates',

  COLLAPSE_INFO_PANEL_MESSAGE_TOGGLE: 'collapseInfoPanelMessageToggle',

  COLOR_PICKER_SHOW: 'colorPickerToggle',

  COLOR_PICKER_CANCEL: 'colorPickerCancel',

  COLOR_PICKER_UPDATE_COLOR: 'colorPickerUpdateColor',

  COLOR_PICKER_UPDATE: 'colorPickerUpdate',

  CONFIGURATION_PANEL_HEIGHT_CHANGE: 'cofigurationPanelHeightChange',

  CONFIGURATION_PANEL_SELECT_TAB: 'configurationPanelSelectTab',

  DIALOG_HIDE: 'dialogHide',

  DIALOG_SHOW: 'dialogShow',

  DISPLAY_SERVICES_STATES: 'displayServicesState',

  DOCUMENTS_PANEL_SELECT_DOC: 'documentPanelSelectDocument',

  DOCUMENTS_PANEL_DESELECT_DOC: 'documentPanelDeselectDocument',

  DOCUMENTS_PANEL_UPDATE_DOCUMENTS: 'documentPanelUpdateDocuments',

  DOCUMENTS_UPDATE_IS_AVAILABLE: 'documentsUpdateIsAvailable',

  DOCUMENTS_RESET_IS_UPDATED: 'documentsResetIsUpdated',

  ENABLE_3D: 'enable3d',

  FAVORITES_PANEL_TOGGLE: 'favoritesPanelToggle',

  FIRFILTER_PANEL_TOGGLE: 'firFilterPanelToggle',

  GEOSERVER_LAYERS_READ_ALL: 'geoserverLayersReadAll',

  GEOSERVER_LAYERS_REMOVE_LAYER: 'geoserverLayersRemoveLayer',

  GEOSERVER_LAYERS_ADD_LAYER: 'geoserverLayersAddLayer',

  GLOBAL_CONFIGURATION_READ: 'globalConfigurationRead',

  GLOBAL_CONFIGURATION_SAVE: 'globalConfigurationSave',

  GLOBAL_CONFIGURATION_CANCEL_EDIT: 'globalConfigurationCancelEdit',

  GLOBAL_CONFIGURATION_EDIT_GEOZONE_CONFIG: 'globalConfigurationEditGeozoneConfig',

  GLOBAL_CONFIGURATION_EDIT_SYSTEM_CONFIG: 'globalConfigurationEditSystemConfig',

  GLOBAL_CONFIGURATION_EDIT_DISPLAY_CONFIG: 'globalConfigurationEditDisplayConfig',

  HUD_SET_COORDINATE: 'hudSetCoordinates',

  HUD_SET_OPENED_TAB: 'hudSetOpenedTab',

  INFORMATION_PANEL_CHANGE_DATE: 'informationPanelChangeDate',

  TIME_SLIDER_RESET_DATE: 'timeSliderResetDate',

  INFORMATION_PANEL_SELECT_TAB: 'informationPanelSelectTab',

  INFORMATION_PANEL_ADD_MSG: 'informationPanelAddMessage',

  INFORMATION_PANEL_TOGGLE_FILTER: 'informationPanelToggleFilter',

  INFORMATION_PANEL_TOGGLE_DATE_PICKER: 'informationPanelToggleDatePicker',

  INFORMATION_PANEL_TOGGLE: 'informationPanelToggle',

  INFORMATION_PANEL_SCROLL_TO_MESSAGE: 'informationPanelScrollToMessage',

  INFORMATION_PANEL_MESSAGE_SEEN: 'informationPanelMessageSeen',

  INFORMATION_PANEL_MESSAGE_CLEANUP: 'informationPanelMessageCleanup',

  INFORMATION_PANEL_MESSAGE_UPDATED: 'informationPanelMessageUpdated',

  INFORMATION_PANEL_TOGGLE_FAVORITE_MESSAGE: 'informationPanelToggleFavoriteMessage',

  INFORMATION_PANEL_TOGGLE_TASK_TAB: 'informationPanelToggleTaskTab',

  INFORMATION_PANEL_CLOSE_TASK_TAB: 'informationPanelCloseTaskTab',

  INFORMATION_PANEL_UPDATE_SELECTED_TASK_SUB_TAB: 'informationPanelUpdateSelectedTaskSubTab',

  INFORMATION_PANEL_TOGGLE_GEOZONE_FILTER: 'informationPanelToggleGeozoneFilter',

  LAYERS_CONFIG_PANEL_ADD_DYNAMIC_LAYERS: 'layersConfigPanelAddDynamicLayers',

  LAYERS_CONFIG_PANEL_SELECT_TAB: 'layersConfigPanelSelectTab',

  LAYERS_CONFIG_PANEL_TOGGLE: 'layersConfigPanelToggle',

  LAYERS_CONFIG_PANEL_TOGGLE_LAYER: 'layersConfigPanelToggleLayer',

  LAYERS_CONFIG_CANCEL_EDIT: 'layersConfigCancelEdit',

  LAYERS_CONFIG_UPDATE_CSW_SERVICE_PROPERTY: 'layersConfigUpdateCswServiceProperty',

  LAYERS_CONFIG_SET_AVAILABLE_LAYERS: 'layerConfigSetAvailableLayers',

  LAYERS_CONFIG_CLEAR_CSW_SERVICE_CONNECTION: 'layersConfigClearCswServiceConnection',

  LAYERS_CONFIG_TOGGLE_CONNECTOR_AND_AVAILABLE_LAYERS: 'layersConfigToggleConnectorAndAvailableLayers',

  LAYERS_CONFIG_OPEN_STORE_LAYER_DIALOG: 'layersConfigOpenStoreLayerDialog',

  LAYERS_CONFIG_CANCEL_STORE_LAYER_DIALOG: 'layersConfigCancelStoreLayerDialog',

  LAYERS_CONFIG_PROGRESS_INDICATOR_TOGGLE: 'layersConfigProgressIndicatorToggle',

  LEFT_PANEL_WIDTH_CHANGE: 'leftPanelWidthChange',

  NOTAMFILTER_SELECT_NOTAMFILTER: 'notamFilterSelectNotamFilter',

  NOTAMFILTER_SET_SELECTED_FILTER: 'notamFilterSeSelectedFilter',

  NOTAMFILTER_SAVE_NOTAMFILTER: 'notamFilterSaveNotamFilter',

  NOTAMFILTER_SAVE_AS_NOTAMFILTER: 'notamFilterSaveAsNotamFilter',

  NOTAMFILTER_CANCEL_EDIT: 'notamFilterCancelEdit',

  NOTAMFILTER_DELETE_FILTER: 'notamFilterDeleteFilter',

  NOTAMFILTER_READ_ALL_FILTERS: 'notamFilterReadAllFilters',

  NOTAMFILTER_TOGGLE_QCODE: 'notamFilterToggleQCode',

  NOTAMFILTER_TOGGLE_CATEGORY: 'notamFilterToggleCategory',

  NOTAMFILTER_UPDATE_PROPERTY: 'notamFilterUpdateProperty',

  NOTIFICATION_SOUND: 'notificationPlaySound',

  NOTIFICATION_SOUND_MUTE: 'notificationPlaySoundMute',

  NOTIFICATION_SOUND_UNMUTE: 'notificationPlaySoundUnmute',

  NOTIFICATION_STATUS_TOGGLE: 'notificationStatusToggle',

  OP_REJECTION_DIALOG_OPEN: 'opRejectionDialogOpen',

  OP_REJECTION_DIALOG_CLOSE: 'opRejectionDialogClose',

  OP_REVOKE_DIALOG_OPEN: 'opRevokeDialogOpen',

  OP_REVOKE_DIALOG_CLOSE: 'opRevokeDialogClose',

  POPUP_ADD_MESSAGE: 'popupAddMessage',

  POPUP_CLEANUP_MESSAGES: 'popupCleanupMessages',

  PROGRESS_INDICATOR_TOGGLE: 'progressIndicatorToggle',

  PROGRESS_INDICATOR_START: 'progressIndicatorStart',

  PROGRESS_INDICATOR_END: 'progressIndicatorEND',

  STATIC_DATA_RECEIVED: 'staticDataReceived',

  UPDATE_CURRENT_RESPONSIBILITY_AREA_FOR_CONFIG: 'updateCurrentResponsibilityAreaForConfig',

  THEME_READ_ALL_THEMES: 'themeReadAllThemes',

  THEME_UPDATE_DYNAMIC_LAYER_CONFIGS: 'themeUpdateDynamicLayerConfigs',

  THEME_CHANGE_DEFAULT_THEME: 'themeChangeDefaultTheme',

  THEME_SELECT_THEME: 'themeSelectTheme',

  THEME_UPDATE_NAME: 'themeUpdateName',

  THEME_SAVE_THEME: 'themeSaveTheme',

  THEME_CANCEL_EDIT: 'themeCancelEdit',

  THEME_DELETE_THEME: 'themeDeleteTheme',

  THEME_SAVE_THEME_AS: 'themeSaveThemeAs',

  THEME_SWITCH_STYLE: 'themeSwitchStyle',

  THEME_UPDATE_LAYER_STYLING: 'themeUpdateLayerStyling',

  THRESHOLD_READ_ALL_THRESHOLDS: 'thresholdReadAllThresholds',

  THRESHOLD_SELECT_THRESHOLDS: 'thresholdSelectThreshold',

  THRESHOLD_SAVE_THRESHOLDS: 'thresholdSaveThreshold',

  THRESHOLD_CANCEL_EDIT: 'thresholdCancelEdit',

  THRESHOLD_UPDATE_VALUE: 'thresholdUpdateValue',

  TIME_SLIDER_SET_DATE: 'timeSliderSetDate',

  UPDATE_NEW_UPDATE_AVAILABLE: 'updateNewUpdateAvailable',

  UPDATE_START_UPDATE_UOM_CHANGED: 'updateStartUpdateUomChanged',

  UPDATE_START_UPDATING: 'updateStartUpdating',

  UPDATE_FINISHED_UPDATING: 'updateFinishedUpdating',

  USER_ADD_USER_TOGGLE: 'userAddUserToggle',

  USER_ADD_USER_TO_CURRENT_GROUP: 'userAddUserToCurrentGroup',

  USER_REMOVE_USER_FROM_CURRENT_GROUP: 'userRemoveUserFromCurrentGroup',

  USER_READ_ALL_USERS: 'userReadAllUsers',

  USER_MANAGEMENT_SELECT_ROLE: 'userManagementSelectRole',

  USER_MANAGEMENT_RESET_ROLE: 'userManagementResetRole',

  USER_MANAGEMENT_SELECT_GROUP: 'userManagementSelectGroup',

  USER_MANAGEMENT_UPDATE_ROLE_NAME: 'userManagementUpdateRoleName',

  USER_MANAGEMENT_UPDATE_GROUP_NAME: 'userManagementUpdateGroupName',

  USER_MANAGEMENT_UPDATE_UOM: 'userManagementUpdateUom',

  USER_MANAGEMENT_ROLE_TOGGLE_VIEW: 'userManagementRoleToggleView',

  USER_MANAGEMENT_GROUP_TOGGLE_THEME: 'userManagementGroupToggleTheme',

  USER_MANAGEMENT_SAVE_GROUP: 'userManagementSaveGroup',

  USER_MANAGEMENT_SAVE_ROLE: 'userManagementSaveRole',

  USER_MANAGEMENT_GROUP_CANCEL_EDIT: 'userManagementGroupCancel',

  USER_MANAGEMENT_DELETE_ROLE: 'userManagementDeleteRole',

  USER_MANAGEMENT_GROUP_REPRESENTATIVES_READ_ALL: 'userManagementGroupRepresentativesReadAll',

  USER_MANAGEMENT_USER_CONNECTIONS_READ_ALL: 'userManagementUserConnectionsReadAll',

  USER_MANAGEMENT_SELECT_ACTIVE_GROUP_ROLE: 'userManagementSelectActiveGroupRole',

  USER_MANAGEMENT_REMOVE_VIEW_FROM_ATTACHED_ROLES: 'userManagementRemoveViewFromAttachedRole',

  USER_MANAGEMENT_SORTING_CONFIG_READ: 'userManagementSortingConfigRead',

  USER_MANAGEMENT_SORTING_CONFIG_UPDATE: 'userManagementSortingConfigUpdate',

  UTM_CONFIG_ADD_AOR: 'urmConfigAddAor',

  UTM_CONFIG_RESET: 'utmConfigReset',

  UTM_CONFIG_SAVE: 'utmConfigSave',

  UTM_CONFIG_SELECT_RESPONSIBILITY_AREA: 'utmConfigSelectResponsibilityArea',

  UTM_CONFIG_CANCEL_EDIT: 'utmConfigCancelEdit',

  UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY: 'utmConfigUpdateResponsibilityAreaProperty',

  UTM_PANEL_TOGGLE: 'utmPanelToggle',

  UTM_PANEL_SELECT_TAB: 'utmPanelSelectTab',

  UTM_PANEL_SELECT_ELEM: 'utmPanelSelectElem',

  UTM_PANEL_SELECTED_ELEM_SNAPSHOT: 'utmPanelSelectElement',

  UTM_PANEL_DESELECT_ELEM: 'utmPanelDeselectElem',

  UTM_PANEL_OUTLOOK_PANEL_SELECT_FILTER: 'outlookPanelSelectFilter',

  REFRESH_LAYER: 'refreshLayer',

  VIEW_EDITOR_TOGGLE_SETTING: 'viewEditorToggleSetting',

  VIEW_EDITOR_TOGGLE_LOCATION: 'viewEditorToggleLocation',

  VIEW_EDITOR_TOGGLE_NOTIFICATION_TYPE: 'viewEditorToggleNotificationType',

  VIEW_EDITOR_TOGGLE_ALTITUDE_FILTERED_FEATURE: 'viewEditorToggleAltitudeFilteredFeature',

  VIEW_EDITOR_SELECT_NOTIFICATION_TYPE: 'viewEditorSelectNotificationType',

  VIEW_EDITOR_SELECT_POPERTY: 'viewEditorSelectProperty',

  VIEW_EDITOR_UPDATE_LOCATION_ORDER: 'viewEditorUpdateLocationOrder',

  VIEW_EDITOR_TOGGLE_ICAO_LOCATIONS_DIALOG_CONFIG: 'viewEditorToggleIcaoLocationsDialogConfig',

  VIEW_EDITOR_ALTITUDE_FILTERED_FEATURES_DIALOG_TOGGLE: 'viewEditorAltitudeFilteredFeaturesDialogToggle',

  VIEW_SELECT_VIEW: 'viewSelectView',

  VIEW_READ_ALL_VIEWS: 'viewReadAllViews',

  VIEW_SAVE_VIEW: 'viewSaveView',

  VIEW_SAVE_VIEW_AS: 'viewSaveViewAs',

  VIEW_CANCEL_EDIT: 'viewCancelEdit',

  VIEW_DELETE_VIEW: 'viewDeleteView',

  VIEW_RESET_VIEWPORT: 'viewResetViewPort',

  VIEW_SET_RESETTING_VIEWPORT_IN_PROGRESS: 'viewSetResettingViewportInProgress',

  VIEW_SET_RESETTING_VIEWPORT_DONE: 'viewSetResettingViewportDone',

  VIEW_RESET_TIME_SLIDER: 'viewResetTimeSlider',

  VIEW_RESET_ALTITUDE_SLIDER: 'viewResetAltitudeSlider',

  VIEW_UPDATE_NAME: 'viewUpdateName',

  VIEW_TOGGLE_TASK_SOUND_NOTIFICATION: 'viewToggleTaskSoundNotification',

  WEBSOCKET_CONNECT: 'websocketConnect',

  WEBSOCKET_SEND_WITH_TOKEN: 'websocketSendWithToken',

  WEBSOCKET_HEARTBEAT: 'websocketHeartbeat',

  WEBSOCKET_REMOVE_HEARTBEAT_FROM_CACHE: 'websocketRemoveHeartbeatFromCache',

  WEBSOCKET_INVALID_TOKEN: 'invalidToken',

  WEBSOCKET_SET_CONFIG: 'websocketSetConfig',

  WEBSOCKET_CONNECTED: 'websocketConnected',

  WEBSOCKET_CLOSED: 'websocketClosed',

  TAKE_SNAPSHOT: 'takeSnapshot',

  UPDATE_HEALTH_CHECK: 'updateHealthCheck',

  MAP_TOGGLE_OVERLAY_EDIT_MESSAGE: 'mapToggleOverlayEditMessage',

  WEBSOCKET_SET_CLIENT_SERVER_OFFSET: 'websocketClientServerOffset',

  DISABLE_PARTIAL_SUBSCRIBE: 'disablePartialSubscribe',

  ENABLE_PARTIAL_SUBSCRIBE: 'enablePartialSubscribe',

  USER_MANAGEMENT_ROLE_TOGGLE_VIEW_DEFAULT: 'userManagementRoleToggleViewDefault',

  USER_MANAGEMENT_GROUP_TOGGLE_THEME_DEFAULT: 'userManagementGroupToggleThemeDefault',

  USER_MANAGEMENT_GROUPS_READ_DEFAULT_GROUP_ROLE: 'userManagementGroupReadDefaultGroupRole',

  USER_MANAGEMENT_TOGGLE: 'userManagementToggle',

  UPDATE_RESPONSIBILITY_AREA_STATIC_DATA: 'updateResponsibilityAreaStaticData',

  DOCUMENTS_PANEL_DELETE_DOCUMENT: 'documentsPanelDeleteDocument',

  DOCUMENTS_PANEL_ADD_DOCUMENT: 'documentsPanelAddDocument',

  EMBEDDED_MAP_DISPLAY: 'embeddedMapDisplay',

  EMBEDDED_MAP_DESTROY: 'embeddedMapDestroy',

  EMBEDDED_MAP_INITIALIZE: 'embeddedMapInitialize',

  EMBEDDED_MAP_SET_BACKGROUND: 'embeddedMapSetBackground',

  EMBEDDED_MAP_MESSAGE_CLEANUP: 'embeddedMapMessageCleanup',

  EMBEDDED_MAP_MESSAGE_IDS_AND_HIGHLIGHT_FEATURE_PROPS_CLEANUP: 'embeddedMapMessageIdsAndHighlightFeaturePropsCleanup',

  EMBEDDED_MAP_DISPLAY_MESSAGES: 'embeddedMapDisplayMessages',

  EMBEDDED_MAP_HIGHLIGHT_TARGET_MESSAGE: 'embeddedMapZoomInToTargetMessage',

  EMBEDDED_MAP_RERENDER: 'embeddedMapRerender',

  EMBEDDED_MAP_HIDE: 'embeddedMapHide',

  EMBEDDED_MAP_REMOVE_DISPLAYED_MESSAGE: 'embeddedMapRemoveDisplayedMessage',

  USER_MANAGEMENT_DISABLE_DISPLAY_OF_ROLE_EDITOR: 'userManagementDisableDisplayOfRoleEditor',

  USER_MANAGEMENT_ENABLE_DISPLAY_OF_ROLE_EDITOR: 'userManagementEnableDisplayOfRoleEditor',

  USER_MANAGEMENT_GROUP_ROLE_REPRESENTATIVES_READ_ALL: 'userManagementGroupRoleRepresentativesReadAll',

  DRONE_ADD_DRONE_INFO: 'droneAddDroneInfo',

  DRONE_TOGGLE_FETCHING_INFO: 'droneToggleFetchingInfo',

  FORM_WRAPPER_OPEN: 'formWrapperOpen',

  FORM_WRAPPER_CLOSE: 'formWrapperClose',

  FORM_WRAPPER_SET_CURRENT_MODEL_IMPORTED: 'formWrapperSetCurrentModelImported',

  MAP_ADD_FORM_LAYER: 'mapAddFormLayer',

  MAP_ADD_FORM_GEOMETRY_TO_LAYER: 'mapAddFormGeometryToLayer',

  MAP_REMOVE_ALL_LAYERS: 'mapRemoveAllLayers',

  TRAJECTORY_ADD_ELEMENT_TO_INDEX: 'trajectoryAddElementToIndex',

  TRAJECTORY_INIT_ELEMENTS: 'trajectoryInitElements',

  TRAJECTORY_CLEAR_ELEMENTS: 'trajectoryClearElements',

  TRAJECTORY_INCREASE_CURRENT_INDEX: 'trajectoryIncreaseCurrentIndex',

  TRAJECTORY_DECREASE_CURRENT_INDEX: 'trajectoryDecreaseCurrentIndex',

  TRAJECTORY_UPDATE_ELEMENT: 'trajectoryUpdateElement',

  TRAJECTORY_UPDATE_CORRIDOR_HORIZONTAL_BUFFER: 'trajectoryUpdateCorridorHorizontalBuffer',

  TRAJECTORY_CONSTRUCT_ELEMENTS_FROM_GEOMETRY: 'trajectoryConstructElementsFromGeometry',

  TRAJECTORY_UPDATE_ELEMENTS_POSITIONS: 'trajectoryUpdateElementsPositions',

  TRAJECTORY_UPDATE_WAYPOINT_TYPE: 'trajectoryUpdateWaypointType',

  TRAJECTORY_SET_ELEMENTS: 'trajectorySetElements',

  TRAJECTORY_REMOVE_WAYPOINT: 'trajectoryRemoveWaypoint',

  ALERT_REPLY_DETAILS_SHOW: 'alertReplyDetailsShow',

  ALERT_REPLY_DETAILS_CANCEL: 'alertReplyDetailsCancel',

  ALERT_REPLY_DETAILS_SET_MESSAGE: 'alertReplyDetailsSetMessage',

  ALERT_REPLY_DETAILS_SEND: 'alertReplyDetailsSend',

  SELECT_FEATURE: 'selectFeature',

  REMOVE_SELECT_FEATURE: 'removeSelectFeature',

  WHITELIST_PANEL_OPEN: 'whiteListPanelOpen',

  WHITELIST_PANEL_CLOSE: 'whiteListPanelClose',

  WHITELIST_PANEL_UPDATE_MONITORED_GEOZONE: 'whiteListPanelUpdateMonitoredGeozone',

};