import AppConfig from './appConfig';
import {i18n} from "@/internationalization";

const CRS_TYPE = {
  HAE: 'HAE',
  AMSL: 'AMSL',
  AGL: 'AGL'
}

const crsTypeConfigs = {
  [CRS_TYPE.HAE]: {
    displayValue: 'labels.common.hae',
    utmModelValue: 'ABOVE_ELLIPSOID',
    rasModelValue: 'WGS84',
    telemetryModelValue: 'ABOVE_ELLIPSOID'
  },
  [CRS_TYPE.AGL]: {
    displayValue: 'labels.common.agl',
    utmModelValue: 'ABOVE_GND',
    rasModelValue: 'AGL',
    telemetryModelValue: 'ABOVE_GND'
  },
  [CRS_TYPE.AMSL]: {
    displayValue: 'labels.common.amsl',
    utmModelValue: 'ABOVE_MSL',
    rasModelValue: 'AMSL',
    telemetryModelValue: 'ABOVE_MSL'
  }
}

function getConfigForCrsType(crsType) {
  return crsTypeConfigs[crsType];
}

function getCurrentCrsTypeDisplayValue() {
  const currentCrsType = AppConfig.ui.globalCrs;
  return i18n.global.t(getConfigForCrsType(currentCrsType).displayValue);
}

export default {
  CRS_TYPE,
  crsTypeConfigs,
  getConfigForCrsType,
  getCurrentCrsTypeDisplayValue
}