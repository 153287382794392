<template>
  <div
    :class="[this.isDisabled ? 'disabled-action-button' : 'action-button']"
    id="approve-button-id">
    <a @click="approveOperationPlan()">
      <div>
        <span class="button-icon">
          <img class="infoPanelButton" v-bind:src="getImg('simplified_approve')"
               :alt="$t('labels.operationPlan.approveButton')">
        </span>
      </div>
      <span class="button-text">{{ $t('labels.common.approve') }}</span>
    </a>
  </div>
</template>
<script>

import A from '../../../../constants/actions';
import DialogUtils from '../../../../utils/DialogUtils';
import Logger from '../../../../utils/LoggerUtils';
import OperationPlanServices from '../../../../services/operationPlanService';
import PopupUtils from '../../../../utils/PopupUtils';
import OperationPlanState from '../../../../constants/operationPlanState';
import TimeService from '../../../../services/timeService';
import FeatureUtils from '../../../../utils/FeatureUtils';
import DronePlanUtils from '../../../../utils/dronePlans/DronePlanUtils';
import AppConfig from '../../../../config/appConfig';
import PermissionUtils from "../../../../utils/PermissionUtils";
import UserRolePriorities from "../../../../utils/dronePlans/UserRolePriorities";

export default {
  name: 'ApproveOpButton',
  props: ['message', 'getImg'],

  computed: {
    isDisabled() {
      if (this.message) {
        return (this.isNotApprovable() || this.currentUserHasNoRightsForLocalApproval()) ||
          PermissionUtils.isReadOnlyUser();
      }
      return true;
    },
  },

  methods: {
    isNotApprovable() {
      const offsetTimeInMillis = TimeService.timeOffsetInMilliseconds();
      const stateToCheck = FeatureUtils.State.getOperationState(this.message.state, this.message.stateAttributeType);

      return [
        OperationPlanState.ACTIVATED.state,
        OperationPlanState.CLOSED.state,
        OperationPlanState.NONCONFORMING.state,
        OperationPlanState.INACTIVETWR.state,
        OperationPlanState.NO_SERVICE.state]
        .indexOf(stateToCheck) !== -1 || offsetTimeInMillis !== 0;
    },
    currentUserHasNoRightsForLocalApproval() {
      return !DronePlanUtils.currentUserHasRightsForLocalApproval(this.message, AppConfig.providerId);
    },
    approveOperationPlan() {
      PermissionUtils.performActionIfAllowed(this.isDisabled, this.performApproveOperationPlan);
    },
    performApproveOperationPlan() {
      const currentState = this.message.state;
      const changedState = OperationPlanServices.getOpChangedState(currentState);
      DialogUtils.confirmOperation({
        message: this.getApproveOperationPlanConfirmationMessage(this.message.state),
        yesAction: () => OperationPlanServices.grantOperationPlan(this.createConfigForApproveOp(changedState))
      });
    },
    getApproveOperationPlanConfirmationMessage(state) {
      switch (state) {
        case OperationPlanState.PROPOSED.state:
          return this.$i18n.t('confirmationMessages.dronePlanApprove');
        case OperationPlanState.AUTHORIZED.state:
          return this.$i18n.t('confirmationMessages.dronePlanNoTakeOff');
        case OperationPlanState.TAKEOFFREQUESTED.state:
          return this.$i18n.t('confirmationMessages.dronePlanActivation');
      }
    },
    createConfigForApproveOp(newState) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      const operationPlanId = this.message.id;
      return {
        operationPlanId: operationPlanId,
        partialResultId: UserRolePriorities.getPartialResultIdForOpAndCurrentUser(this.message),
        reasonMessage: null,
        proposedTimeOffsetInMinutes: null,
        successCallback: () => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.info(this.$i18n.t('logMessages.dronePlanStateChanged',
            {oldState: this.message.state, newState: newState}
          ));
          PopupUtils.success(this.$i18n.t('popupMessages.dronePlanStateUpdated',
            {title: DronePlanUtils.getPublicTitleForPopUp(this.message)}
          ));
          this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('errorMessages.dronePlanStateChangeError') + error);
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.dronePlanStateChangeError'));
          this.$store.dispatch(A.REMOVE_SELECT_FEATURE);
        },
      };
    },
  },
};
</script>

<style src="../../informationPanel.css"></style>
