import FeatureType from "../constants/featureType";
import Features from "./features";

const baseFeaturesConfig = [
  {
    baseFeatureId: FeatureType.GEOZONE_BASE_FEATURE,
    features: [
      FeatureType.GEOZONE,
      FeatureType.GEOZONE_OUTLOOK
    ]
  },
  {
    baseFeatureId: FeatureType.OPERATION_PLAN,
    features: [
      FeatureType.OPERATION_PLAN_PROPOSED,
      FeatureType.OPERATION_PLAN_ACCEPTED,
      FeatureType.OPERATION_PLAN_ACTIVATED,
      FeatureType.OPERATION_PLAN_OUTLOOK
    ]
  },
  {
    baseFeatureId: FeatureType.DRONE_FLIGHT,
    features: [
      FeatureType.DRONE_FLIGHT,
      FeatureType.DRONE_FLIGHT_UNIDENTIFIED,
    ],
  }
];

function isBaseFeature(featureId) {
  return baseFeaturesConfig.some(config => config.baseFeatureId === featureId);
}

function belongsToABaseFeature(featureId) {
  return baseFeaturesConfig.some(config => config.features.includes(featureId));
}

function getBaseFeatureId(id) {
  return baseFeaturesConfig.find(config => config.features.includes(id)).baseFeatureId;
}

function getBaseFeatureFor(featureId) {
  const baseFeatureId = baseFeaturesConfig.find(config => config.features.includes(featureId)).baseFeatureId;
  return Features.getFeatureById(baseFeatureId);
}

function getBaseFeatureConfig(baseFeatureId) {
  return baseFeaturesConfig.find(baseFeaturesConfig => baseFeaturesConfig.baseFeatureId === baseFeatureId);
}

export default {
  baseFeaturesConfig,
  isBaseFeature,
  belongsToABaseFeature,
  getBaseFeatureId,
  getBaseFeatureFor,
  getBaseFeatureConfig
}