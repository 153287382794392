import {getCenter} from "ol/extent";
import Point from "ol/geom/Point";
import {Circle as CircleStyle, Fill, Icon, Style} from "ol/style";
import CommonStyleFunction from "../../CommonStyleFunction";
import GlobalConfigurationUtils from "../../../utils/GlobalConfigurationUtils";
import GlobalConfigurationIdConfig from "../../../config/GlobalConfigurationIdConfig";

export default class DronePlanIconStyleFunction {

  static buildIconStyle(featureProperties, stylingConfig) {
    const imageStyle = getImageStyle(featureProperties, stylingConfig);
    const centroid = new Point(getCenter(featureProperties.geometry.getExtent()));
    const offsets = {x: 8, y: -25};
    const color = featureProperties.colorConfigs.iconColor;
    const textStyle = featureProperties.clusteredFeatures > 1 ?
      CommonStyleFunction.getTextStyleConfig(stylingConfig, featureProperties.clusteredFeatures.toString(), offsets) : null;
    return [new Style({
      geometry: centroid,
      image: imageStyle,
      zIndex: 350
    }),
      textStyle ? new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: CommonStyleFunction.toRgba(color),
          }),
          displacement: [8, 27]
        }),
        geometry: centroid,
        zIndex: 351,
        text: textStyle
      }) : new Style({})]
  }
}

function getImageStyle(featureProperties, stylingConfig) {
  const mapPinpointIcon = 'icons/map_pinpoint.svg';
  const selectedPinpointColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
    GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.DRONE_PLAN_SELECTED_MESSAGE_PIN
  );
  const color = featureProperties.selected ? selectedPinpointColor : featureProperties.colorConfigs.iconColor;
  const iconScale = featureProperties.selected ? stylingConfig.iconSize / 60 : stylingConfig.iconSize / 120;
  const SVG_HEIGHT = 260;
  return new Icon({
    color: CommonStyleFunction.toRgba(color),
    src: mapPinpointIcon,
    rotation: 0,
    anchor: [0.5, SVG_HEIGHT],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    scale: iconScale
  })
}