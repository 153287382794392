<template>
  <div class="dateTimePickerContainer">
    <div
      id="timeSlider"
      class="timeSliderContainer timeSlider"
      v-on:click="click"
      v-on:touchend="click"
    >
      <vueSlider id="vue-time-slider"
                 :dotSize="16"
                 :tooltip-formatter="convertToHoursAndSeconds"
                 tooltip="always"
                 :height="4"
                 :interval="10"
                 :max="rightLimit"
                 :min="leftLimit"
                 :show="true"
                 :style="'margin-top:6px; padding:8px'"
                 :tooltipDir="'top'"
                 ref="FLSlider"
                 v-model="time"
      >
      </vueSlider>
    </div>
    <div id="reset-time-slider-button-container" class="resetButtonContainer">
      <button
        id="reset-time-slider-button"
        @click="resetTimeOffset()"
        class="btn btn-primary resetButton"
      >
        <img v-bind:src="getButtonImg('resetTimeSlider')">
      </button>
    </div>
    <div id="date-time-picker-container" class="datePickerContainer">
      <DatePicker
        :get-value="getDate"
        :set-value="setDate"
        customClass=""
        :format="dateFormatter"
        id="history-date-time-picker"
        icon-slot="icon-calendar">
      </DatePicker>
    </div>
  </div>
</template>

<script>
import A from './../../constants/actions';
import vueSlider from 'vue-3-slider-component';
import ApplicationConfig from '../../config/appConfig';
import Logger from '../../utils/LoggerUtils';
import DatePicker from "../../common/DatePicker";
import TimeService from '../../services/timeService';
import ImgUtils from '../../utils/ImgUtils';
import SubscriptionUtils from "../../utils/SubscriptionUtils";
import HudTabName from "../../constants/hudTabName";
import Store from "../../store";

export default {
  name: 'TimeSlider',
  props: ['forcedRefresh'],
  components: {
    vueSlider,
    DatePicker
  },
  data() {
    return {
      value: '',
      timeout: '',
    }
  },
  mounted: function () {
    document.getElementById("timeSlider").style.zoom = 1 / ApplicationConfig.ui.applicationZoomLevel;
  },
  computed: {
    time: {
      get() {
        return (this.$store.getters.getTimeSliderOffset) / 60000;
      },
      set(value) {
        this.value = value;
        if (this.isDifferentThenCurrentOffset(value)) {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => this.setTime(), 1000);
        }
      }
    },
    rightLimit() {
      return ApplicationConfig.timeSliderRightLimit;
    },
    leftLimit() {
      return ApplicationConfig.timeSliderLeftLimit;
    }
  },
  methods: {
    getDate() {
      const date = this.$store.getters.timeSliderDate;
      if (date) {
        const dt = new Date(date);
        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        return dt;
      }
      return '';
    },
    dateFormatter(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${year}-${month}-${day} ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes} UTC`;
    },
    setDate(value) {
      const date = new Date(Date.parse(value));
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      date.setSeconds(30, 0);
      this.$store.dispatch(A.INFORMATION_PANEL_CHANGE_DATE, date.toISOString());
      this.doPartialSubscribe();
    },
    isDifferentThenCurrentOffset(value) {
      return Store.getters.getTimeSliderOffset / 60000 !== value;
    },
    convertToHoursAndSeconds(value) {
      let minutesToSet = value < 0 ? -value : value;
      let hours = Math.floor(minutesToSet / 60);
      let minutes = minutesToSet - hours * 60;
      return (value < 0 ? '-' : '') + hours + ':' + minutes + ' H:m';
    },
    getButtonImg(name) {
      return ImgUtils.getButtonImg(name, 'svg');
    },
    resetTimeOffset() {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.$store.dispatch(A.TIME_SLIDER_RESET_DATE, TimeService.currentUtcTime());
      this.value = 0;
      this.doPartialSubscribe();
    },
    click() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setTime(), 500);
    },
    setTime() {
      if (TimeService.timeOffsetInMilliseconds() === 0 && this.value === 0) {
        return;
      }
      this.doPartialSubscribe();
    },
    doPartialSubscribe() {
      Logger.info(this.$i18n.t('logMessages.timeChanged', {newValue: this.convertToHoursAndSeconds(this.value)}));
      this.$store.dispatch(A.VIEW_EDITOR_ADJUST_TIME, this.value * 60000);
      if (this.$store.getters.partialSubscribeIsEnabled &&
        !this.$store.getters.isGivenTabOpened(HudTabName.CONFIGURATION)) {
        SubscriptionUtils.performPartialSubscribeForTimeSlider();
      }
    }
  }
}
</script>

<style src="./slider.css"></style>