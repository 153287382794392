import UomConfig from "../config/uomConfig";
import bigDecimal from "js-big-decimal";

export default class UomUtils {

  //Needs to be used for altitude objects { altitude_value , units_of_measure } and UoM can be m/ft
  static convertAltitudeObjectToCurrentUom({altitude_value, units_of_measure}) {
    return this.convertAltitudeToCurrentUom(altitude_value, units_of_measure);
  }

  //Needs to be used when the UoM can contain lowercase characters. ex. in geozone model UoM is m/ft.
  static convertAltitudeToCurrentUom(value, uom) {
    const altitudeUom = uom.toUpperCase() === 'M' ? UomConfig.UOM.METERS : UomConfig.UOM.FEET;
    return this.convertValueToCurrentUom(value, altitudeUom);
  }

  //Needs to be used when the UoM is in internal model METERS/FEET
  static convertValueToCurrentUom(value, sourceUomId) {
    const currentUom = UomConfig.getCurrentUom();
    return this.convert(value, sourceUomId, currentUom);
  }

  static convertAltitudeFromCurrentUomToTargetUom(altitudeValue, targetUomId) {
    const currentUom = UomConfig.getCurrentUom();
    return this.convert(altitudeValue, currentUom, targetUomId);
  }

  static convert(value, sourceUomId, targetUomId) {
    const valueInMeters = bigDecimal.multiply(UomConfig.getConfigForUom(sourceUomId).toMeters, value);
    const valueInTargetUom = bigDecimal.divide(valueInMeters, UomConfig.getConfigForUom(targetUomId).toMeters, 7);
    return parseFloat(valueInTargetUom);
  }
}