import A from '../../constants/actions';
import M from '../../constants/mutations';

const state = {
  isOpen: false,
  airspaceReservationRequestId: null,
};

const actions = {
  [A.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_OPEN]({commit}, airspaceReservationRequestId) {
    commit(M.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_OPEN, airspaceReservationRequestId);
  },

  [A.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_CLOSE]({commit}) {
    commit(M.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_CLOSE);
  }
};

const mutations = {
  [M.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_OPEN](state, airspaceReservationRequestId) {
    state.isOpen = true;
    state.airspaceReservationRequestId = airspaceReservationRequestId;
  },

  [M.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_CLOSE](state) {
    state.isOpen = false;
    state.airspaceReservationRequestId = null;
  }
};

const getters = {
  isAirspaceReservationRequestApproveDialogOpen: state => state.isOpen,
  airspaceReservationRequestId: state => state.airspaceReservationRequestId,
};

export default {
  state,
  actions,
  mutations,
  getters
};