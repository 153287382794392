import FeatureType from '../constants/featureType';
import AppConfig from '../config/appConfig';
import Store from '../store';
import {Fill, Icon, RegularShape, Stroke, Style} from 'ol/style';
import Point from 'ol/geom/Point';
import {getCenter} from 'ol/extent';
import SailFillColorConfig from '../config/SailFillColorConfig';
import NetworkFillColorConfig from "../config/NetworkFillColorConfig";
import CoordinateUtils from "../utils/CoordinateUtils";
import ModelUtils from "../utils/ModelUtils";
import UomUtils from "../utils/UomUtils";
import UomConfig from "../config/uomConfig";
import DroneStyleFunction from "./utm-features/DroneStyleFunction";
import SigmetAirmetStyleFunction from "./met-features/SigmetAirmetStyleFunction";
import CommonStyleFunction from "./CommonStyleFunction";
import MetFeatureStyleFunction from "./met-features/MetFeatureStyleFunction";
import DronePlanStyleFunction from "./utm-features/DronePlanStyleFunction";
import OpGeometryTypeUtils from "../utils/dronePlans/OpGeometryTypeUtils";
import {i18n} from '../internationalization/index';
import DroneAlertStyleFunction from "./utm-features/DroneAlertStyleFunction";
import GeozoneStyleFunction from "./utm-features/GeozoneStyleFunction";

function getFillColorBySail(sail) {
  return SailFillColorConfig[FeatureType.SAIL_DETAILS.toUpperCase() + '_' + sail];
}

function getFillColorByNetwork(hasSignal) {
  return NetworkFillColorConfig[FeatureType.NETWORK_COVERAGE.toUpperCase() + '_' + (hasSignal ? 'STRONG' : 'WEAK')]
}

function getImageStyleConfig(stylingConfig, pathToIcon, scale, rotation, color = CommonStyleFunction.toRgba(stylingConfig.colors.imageColor)) {
  return new Icon({
    color: color,
    src: pathToIcon,
    scale: stylingConfig.iconSize / scale,
    rotation: rotation
  });
}

function lineStringFlightPlanStyleFunction(stylingConfig) {
  return function (feature) {
    const text = feature.getProperties().departureAirport + ' ' + i18n.t('labels.map.to') + ' ' +
      feature.getProperties().destinationAirport;
    const offsets = {
      x: 10,
      y: 10
    };
    const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
    const style = CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, styleText);
    const strokeConfig = {
      color: stylingConfig.colors.borderColor,
      width: stylingConfig.iconSize
    };
    const geometryStyle = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 81);
    return [geometryStyle, style];
  };
}

function getLayerTextStyling(stylingConfig, layerTextStyleConfig) {
  return new Style({
    text: stylingConfig.showText ? layerTextStyleConfig : undefined,
    zIndex: 30
  });
}

function respAreaAndAirspaceStyleFunction(stylingConfig) {
  return function (feature) {
    const ident = feature.getProperties().designator;
    const offsets = {
      x: 15,
      y: 15
    };
    const textStyle = CommonStyleFunction.getTextStyleConfig(stylingConfig, ident, offsets);
    const strokeConfig = {
      color: stylingConfig.colors.borderColor,
      width: feature.getProperties().selected ? 4 : 2
    };
    return [
      ...CommonStyleFunction.getLayerGeometryStyle(strokeConfig, stylingConfig.colors.fillColor, feature.getProperties().geometry),
      feature.getProperties().selected ? getLayerTextStyling(stylingConfig, textStyle) : new Style()
    ];
  };
}

function buildAdsbStyleFunction(stylingConfig) {
  return function (feature) {
    const rotation = feature.getProperties().trackAngle;
    const image = getImageStyleConfig(stylingConfig, 'icons/aircraft.svg', 100, rotation);
    const ident = feature.getProperties().identifier;
    const offsets = {
      x: 70,
      y: 0
    };
    const convertedAltitude =
      UomUtils.convertValueToCurrentUom(feature.getProperties().altitude, UomConfig.UOM.FEET);
    const altitudeToBeDisplayed = ModelUtils.roundToNearestInteger(convertedAltitude);
    const text = ident + '\n' + altitudeToBeDisplayed + " " + UomConfig.getCurrentlyConfiguredUomDisplayValue();
    const textStyleConfig = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
    return [CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, textStyleConfig, image)];
  };
}

let StyleFunctions = {
  [FeatureType.AIRSPACE]: respAreaAndAirspaceStyleFunction,
  [FeatureType.AIRPORT_HELIPORT]: (stylingConfig) => {
    return function (feature) {
      const ident = feature.getProperties().designator;
      const image = getImageStyleConfig(stylingConfig, 'icons/airport.svg', 100, undefined);
      const offsets = {
        x: 0,
        y: -20
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, ident, offsets);
      return [CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text, image)];
    };
  },
  [FeatureType.ADSB]: buildAdsbStyleFunction,
  [FeatureType.AIRCRAFT]: buildAdsbStyleFunction,
  [FeatureType.CTR]: (stylingConfig) => {
    return function () {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      return [CommonStyleFunction.getStyleConfigWithStrokeAndText(null, strokeConfig, 30, fill)];
    };
  },
  [FeatureType.DRONES]: (stylingConfig) => {
    return function (feature, resolution) {
      return DroneStyleFunction.buildDroneStyleFunctions(stylingConfig, feature, resolution);
    };
  },
  [FeatureType.METAR]: (stylingConfig) => {
    return function (feature, resolution) {
      return MetFeatureStyleFunction.buildMetarStyleFunction(stylingConfig, feature, resolution);
    };
  },
  [FeatureType.SIGMET]: (stylingConfig) => {
    return function (feature, resolution) {
      return SigmetAirmetStyleFunction.buildSigmetAirmetStyleFunction(feature, stylingConfig, resolution);
    };
  },
  [FeatureType.AIRMET]: (stylingConfig) => {
    return function (feature, resolution) {
      return SigmetAirmetStyleFunction.buildSigmetAirmetStyleFunction(feature, stylingConfig, resolution);
    };
  },
  [FeatureType.GAMET]: (stylingConfig) => {
    return function (feature) {
      const pressure = '\n' + ((feature.getProperties().pressure) ? feature.getProperties().pressure + ' hPa' : '');
      const gametText = feature.getProperties().ident + ' ' + pressure;
      const offsets = {
        x: -10,
        y: -10
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, gametText, offsets);
      return [CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text)];
    };
  },
  [FeatureType.AD_WARNING]: (stylingConfig) => {
    return function (feature) {
      const awText = i18n.t('labels.map.adWarning') + ' ' + feature.getProperties().phenomenon;
      const offsets = {
        x: -10,
        y: -35
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, awText, offsets);
      const style = CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text);
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 4
      };
      const geometryStyle = CommonStyleFunction.getStyleConfigWithCircle(13, strokeConfig, 100);
      return [geometryStyle, style];
    };
  },
  [FeatureType.WS_WARNING]: (stylingConfig) => {
    return function (feature) {
      return MetFeatureStyleFunction.buildWsWarningStyleFunction(stylingConfig, feature);
    };
  },
  [FeatureType.EVENT]: (stylingConfig) => {
    return function (feature) {
      const awText = i18n.t('features.event') + (feature.getProperties().type || '') + ' ' +
        (feature.getProperties().series || '-') + (feature.getProperties().number || '-') + '/' +
        (feature.getProperties().year || '-');
      const offsets = {
        x: 50,
        y: 10
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, awText, offsets);
      const style = CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text);
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      const geometryStyle = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 120, fill);
      return [geometryStyle, style];
    };
  },
  [FeatureType.SFO]: lineStringFlightPlanStyleFunction,
  [FeatureType.VAA]: (stylingConfig) => {
    return function (feature) {
      const vaaText = feature.getProperties().text;
      const offsets = {
        x: -10,
        y: -20
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, vaaText, offsets);
      const style = CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text);
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: stylingConfig.iconSize
      };
      const geometryStyle = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 81);
      return [geometryStyle, style];
    };
  },
  [FeatureType.TCA]: (stylingConfig) => {
    return function (feature) {
      const tcaText = feature.getProperties().text;
      const offsets = {
        x: -20,
        y: -30
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, tcaText, offsets);
      const style = CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text);
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 3
      };
      const geometryStyle = CommonStyleFunction.getStyleConfigWithCircle(10, strokeConfig, 100);
      return [geometryStyle, style];
    };
  },
  [FeatureType.BRIEFING]: lineStringFlightPlanStyleFunction,
  [FeatureType.FLIGHT_OBJECT]: lineStringFlightPlanStyleFunction,
  [FeatureType.FLIGHT_TRACK]: (stylingConfig) => {
    return function (feature) {
      const ident = feature.getProperties().id;
      const coords = feature.getGeometry().getCoordinates().slice(-2);
      const planeCoordinate = coords[1];
      const previousCoordinate = coords[0];
      const planeRotation = CoordinateUtils.computeAngle(previousCoordinate, planeCoordinate);
      const image = getImageStyleConfig(stylingConfig, 'icons/plane.svg', 1 / 0.3, planeRotation);
      const offsets = {
        x: 30,
        y: 0
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, ident, offsets);
      const planeStyle = new Style({
        geometry: new Point(planeCoordinate),
        image: image,
        text: text
      });
      const routeStyle = new Style({
        stroke: new Stroke({
          color: CommonStyleFunction.toRgba(stylingConfig.colors.borderColor),
          width: stylingConfig.iconSize,
          lineDash: [5, 15]
        }),
        zIndex: 81
      });
      return [planeStyle, routeStyle];
    };
  },
  [FeatureType.OPERATION_PLAN]: DronePlanStyleFunction.buildOperationPlanStyleFunction,
  [FeatureType.OPERATION_PLAN_PROPOSED]: DronePlanStyleFunction.buildOperationPlanStyleFunction,
  [FeatureType.OPERATION_PLAN_ACCEPTED]: DronePlanStyleFunction.buildOperationPlanStyleFunction,
  [FeatureType.OPERATION_PLAN_ACTIVATED]: DronePlanStyleFunction.buildOperationPlanStyleFunction,
  [FeatureType.OPERATION_PLAN_OUTLOOK]: DronePlanStyleFunction.buildOutlookOperationPlanStyleFunction,
  [FeatureType.DESIGNATED_POINT]: (stylingConfig) => {
    return function (feature) {
      const ident = feature.getProperties().designator;
      const offsets = {
        x: 0,
        y: -20
      };
      const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, ident, offsets);
      const image = new RegularShape({
        fill: new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.imageColor)}),
        stroke: new Stroke({color: 'black', width: 1}),
        points: 3,
        radius: 10,
        rotation: 0,
        angle: 0,
        size: stylingConfig.iconSize / 100,
      });
      return [CommonStyleFunction.getStyleConfigWithImageAndText(stylingConfig, text, image)];
    };
  },
  [FeatureType.WINDBARB]: (stylingConfig) => {
    return function (feature) {
      const style = new Style({
        image: new Icon({
          color: CommonStyleFunction.toRgba(stylingConfig.colors.windbarbColor),
          src: 'svgBarbs/' + 5 * Math.round(feature.get('speed') / 5) + 'kn.svg',
          rotation: (Math.PI * (feature.get('direction') - 90)) / 180,
          scale: stylingConfig.iconSize / 10
        })
      });
      return [style];
    };
  },
  [FeatureType.TEMPPOINT]: (stylingConfig) => {
    return function (feature) {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 1
      };
      const radius = (stylingConfig.textSize - 2) * 2;
      const style = CommonStyleFunction.getStyleConfigWithCircle(radius, strokeConfig, undefined);
      const offsets = {
        x: -2,
        y: 0
      };
      const ident = '' + Math.round(feature.getProperties().temperature);
      const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, ident, offsets);
      const text = new Style({
        text: styleText,
        zIndex: 100
      });
      return [style, text];
    };
  },
  [FeatureType.LIGHTNING]: (stylingConfig) => {
    return function (feature) {
      const centroid = new Point(getCenter(feature.getGeometry().getExtent()));
      const image = getImageStyleConfig(stylingConfig, 'icons/lightning.svg', 100, undefined);
      const geometryStyle = new Style({
        image: image,
        geometry: centroid,
        zIndex: 100
      });
      return [geometryStyle];
    };
  },
  [FeatureType.TURBULENCE]: (stylingConfig) => {
    return function (feature) {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const text = feature.getProperties().intensity + ' ' + i18n.t('labels.map.turbFrom') + ' ' +
          feature.getProperties().base + ' ' + i18n.t('labels.map.to') + ' ' + feature.getProperties().top;
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 30, fill);
        return [style];
      }
    };
  },
  [FeatureType.DUST]: (stylingConfig) => {
    return function (feature) {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const text = feature.getProperties().intensity;
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 30, fill);
        return [style];
      }
    };
  },
  [FeatureType.GEOZONE_BASE_FEATURE]: GeozoneStyleFunction.buildGeozoneStyleFunction,
  [FeatureType.GEOZONE]: GeozoneStyleFunction.buildGeozoneStyleFunction,
  [FeatureType.GEOZONE_OUTLOOK]: GeozoneStyleFunction.buildGeozoneStyleFunction,
  [FeatureType.LARA_AIRSPACE]: GeozoneStyleFunction.buildGeozoneStyleFunction,
  [FeatureType.AIRSPACE_RESERVATION_REQUEST]: GeozoneStyleFunction.buildGeozoneStyleFunction,

  [FeatureType.CONVECTION]: (stylingConfig) => {
    return function (feature) {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const text = feature.getProperties().intensity + ' ' + i18n.t('labels.map.convTop') + ' ' +
          feature.getProperties().top;
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 30, fill);
        return [style];
      }
    };
  },
  [FeatureType.ICING]: (stylingConfig) => {
    return function (feature) {
      const centroid = new Point(getCenter(feature.getGeometry().getExtent()));
      const image = getImageStyleConfig(stylingConfig, 'icons/snowflake.svg', 250, undefined);
      const snowflakeStyle = new Style({
        image: image,
        geometry: centroid,
        zIndex: 100
      });
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const text = i18n.t('labels.map.iceFrom') + ' ' + feature.getProperties().base + ' ' +
          i18n.t('labels.map.to') + ' ' + feature.getProperties().top;
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style, snowflakeStyle];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 30, fill);
        return [style, snowflakeStyle];
      }
    };
  },
  [FeatureType.THUNDERSTORM]: (stylingConfig) => {
    return function (feature) {
      const centroid = new Point(getCenter(feature.getGeometry().getExtent()));
      const image = getImageStyleConfig(stylingConfig, 'icons/lightning.svg', 100, undefined);
      const lightningStyle = new Style({
        image: image,
        geometry: centroid,
        zIndex: 100
      });
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const text = feature.getProperties().intensity + ' ' + i18n.t('labels.map.tsTop') + ' ' +
          feature.getProperties().top;
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style, lightningStyle];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 30, fill);
        return [style, lightningStyle];
      }
    };
  },
  [FeatureType.APRON_ELEMENT]: (stylingConfig) => {
    return function () {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, i18n.t('labels.map.apronElm'), offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(null, strokeConfig, 30, fill);
        return [style];
      }
    };
  },
  [FeatureType.RUNWAY_ELEMENT]: (stylingConfig) => {
    return function () {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, i18n.t('labels.map.rwyElm'), offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(null, strokeConfig, 30, fill);
        return [style];
      }
    };
  },
  [FeatureType.TAXIWAY_ELEMENT]: (stylingConfig) => {
    return function () {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
      if (stylingConfig.showText) {
        const offsets = {
          x: 15,
          y: 15
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, i18n.t('labels.map.twyElm'), offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style];
      } else {
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(null, strokeConfig, 30, fill);
        return [style];
      }
    };
  },
  [FeatureType.ROUTE_SEGMENT]: (stylingConfig) => {
    return function (feature) {
      const level = feature.getProperties().level ?
        i18n.t('labels.map.lvl') + ': ' + feature.getProperties().level : '';
      const navigation = feature.getProperties().navigationType || '';
      const text = feature.getProperties().start + ' - ' + feature.getProperties().end + ' ' + navigation + ' ' + level;
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      if (stylingConfig.showText) {
        const offsets = {
          x: 0,
          y: 0
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30);
        return [style];
      } else {
        const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(null, strokeConfig, 1, fill);
        return [style];
      }
    };
  },
  [FeatureType.SERVICE_INSTANCE]: (stylingConfig) => {
    return function (feature) {
      const text = feature.getProperties().name;
      let fillColor = stylingConfig.colors.fillColor;
      let borderColor = stylingConfig.colors.borderColor;
      if (feature.getProperties().status !== 'provisional' && feature.getProperties().status !== 'released') {
        fillColor = {r: 240, g: 50, b: 70, a: 0.15};
        borderColor = {r: 250, g: 50, b: 20, a: 0.75};
      }
      if (stylingConfig.showText) {
        const offsets = {
          x: 0,
          y: 0
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, text, offsets);
        const strokeConfig = {
          color: borderColor,
          width: 2
        };
        const fill = new Fill({color: CommonStyleFunction.toRgba(fillColor)});
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(styleText, strokeConfig, 30, fill);
        return [style];
      } else {
        const strokeConfig = {
          color: stylingConfig.colors.borderColor,
          width: 2
        };
        const fill = new Fill({color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)});
        const style = CommonStyleFunction.getStyleConfigWithStrokeAndText(null, strokeConfig, 1, fill);
        return [style];
      }
    };
  },
  [FeatureType.VAISALA]: (stylingConfig) => {
    return function (feature) {
      const style = new Style({
        image: new Icon({
          color: CommonStyleFunction.toRgba(stylingConfig.colors.imageColor),
          src: 'svgBarbs/' + 5 * Math.round(feature.get('speed') / 5) + 'kn.svg',
          rotation: (Math.PI * (feature.get('direction') - 90)) / 180,
          scale: stylingConfig.iconSize / 10
        })
      });
      return [style];
    }
  },
  [FeatureType.GROUND_HAZARD]: (stylingConfig) => {
    return function (feature) {
      let geometryStyle;
      let textStyle;
      const fill = new Fill({
        color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)
      });
      if (feature.getGeometry().getType() === 'Point') {
        const strokeConfig = {
          color: stylingConfig.colors.borderColor,
          width: 4
        };
        geometryStyle = CommonStyleFunction.getStyleConfigWithCircle(5, strokeConfig, 100, fill);
      } else {
        const strokeConfig = {
          color: stylingConfig.colors.borderColor,
          width: 2
        };
        geometryStyle = CommonStyleFunction.getStyleConfigWithCircle(undefined, strokeConfig, 100, fill);
      }
      if (stylingConfig.showText) {
        const offsets = {
          x: 0,
          y: 0
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, feature.getProperties().text, offsets);
        textStyle = new Style({
          text: styleText,
          zIndex: 30
        });
      } else {
        textStyle = new Style({
          text: null,
          zIndex: 1
        });
      }
      return [geometryStyle, textStyle];
    };
  },
  [FeatureType.RESPONSIBILITY_AREA]: respAreaAndAirspaceStyleFunction,
  [FeatureType.TERRAIN]: (stylingConfig) => {
    return function (feature) {
      let textStyle;
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({
        color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)
      });
      const geometryStyle = CommonStyleFunction.getStyleConfigWithStrokeAndText(undefined, strokeConfig, 100, fill);
      if (stylingConfig.showText) {
        const offsets = {
          x: 0,
          y: 0
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, feature.getProperties().text, offsets);
        textStyle = new Style({
          text: styleText,
          zIndex: 30
        });
      } else {
        textStyle = new Style({
          text: null,
          zIndex: 1
        });
      }
      return [geometryStyle, textStyle];
    };
  },
  [FeatureType.RUNWAY_DIRECTION]: (stylingConfig) => {
    return function (feature) {
      const designator = feature.getProperties().designator.endsWith('R') ?
        feature.getProperties().designator.substring(0, feature.getProperties().designator.length - 1) + '   R' :
        feature.getProperties().designator + '   ';
      const icon = 'icons/arrow.svg';
      let textStyle;
      const iconStyle = new Style({
        image: new Icon({
          color: CommonStyleFunction.toRgba(stylingConfig.colors.imageColor),
          src: icon,
          rotation: (Math.PI * (feature.get('angle') - 90)) / 180,
          scale: stylingConfig.iconSize / 100
        }),
        geometry: feature.getGeometry(),
        zIndex: 1000
      });
      if (stylingConfig.showText) {
        const offsets = {
          x: 30 * Math.sin((Math.PI * (feature.get('angle') - 90)) / 180),
          y: 30 * Math.cos((Math.PI * (feature.get('angle') - 90)) / 180)
        };
        const styleText = CommonStyleFunction.getTextStyleConfig(stylingConfig, designator, offsets);
        textStyle = new Style({
          text: styleText
        });
      } else {
        textStyle = new Style({
          text: null,
          zIndex: 1
        });
      }
      return [textStyle, iconStyle];
    };
  },
  [FeatureType.DRONE_ALERT]: DroneAlertStyleFunction.buildDroneAlertStyleFunction,
  [FeatureType.SAIL_DETAILS]: () => {
    return function (feature, resolution) {
      const style = new Style({
        stroke: new Stroke({
          color: CommonStyleFunction.toRgba(getFillColorBySail(feature.getProperties().sail)),
          width: 3
        }),
        geometry: feature.getGeometry(),
        fill: new Fill({
          color: CommonStyleFunction.toRgba(getFillColorBySail(feature.getProperties().sail))
        }),
        zIndex: 30
      });
      const opGufi = feature.getProperties().associatedFeatureId;
      const featureGeometry = OpGeometryTypeUtils.getOperationPolygonsById(opGufi);
      return CommonStyleFunction.shouldDisplayFeatureGeometry(featureGeometry, resolution) ? [style] : [];
    };
  },
  [FeatureType.NETWORK_COVERAGE]: (stylingConfig) => {
    return function (feature, resolution) {
      const strokeConfig = {
        color: stylingConfig.colors.borderColor,
        width: 2
      };
      const fill = new Fill({
        color: CommonStyleFunction.toRgba(getFillColorByNetwork(feature.getProperties().hasSignal))
      });
      const opGufi = feature.getProperties().associatedFeatureId;
      const featureGeometry = OpGeometryTypeUtils.getOperationPolygonsById(opGufi);
      return CommonStyleFunction.shouldDisplayFeatureGeometry(featureGeometry, resolution) ?
        [CommonStyleFunction.getStyleConfigWithStrokeAndText(null, strokeConfig, 30, fill)] : [];
    }
  },
  [FeatureType.METEOPROBE]: (stylingConfig) => {
    return function (feature, resolution) {
      const temperature = ModelUtils.roundToTwoDecimals(feature.getProperties().temperature.value) + ' ' +
        feature.getProperties().temperature.uom;
      const windSpeed = ModelUtils.roundToTwoDecimals(feature.getProperties().wind.speed.value) + ' ' +
        feature.getProperties().wind.speed.uom;
      const windDirection = ModelUtils.roundToTwoDecimals(feature.getProperties().wind.direction.value) + ' ' +
        feature.getProperties().wind.direction.uom;
      const pressure = ModelUtils.roundToTwoDecimals(feature.getProperties().pressure.value) + ' ' +
        feature.getProperties().pressure.uom;
      const altitude = ModelUtils.roundToTwoDecimals(feature.getProperties().altitude.value) + ' ' +
        feature.getProperties().altitude.uom;
      const dateTime = feature.getProperties().observationTime;
      const labelContent =
        temperature + '\n' + windSpeed + ' ' + windDirection + '\n' + pressure + '\n' + altitude + '\n' + dateTime;
      const icon = 'icons/meteoprobe.svg';
      let scaleNumber = resolution < 30 ? 60 : Math.max(60, resolution);
      const image = getImageStyleConfig(stylingConfig, icon, scaleNumber, 0);
      if (stylingConfig.showText && resolution < AppConfig.maxResolutionForText - 1485) {
        const offsets = {
          x: 0,
          y: 57
        };
        const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, labelContent, offsets);
        const style = new Style({
          image: image,
          text: text
        });
        return [style];
      } else {
        const style = new Style({
          image: image
        });
        return [style];
      }
    }
  },
};

function buildGeoserverLayerStyle(stylingConfig) {
  return function (feature) {
    const geoserverLayer = Store.getters.getGeoserverLayers.find(layer => layer.id === stylingConfig.layer);
    const layerText = geoserverLayer?.label ? feature.getProperties()[geoserverLayer.label] : null;
    const offsets = {
      x: 0,
      y: 0
    };
    const text = CommonStyleFunction.getTextStyleConfig(stylingConfig, layerText, offsets);
    const strokeConfig = {
      color: stylingConfig.colors.borderColor,
      width: 2
    };
    const fill = new Fill({
      color: CommonStyleFunction.toRgba(stylingConfig.colors.fillColor)
    });
    return CommonStyleFunction.getStyleConfigWithStrokeAndText(layerText ? text : null, strokeConfig, 30, fill);
  }
}

export default {
  createStyleFunction: function (stylingConfig, isEmbeddedMapFeature) {
    let styleFunction = StyleFunctions[stylingConfig?.layer];

    if (!styleFunction) {
      styleFunction = buildGeoserverLayerStyle;
    }
    return styleFunction(stylingConfig, isEmbeddedMapFeature);
  }
}