const GEOMETRY_CONFIG_TYPE = {
  FEATURE_COLLECTION: 'FeatureCollection',
  FEATURE: 'Feature',
  POLYGON: 'Polygon',
  LINESTRING: 'LineString'
}

const GEOMETRY_CONFIGS = {
  [GEOMETRY_CONFIG_TYPE.FEATURE_COLLECTION]: {
    isValid: (config) => {
      return config && config.features && config.features.length === 1 && isValidGeoJsonConfig(config.features[0]);
    },
    getGeometry: (config) => {
      return config.features[0].geometry;
    }
  },
  [GEOMETRY_CONFIG_TYPE.FEATURE]: {
    isValid: (config) => {
      return config && config.geometry && isValidGeoJsonConfig(config.geometry);
    },
    getGeometry: (config) => {
      return config.geometry;
    }
  },
  [GEOMETRY_CONFIG_TYPE.POLYGON]: {
    isValid: (config) => {
      return config && config.coordinates && isValidPolygon(config.coordinates);
    },
    getGeometry: (config) => {
      return config;
    }
  },
  [GEOMETRY_CONFIG_TYPE.LINESTRING]: {
    isValid: (config) => {
      return config && config.coordinates && isValidLineString(config.coordinates);
    },
    getGeometry: (config) => {
      return config;
    }
  },
}

function getGeometryConfigTypeFromConfig(config) {
  if (!config) {
    return null;
  }
  switch (config.type) {
    case GEOMETRY_CONFIG_TYPE.FEATURE_COLLECTION:
      return GEOMETRY_CONFIG_TYPE.FEATURE_COLLECTION;
    case GEOMETRY_CONFIG_TYPE.FEATURE:
      return GEOMETRY_CONFIG_TYPE.FEATURE;
    case GEOMETRY_CONFIG_TYPE.POLYGON:
      return GEOMETRY_CONFIG_TYPE.POLYGON;
    case GEOMETRY_CONFIG_TYPE.LINESTRING:
      return GEOMETRY_CONFIG_TYPE.LINESTRING;
    default:
      return null;
  }
}

function isValidGeoJsonConfig(config) {
  const geometryConfigType = getGeometryConfigTypeFromConfig(config);
  return geometryConfigType && GEOMETRY_CONFIGS[geometryConfigType].isValid(config);
}

function getGeometryFromGeoJsonConfig(config) {
  const geometryConfigType = getGeometryConfigTypeFromConfig(config);
  return GEOMETRY_CONFIGS[geometryConfigType].getGeometry(config);
}

function isValidPolygon(coordinates) {
  return coordinates.length === 1 && coordinates[0].length >= 4 &&
    coordinates[0].every(coordinate => coordinate.length === 2) &&
    coordinates[0][0][0] === coordinates[0][coordinates[0].length - 1][0] &&
    coordinates[0][0][1] === coordinates[0][coordinates[0].length - 1][1];
}

function isValidLineString(coordinates) {
  return coordinates.length >= 2 && coordinates.every(coordinate => coordinate.length === 2);
}

export default {
  GEOMETRY_CONFIG_TYPE,
  getGeometryConfigTypeFromConfig,
  isValidGeoJsonConfig,
  getGeometryFromGeoJsonConfig
}