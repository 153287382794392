import OperationPlanState from '../constants/operationPlanState';

export const ResultState = {
  NOT_YET_REQUESTED: 'NOT_YET_REQUESTED',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  GRANTED: 'GRANTED',
  GRANTED_WITH_RESTRICTIONS: 'GRANTED_WITH_RESTRICTIONS',
  DENIED: 'DENIED',
  TIMEOUT: 'TIMEOUT',
  REVOKED: 'REVOKED'
};

export const configForAuthorization = {
  listTitle: 'labels.operationPlan.stakeholderApprovalStatus',
  operationPlanStates: [OperationPlanState.PROPOSED.state, OperationPlanState.AUTHORIZED.state, OperationPlanState.CLOSED.state],
  getResults: operationPlan => operationPlan.localApprovalResults,
  resultStatesDisplayValues: [
    {
      resultStates: [ResultState.NOT_YET_REQUESTED, ResultState.ERROR, ResultState.PENDING],
      displayValue: 'labels.operationPlan.pendingApproval'
    },
    {
      resultStates: [ResultState.GRANTED, ResultState.GRANTED_WITH_RESTRICTIONS],
      displayValue: 'labels.operationPlan.approved'
    },
    {
      resultStates: [ResultState.DENIED],
      displayValue: 'labels.operationPlan.rejected'
    },
    {
      resultStates: [ResultState.REVOKED],
      displayValue: 'labels.operationPlan.revoked'
    },
    {
      resultStates: [ResultState.TIMEOUT],
      displayValue: 'labels.operationPlan.timeout'
    },
    {
      resultStates: [ResultState.ERROR],
      displayValue: 'labels.common.error'
    }
  ]
};
export const configForActivation = {
  listTitle: 'labels.operationPlan.stakeholderActivationStatus',
  operationPlanStates: [OperationPlanState.TAKEOFFREQUESTED.state, OperationPlanState.ACTIVATED.state, OperationPlanState.CLOSED.state],
  getResults: operationPlan => operationPlan.localTakeoffClearanceResults,
  resultStatesDisplayValues: [
    {
      resultStates: [ResultState.NOT_YET_REQUESTED, ResultState.ERROR, ResultState.PENDING],
      displayValue: 'labels.operationPlan.pendingActivation'
    },
    {
      resultStates: [ResultState.GRANTED, ResultState.GRANTED_WITH_RESTRICTIONS],
      displayValue: 'labels.operationPlan.clearForTakeoff'
    },
    {
      resultStates: [ResultState.DENIED],
      displayValue: 'labels.operationPlan.rejected'
    },
    {
      resultStates: [ResultState.REVOKED],
      displayValue: 'labels.operationPlan.revoked'
    },
    {
      resultStates: [ResultState.TIMEOUT],
      displayValue: 'labels.operationPlan.timeout'
    },
    {
      resultStates: [ResultState.ERROR],
      displayValue: 'labels.common.error'
    }
  ]
}

export const configList = [
  configForAuthorization,
  configForActivation
];
