import FeatureType from '../../constants/featureType';
import ThemeElements from '../../constants/theme-elements';
import FeatureUtils from '../../utils/FeatureUtils';
import MessageUtils from '../../utils/MessageUtils';
import ModelUtils from '../../utils/ModelUtils';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import {v4 as uuidv4} from "uuid";

export default {

  id: FeatureType.GEOZONE_BASE_FEATURE,
  name: 'features.uas.name',
  icon: 'sigmet',
  abbreviation: 'features.uas.abbreviation',
  displayOnMap: true,
  displayInMessages: true,
  staticDataType: [FeatureType.AIRSPACE, FeatureType.RESPONSIBILITY_AREA],
  isViewPortBased: false,
  isFlightLevelBased: true,
  isNotificationsDisabled: true,
  maxNrOfFeaturesPerLocation: 200,
  getReplaceableMessages: FeatureUtils.Filters.filterByFeatureTypeAndId,
  featureIdentifier: 'id',
  maxResolution: 10000,
  declutter: false,
  zIndex: 1,
  flightLevel: null,
  theme: {
    elements: [
      ThemeElements.IMAGE_COLOR
    ]
  },

  geoJsonMapper: (feature) => {
    const volume = feature.volumes[0];
    return new Feature({
      geometry: new GeoJSON().readGeometry(volume.horizontalProjection),
      id: feature.id,
      featureType: feature.featureType,
      altitudeType: volume.uomDimensions,
      minAltitude: volume.lowerLimit,
      maxAltitude: volume.upperLimit,
      reason: feature.reason,
      selected: false,
      name: feature.name
    });
  },

  geoJsonVolumeMapper: (feature, volume, index) => {
    const geometriesByErrorPrecision = feature.geometriesByErrorPrecision.map(volume =>
      new GeoJSON().readGeometry(volume[index]).transform('EPSG:4326', 'EPSG:3857'));
    return new Feature({
      id: uuidv4(),
      featureType: feature.featureType,
      type: feature.type,
      altitudeType: volume.uomDimensions,
      minAltitude: volume.lowerLimit,
      maxAltitude: volume.upperLimit,
      geometry: new GeoJSON().readGeometry(volume.horizontalProjection),
      geometries: geometriesByErrorPrecision,
      associatedFeatureId: feature.id,
      validity: feature.validity,
      selected: false,
      name: feature.name,
      isActive: feature.isActive
    });
  },

  color: (colors) => {
    return colors.imageColor;
  },

  defaultStyle: () => {
    return {
      showText: true,
      textSize: 14,
      iconSize: 10,
      colors: {
        borderColor: {r: 248, g: 231, b: 28, a: 1},
        fillColor: {r: 248, g: 231, b: 28, a: 0.1},
        textColor: {r: 255, g: 255, b: 255, a: 1},
        textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
        imageColor: {r: 248, g: 231, b: 28, a: 1},
      }
    }
  },

  getNotificationMessage() {
    return 'ED-269';
  },

  getMinifiedObject(feature) {
    return {
      id: feature.id,
      featureType: feature.featureType,
      wrapperId: feature.wrapperId,
      type: feature.type,
      variant: feature.variant,
      minAltitude: {
        altitude_value: ModelUtils.roundToTwoDecimals(MessageUtils.getMinAltitudeForUasZone(feature.volumes)),
        units_of_measure: feature.volumes[0].uomDimensions
      },
      maxAltitude: {
        altitude_value: ModelUtils.roundToTwoDecimals(MessageUtils.getMaxAltitudeForUasZone(feature.volumes)),
        units_of_measure: feature.volumes[0].uomDimensions
      },
      validTime: {
        from: new Date(feature.startDateTime).getTime(),
        to: new Date(feature.endDateTime).getTime(),
      },
      reason: feature.reason,
      reasons: feature.reasons,
      otherReasonInfo: feature.otherReasonInfo,
      applicability: feature.applicability,
      isPermanent: feature.isPermanent,
      endDateTime: feature.endDateTime,
      isApplicabilityEditable: feature.isApplicabilityEditable,
      name: feature.name,
      country: feature.country,
      message: feature.message,
      authorityDetails: feature.authorityDetails,
      geometriesByErrorPrecision: feature.geometriesByErrorPrecision,
      isActive: feature.isActive,
      providerId: feature.providerId,
      volumes: feature.volumes,
      whitelistedOperationPlans: feature.whitelistedOperationPlans,
      whitelistedDrones: feature.whitelistedDrones,
      whitelistedOperators: feature.whitelistedOperators,
      isCreatedBySmartsis: feature.isCreatedBySmartsis,
      identifier: feature.identifier,
      isLaraAirspaceReservation: feature.isLaraAirspaceReservation
    }
  },

  sortFeaturesList(a, b) {
    return ModelUtils.sort(a.startDateTime, b.startDateTime);
  },

  filterMessages: (messages) => {
    return messages.filter((msg) => {
      return msg.featureType === FeatureType.GEOZONE_BASE_FEATURE;
    });
  },

}