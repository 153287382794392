import A from '../../constants/actions';
import M from '../../constants/mutations';
import Moment from "moment";

const state = {
  dateIsSelected: null,
  selectedDate: Moment.utc(),
  dateToShowOnDatePicker: Moment.utc(),
  dateSelectionTimestamp: Moment.utc(),
};

const actions = {
  [A.TIME_SLIDER_SET_DATE]({commit}, datetime) {
    commit(M.TIME_SLIDER_SET_DATE, datetime);
  },

  [A.TIME_SLIDER_RESET_DATE]({commit}, datetime) {
    commit(M.TIME_SLIDER_RESET_DATE, datetime);
  },

  [A.INFORMATION_PANEL_CHANGE_DATE]({commit, dispatch}, datetime) {
    commit(M.INFORMATION_PANEL_CHANGE_DATE, datetime);
    dispatch(A.VIEW_EDITOR_ADJUST_TIME, 0);
  },
};

const getters = {

  timeSliderHasSelectedDate: state => {
    return state.dateIsSelected;
  },

  timeSliderSelectedDate: state => {
    return state.selectedDate;
  },
  timeSliderDate: state => {
    return state.dateToShowOnDatePicker;
  },
  dateSelectionTimestamp: state => {
    return state.dateSelectionTimestamp;
  }
};

const mutations = {
  [M.TIME_SLIDER_SET_DATE](state, datetime) {
    state.dateToShowOnDatePicker = datetime;
    state.dateSelectionTimestamp = Moment.utc();
  },

  [M.TIME_SLIDER_RESET_DATE](state, datetime) {
    state.dateIsSelected = false;
    state.selectedDate = datetime;
    state.dateToShowOnDatePicker = datetime;
    state.dateSelectionTimestamp = Moment.utc();
  },

  [M.INFORMATION_PANEL_CHANGE_DATE](state, datetime) {
    state.dateIsSelected = state.dateIsSelected !== null;
    state.selectedDate = datetime;
    state.dateToShowOnDatePicker = datetime;
    state.dateSelectionTimestamp = Moment.utc();
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
}