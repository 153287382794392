import A from '../../constants/actions';
import M from '../../constants/mutations';
import Features from '../../config/features';

const state = {

  progressIndicator: false,

  views: [{
    id: 1,
    active: true,
    staticLayers: [{
      id: 1,
      name: 'fir',
      enabled: true,
    }, {
      id: 2,
      name: 'airportHeliport',
      enabled: true,
    }],
    dynamicLayers: Features.getMapFeatures().map((layer) => {
      return layer.id;
    }),
    basemap: '0',
  }],

  layersConfig: {
    isOpen: false,
    activeTab: '',
  }
};

const actions = {

  [A.LAYERS_CONFIG_PANEL_TOGGLE]({commit}) {
    commit(M.LAYERS_CONFIG_PANEL_TOGGLE);
  },

  [A.LAYERS_CONFIG_PANEL_SELECT_TAB]({commit}, tabId) {
    commit(M.LAYERS_CONFIG_PANEL_SELECT_TAB, tabId);
  },

  [A.LAYERS_CONFIG_PANEL_TOGGLE_LAYER]({commit}, layer) {
    commit(M.LAYERS_CONFIG_PANEL_TOGGLE_LAYER, layer);
  },

  [A.PROGRESS_INDICATOR_TOGGLE]({commit}) {
    commit(M.PROGRESS_INDICATOR_TOGGLE);
  },

  [A.PROGRESS_INDICATOR_START]({commit}) {
    commit(M.PROGRESS_INDICATOR_START);
  },

  [A.PROGRESS_INDICATOR_END]({commit}) {
    commit(M.PROGRESS_INDICATOR_END);
  },
};

const mutations = {
  [M.UPDATE_BASE_MAP](state, basemap) {
    state.views.filter(v => v.active)[0].basemap = basemap.id;
  },

  [M.LAYERS_CONFIG_PANEL_ADD_DYNAMIC_LAYERS](state, layers) {
    const activeView = state.views.find(v => v.active);
    const layerIds = layers.map(layer => layer.id);
    activeView.dynamicLayers.push(...layerIds);
  },

  [M.LAYERS_CONFIG_PANEL_SELECT_TAB](state, tab) {
    state.layersConfig.activeTab = tab;
  },

  [M.LAYERS_CONFIG_PANEL_TOGGLE](state) {
    state.layersConfig.isOpen = !state.layersConfig.isOpen;
  },

  [M.LAYERS_CONFIG_PANEL_TOGGLE_LAYER](state, layer) {
    const activeView = state.views.find(v => v.active);
    let index = -1;
    if ((index = activeView.dynamicLayers.indexOf(layer)) > -1) {
      activeView.dynamicLayers.splice(index, 1);
    } else {
      activeView.dynamicLayers.push(layer);
    }
  },

  [M.PROGRESS_INDICATOR_TOGGLE](state) {
    state.progressIndicator = !state.progressIndicator;
  },

  [M.PROGRESS_INDICATOR_START](state) {
    state.progressIndicator = true;
  },

  [M.PROGRESS_INDICATOR_END](state) {
    state.progressIndicator = false;
  }
};

const getters = {
  activeView: state => {
    return state.views.find(v => v.active);
  },
  activeViewBasemap: state => {
    return state.views.find(v => v.active).basemap;
  },
  activeLayerConfigTab: state => {
    return state.layersConfig.activeTab;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};

