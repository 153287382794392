import A from '../../constants/actions';
import M from '../../constants/mutations';
import FeatureType from "../../constants/featureType";
import GeoJSON from "ol/format/GeoJSON";

const defaultAorConfiguration = {
  designator: '',
  enabled: false,
  automaticApprovalEnabled: false,
  automaticTakeoffApprovalEnabled: false,
  automaticRejection: false,
  maxSimultaneousOperationsEnabled: false,
  maxSimultaneousOperations: 0,
  maxNoiseLevelEnabled: false,
  maxNoiseLevel: 0,
  geometry: null,
  extendedGeometry: null,
};

const state = {
  currentConfiguration: JSON.parse(JSON.stringify(defaultAorConfiguration)),
  originalConfiguration: JSON.parse(JSON.stringify(defaultAorConfiguration)),
};

const actions = {

  [A.UTM_CONFIG_SELECT_RESPONSIBILITY_AREA]({commit}, responsibilityArea) {
    commit(M.UTM_CONFIG_SELECT_RESPONSIBILITY_AREA, responsibilityArea);
  },

  [A.UTM_CONFIG_RESET]({commit}) {
    commit(M.UTM_CONFIG_RESET);
  },

  [A.UTM_CONFIG_SAVE]({commit}) {
    commit(M.UTM_CONFIG_SAVE);
  },

  [A.UTM_CONFIG_CANCEL_EDIT]({state, commit}) {
    commit(M.MAP_UPDATE_LAYER_FEATURE_GEOMETRY, {
      layerId: FeatureType.RESPONSIBILITY_AREA,
      featureId: state.originalConfiguration.id,
      geometry: new GeoJSON().readGeometry(state.originalConfiguration.geometry).transform('EPSG:4326', 'EPSG:3857')
    });
    commit(M.UTM_CONFIG_CANCEL_EDIT);
  },

  [A.UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY]({commit}, config) {
    commit(M.UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY, config);
    if (config.name === 'maxSimultaneousOperations' && config.value === 0) {
      commit(M.UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY, {
        name: 'maxSimultaneousOperationsEnabled',
        value: false
      });
    }
  },

  [A.UTM_CONFIG_ADD_AOR]({commit}, airspace) {
    commit(M.UTM_CONFIG_ADD_AOR, airspace);
  }
};

const mutations = {

  [M.UTM_CONFIG_SELECT_RESPONSIBILITY_AREA](state, responsibilityArea) {
    state.currentConfiguration = JSON.parse(JSON.stringify(responsibilityArea));
    state.originalConfiguration = JSON.parse(JSON.stringify(state.currentConfiguration));
  },

  [M.UTM_CONFIG_RESET](state) {
    state.currentConfiguration = defaultAorConfiguration;
    state.originalConfiguration = defaultAorConfiguration;
  },

  [M.UTM_CONFIG_SAVE](state) {
    state.originalConfiguration = JSON.parse(JSON.stringify(state.currentConfiguration));
  },

  [M.UTM_CONFIG_CANCEL_EDIT](state) {
    state.currentConfiguration = JSON.parse(JSON.stringify(state.originalConfiguration));
  },

  [M.UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY](state, configuration) {
    state.currentConfiguration[configuration.name] = configuration.value === '' ? null : configuration.value;
  },

};

const getters = {
  getResponsibilityAreaProperty: state => propName => state.currentConfiguration[propName],
  getCurrentResponsibilityArea: state => state.currentConfiguration,
  getCurrentResponsibilityAreaDesignator: state => state.currentConfiguration.designator,
};

export default {
  state,
  actions,
  mutations,
  getters
};