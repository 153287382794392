<template>
  <div :class="this.getThemeMode" id="app" v-if="isAuthenticated">
    <div class="workspace-content">
      <div id="panelContainer" class="panel-container">
        <div id="panel" class="panel-content-body">
          <div id="directory-panel" class="left-side" v-if="displayDirectoryPanel">
            <div class="right-panel-container">
              <div class="right-panel">
                <div class="right-panel-content smartSISPanel">
                  <DirectoryNavigation></DirectoryNavigation>
                </div>
              </div>
            </div>
          </div>
          <div id="map-container" class="upper-panel-container">
            <AltitudeSlider :forcedRefresh="isOpen"></AltitudeSlider>
            <div id="training-mode" class="trainingMode" v-if="enableTrainingMode">
              <h1><strong>{{ $t('labels.map.trainingMode') }}</strong></h1>
            </div>
            <Map></Map>
            <UtmPanel v-if="displayUTMPanel"></UtmPanel>
            <TimeSlider :forcedRefresh="forcedRefresh" v-if="showTimeSlider"></TimeSlider>
            <div id="history-mode-label" class="coverLayer w-100 h-100" v-if="dateIsChanged">
              <h1 class="coverLayerTitle">{{ getLayerTitle() }}</h1>
            </div>
          </div>
          <div id="information-panel-container" class="right-side">
            <div class="right-panel-container">
              <div class="right-panel">
                <div class="right-panel-content smartSISPanel">
                  <LeftPanel></LeftPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="bottom-panel-container" class="bottom-panel-container smartSISPanel">
          <ConfigurationPanel></ConfigurationPanel>
          <HUD></HUD>
        </div>
      </div>
      <DocumentsPanel></DocumentsPanel>
      <RolesPanel></RolesPanel>
      <ColorPicker v-if="colorPickerOpen"></ColorPicker>
      <FiltersDialog v-if="displayFirFilters"></FiltersDialog>
      <Popup></Popup>
      <ProgressIndicator v-if="displayProgressIndicator"></ProgressIndicator>

      <WebSocket></WebSocket>
      <DroneAlertDetails v-if="displayDroneAlertDialog"></DroneAlertDetails>
      <AlertReplyDetails v-if="displayAlertReplyDialog"/>
      <ServicesStates v-if="servicesStateIsOpen"></ServicesStates>
      <Dialog v-if="displayDialog"></Dialog>
      <OpRejectionDialog/>
      <OpRevokeDialog/>
      <WhiteListPanel/>
      <GeoserverLayerStoringDialog/>
      <FeatureSelectionPopup/>
      <IcaoLocationsConfigDialog/>
      <RestrictAorDialog/>
      <AirspaceReservationRequestApproveDialog/>
      <AltitudeFilteredFeaturesDialog/>
    </div>
    <BGWorker></BGWorker>
  </div>
</template>

<script>
import A from './constants/actions';
import AssetUtils from './utils/AssetUtils';
import BGWorker from './components/bg-worker/BGWorker.vue';
import HUD from './components/hud/HUD.vue';
import ColorPicker from './components/color-picker/ColorPicker.vue';
import ConfigurationPanel from './components/config-panel/ConfigurationPanel.vue';
import Dialog from './components/dialog/Dialog.vue';
import DirectoryNavigation from './components/left-panel/DirectoryNavigation';
import DroneAlertDetails from './components/drone-alert-details/DroneAlertDetails';
import AlertReplyDetails from './components/alert-reply-details/AlertReplyDetails';
import FiltersDialog from './components/config-panel/view/editor/FiltersDialog.vue';
import DocumentsPanel from './components/documents-panel/DocumentsPanel.vue';
import Map from './components/Map.vue';
import Popup from './components/popup/Popup.vue';
import ProgressIndicator from './components/progress-indicator/ProgressIndicator.vue';
import WebSocket from './components/websocket/WebSocket.vue';
import PermissionUtils from './utils/PermissionUtils';
import RolesPanel from './components/roles-panel/RolesPanel.vue';
import LeftPanel from './components/left-panel/LeftPanel.vue';
import AltitudeSlider from './components/slider/AltitudeSlider.vue';
import TimeSlider from './components/slider/TimeSlider.vue';
import UtmPanel from './components/utm-list-view-panel/UtmListViewPanel';
import ServicesStates from './components/hud/ServicesStates.vue';
import OpRejectionDialog from '@/components/dialog/OpRejectionDialog';
import WhiteListPanel from "./components/geozones/whiteList/WhiteListPanel";
import IcaoLocationsConfigDialog from "./components/dialog/IcaoLocationsConfigDialog";
import RestrictAorDialog from "./components/restrict-aor/RestrictAorDialog";
import AltitudeFilteredFeaturesDialog from './components/dialog/AltitudeFilteredFeaturesDialog';
import {setInterval} from 'worker-timers';

import Roles from './constants/roles';
import DocumentsService from './services/smartSisDocumentService';

import AppConfig from './config/appConfig';
import KeyCloak from 'keycloak-js';

import Logger from './utils/LoggerUtils';
import TimeService from './services/timeService';
import WebsocketSubscriptionService from "./services/websocketSubscriptionService";
import NetworkUtils from "./utils/NetworkUtils";
import GeoserverLayerStoringDialog from "./components/geoserver-layers-config/GeoserverLayerStoringDialog";
import OpRevokeDialog from "./components/dialog/OpRevokeDialog";
import FeatureSelectionPopup from "./components/map/FeatureSelectionPopup";
import HudTabName from './constants/hudTabName';
import AirspaceReservationRequestApproveDialog from "./components/dialog/AirspaceReservationRequestApproveDialog.vue";

export default {
  name: 'app',
  components: {
    AirspaceReservationRequestApproveDialog,
    OpRevokeDialog,
    GeoserverLayerStoringDialog,
    HUD,
    ColorPicker,
    Dialog,
    DocumentsPanel,
    DroneAlertDetails,
    AlertReplyDetails,
    DirectoryNavigation,
    Map,
    Popup,
    ProgressIndicator,
    BGWorker,
    ConfigurationPanel,
    RolesPanel,
    WebSocket,
    LeftPanel,
    FiltersDialog,
    AltitudeSlider,
    TimeSlider,
    UtmPanel,
    ServicesStates,
    OpRejectionDialog,
    WhiteListPanel,
    FeatureSelectionPopup,
    IcaoLocationsConfigDialog,
    RestrictAorDialog,
    AltitudeFilteredFeaturesDialog
  },

  computed: {
    getThemeMode() {
      return (this.$store.getters.getIsDayThemeSelected) ? 'day' : 'night';
    },
    colorPickerOpen() {
      return this.$store.state.colorPickerStore.isOpen;
    },
    displayDirectoryPanel() {
      return AppConfig.ui.showDirectoryPanel;
    },
    displayProgressIndicator() {
      return this.$store.state.workspaceStore.progressIndicator;
    },
    dateIsChanged() {
      return TimeService.isInHistoryMode();
    },
    displayDialog() {
      return this.$store.state.dialogStore.isOpen;
    },
    displayDroneAlertDialog() {
      return this.$store.state.droneAlertDetails.isOpen;
    },
    displayAlertReplyDialog() {
      return this.$store.state.alertReplyDetails.isOpen;
    },
    isAuthenticated() {
      return (AppConfig.ui.authRequired) ? this.$store.state.userStore.authInstance && PermissionUtils.isSmartSISUser() : true;
    },
    displayFirFilters() {
      return this.$store.state.configurationStore.displayFirFilters;
    },
    displayUTMPanel() {
      return this.$store.getters.isGivenTabOpened(HudTabName.UTM_PANEL);
    },
    isOpen() {
      return this.$store.getters.isGivenTabOpened(HudTabName.CONFIGURATION);
    },
    forcedRefresh() {
      return this.$store.state.informationStore.activeTab ? this.$store.state.informationStore.width : null;
    },
    showTimeSlider() {
      return AppConfig.timeSlider;
    },
    servicesStateIsOpen() {
      return this.$store.state.hudStore.health.servicesStateIsOpen;
    },
    enableTrainingMode() {
      return AppConfig.ui.enableTrainingMode;
    }
  },

  methods: {
    getLayerTitle() {
      return TimeService.currentTimeSliderTime().valueOf() > TimeService.currentUtcTime() ?
        this.$i18n.t('labels.map.futureView') : this.$i18n.t('labels.map.pastView');
    },
    update() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      this.cleanup();

      WebsocketSubscriptionService.websocketSubscribe(this.$store);
    },

    cleanup() {
      this.$store.dispatch(A.MAP_WIPE);
      this.$store.dispatch(A.INFORMATION_WIPE);
      this.$store.dispatch(A.MAP_REFRESH_GEOSERVER_LAYERS);
    },

    hasSmartSISRole(userRoles) {
      return (
        userRoles.includes(Roles.USER) ||
        userRoles.includes(Roles.SUPERVISOR) ||
        userRoles.includes(Roles.SUPER_ADMIN) ||
        userRoles.includes(Roles.T_CHART_GENERATOR)
      );
    },
  },

  mounted: function () {
    Logger.debug(this.$i18n.t('logMessages.applicationMounted'));

    if (AppConfig.ui.authRequired) {
      const keycloak = new KeyCloak(AppConfig.auth);
      keycloak.init({onLoad: 'check-sso', checkLoginIframeInterval: 1}).then(() => {
        if (keycloak.authenticated) {
          const clientResourceAccess = keycloak.resourceAccess[AppConfig.auth.clientId];
          const userRoles = clientResourceAccess ? clientResourceAccess.roles : undefined;
          if (!userRoles || !this.hasSmartSISRole(userRoles)) {
            Logger.error(this.$i18n.t('logMessages.noSmartSisPermission'));
            alert(this.$i18n.t('errorMessages.smartSisPermissionError') + " " +
              this.$i18n.t('logMessages.automaticallyLoggedOut'));
            keycloak.logout();
          } else {
            this.$store.dispatch(A.AUTH_UPDATE_TOKEN, keycloak);

            keycloak.loadUserProfile().then(userProfile => {
              userProfile['userRoles'] = userRoles;
              this.$store.dispatch(A.AUTH_SET_USER_PROFILE, userProfile);
              Logger.debug(this.$i18n.t('logMessages.userLoggedIn'));

              DocumentsService.getAllDocuments();
              this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
              AssetUtils.loadAssets(() => {
                this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
                AssetUtils.setInitialRole((() => {
                  this.$store.dispatch(A.MAP_ADD_GEOSERVER_LAYERS);
                }));
              });
            });

            setInterval(() => {
              keycloak.updateToken(20).catch(() => keycloak.logout());
              this.$store.dispatch(A.AUTH_UPDATE_TOKEN, keycloak);
            }, 20000);
          }
        } else {
          keycloak.login({idpHint: AppConfig.auth.idpHint});
        }
      });
    }

    const self = this;
    this.$store.watch(
      () => {
        return this.$store.state.viewStore.currentViewId;
      },
      () => {
        if (this.$store.state.webSocketStore.isConnected) {
          self.update();
        }
      },
      {deep: true}
    );

    this.$store.watch(
      () => {
        return this.$store.state.updateStore.uomChange;
      },
      () => {
        if (this.$store.state.webSocketStore.isConnected) {
          self.update();
        }
      },
      {deep: true}
    );

    this.$store.watch(
      () => {
        return this.$store.state.updateStore.doUpdate;
      },
      (shouldDoUpdate) => {
        if (shouldDoUpdate) {
          AssetUtils.loadAssets(() => {
            AssetUtils.setInitialRole(() => {
              this.update();
              this.$store.dispatch(A.MAP_ADD_GEOSERVER_LAYERS);
              this.$store.dispatch(A.UPDATE_FINISHED_UPDATING);
            });
          });
        }
      },
    );

    this.$store.watch(
      () => {
        return this.$store.state.webSocketStore.reConnectCounter;
      },
      (value) => {
        if (value > 1) {
          this.update();
        }
      },
    );

    this.$store.watch(
      () => {
        return this.$store.state.themeStore.currentTheme.baseMap;
      },
      (value) => {
        this.$store.dispatch(A.EMBEDDED_MAP_UPDATE_BACKGROUND, value);
      }
    );

    const zoomValue = AppConfig.ui.applicationZoomLevel;
    const userAgentInfo = window.clientInformation.appVersion;
    const maxTouchPoints = window.clientInformation.maxTouchPoints;
    if (userAgentInfo.indexOf("iPad") !== -1 ||
      (userAgentInfo.indexOf("Mac") !== -1 && maxTouchPoints && maxTouchPoints > 2)) {
      document.documentElement.style.fontSize = 16 * zoomValue + "px";
    }
    document.documentElement.style.zoom = zoomValue;

    navigator.connection.onchange = async function () {
      await NetworkUtils.checkOnlineStatus();
    }
  }
};
</script>

<style src="./app.css"></style>