<template>
  <div v-if="isOpen" id="airspace-reservation-request-approve-dialog-container" class="dialogContainer">
    <div class="dialog modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div id="airspace-reservation-request-approve-dialog-modal-content" class="modal-content smartSISPanel">
          <div id="airspace-reservation-request-approve-dialog-modal-header" class="modal-header">
            <h5 id="airspace-reservation-request-approve-dialog-modal-title" class="modal-title">
              {{ $t('confirmationMessages.title') }}
            </h5>
          </div>
          <div id="airspace-reservation-request-approve-dialog-modal-body" class="modal-body errorBody">
            <PropertyDropDown title="labels.airspaceReservationRequest.airspaceReservationRequestRestrictionType"
                              propName="restrictionType"
                              :options="geozoneRestrictionTypes"
                              :get-value="getValue"
                              :set-value="setValue"
                              :is-mandatory="true"
                              :is-valid="true"
                              id="airspace-reservation-request-restriction-type"/>
            {{ $t("confirmationMessages.airspaceReservationRequestApprove") }}
          </div>
          <div id="airspace-reservation-request-approve-dialog-modal-footer" class="modal-footer">
            <div>
              <button id="airspace-reservation-request-approve" class="btn btn-danger"
                      :disabled="isApproveButtonDisabled"
                      @click="approveAirspaceReservationRequest">
                {{ $t('labels.common.approve') }}
              </button>
              <button id="airspace-reservation-request-approve-dialog-cancel" class="btn btn-primary"
                      @click="cancelAirspaceReservationRequestApproval">
                {{ $t('labels.common.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import A from "../../constants/actions";
import {mapGetters} from 'vuex';
import PropertyDropDown from "../../common/PropertyDropDown.vue";
import Logger from "../../utils/LoggerUtils";
import PopupUtils from "../../utils/PopupUtils";
import DialogUtils from "../../utils/DialogUtils";
import AirspaceReservationRequestService from "../../services/airspaceReservationRequestService";
import GeozoneModel from "../../model/geozone";
import AjaxUtils from "../../utils/AjaxUtils";

export default {
  name: "AirspaceReservationRequestApproveDialog",
  components: {PropertyDropDown},
  data() {
    return {
      selectedRestrictionType: null,
    }
  },
  computed: {
    ...mapGetters({
      isOpen: 'isAirspaceReservationRequestApproveDialogOpen',
      airspaceReservationRequestId: "airspaceReservationRequestId",
    }),
    geozoneRestrictionTypes() {
      return GeozoneModel.restrictionTypes;
    },
    isApproveButtonDisabled() {
      return this.selectedRestrictionType === null;
    }
  },
  methods: {
    getValue() {
      return this.selectedRestrictionType;
    },
    setValue(propName, newValue) {
      this.selectedRestrictionType = newValue;
    },
    approveAirspaceReservationRequest() {
      AirspaceReservationRequestService.approveAirspaceReservationRequest(
        this.airspaceReservationRequestId, this.selectedRestrictionType
      )
        .then(() => this.successCallback())
        .catch(error => this.errorCallback(AjaxUtils.parseErrorMessage(error)));
      this.selectedRestrictionType = null;
    },
    cancelAirspaceReservationRequestApproval() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      this.selectedRestrictionType = null;
      this.$store.dispatch(A.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_CLOSE);
    },
    successCallback() {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      this.$store.dispatch(A.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_CLOSE);
      Logger.info(this.$i18n.t('logMessages.airspaceReservationRequestApproved'));
      PopupUtils.success(this.$i18n.t('popupMessages.airspaceReservationRequestApproved'));
    },
    errorCallback(error) {
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      this.$store.dispatch(A.AIRSPACE_RESERVATION_REQUEST_APPROVE_DIALOG_CLOSE);
      Logger.error(this.$i18n.t('errorMessages.airspaceReservationRequestApproveError') + error);
      DialogUtils.errorNotification(this.$i18n.t('errorMessages.airspaceReservationRequestApproveError') + error);
    }
  }
}
</script>

<style src="./dialog.css"/>