<template>
  <div v-if="customMessageCard"
       :id="messageId"
       class="info-panel-custom-message-card"
  >
    <component :is="customMessageCard" :message="message" :select-action-callback="selectMessage"
               :get-style="getStyle"/>
  </div>
  <div
    v-else
    :class="['card', 'smartSISPanel', getMessageBackground(message)].join(' ')"
    :id="messageId"
    :key="this.$store.state.informationStore.forceRenderCount"
    :style="getStyle(message)"
  >
    <div class="card-text message-card-container mx-0">
      <div :class="['message-card-left-side-container', this.isNotUTMFeature ? 'width-80' : ''].join(' ')"
           v-on:click="selectMessageIfNeeded(message)">
        <div class="messageContainer text-left align-middle" v-html="this.formatText(message)"/>
      </div>
      <MessageControlContainer :message="message" :removable="removable" v-if="isNotUTMFeature"/>
      <div class="clear"/>
    </div>
    <ProgressBar :message="message" v-if="isNotUTMFeature"/>
  </div>
</template>
<script>

import A from '../../constants/actions';
import AppConfig from '../../config/appConfig';
import Features from '../../config/features';
import FeatureUtils from '../../utils/FeatureUtils';
import FeatureType from '../../constants/featureType';
import MessageUtils from '../../utils/MessageUtils';
import HighlightedFeatureService from '../../services/highlightedFeatureService';
import {mapGetters} from 'vuex';
import MessageControlContainer from "./MessageControlContainer";
import ProgressBar from "./ProgressBar";
import DialogUtils from "../../utils/DialogUtils";
import DroneState from "../../constants/droneState";
import CustomMessageCardComponentsConfig from '../../config/customMessageCardComponentsConfig';
import GlobalConfigurationUtils from "../../utils/GlobalConfigurationUtils";
import GlobalConfigurationIdConfig from "../../config/GlobalConfigurationIdConfig";

export default {
  name: 'Message',
  props: ['message', 'removable'],

  components: {
    MessageControlContainer,
    ProgressBar
  },

  data: () => {
    return {
      inDOM: false,
      clickTimeout: null,
    }
  },

  watch: {
    isMessageSelected: {
      immediate: true,
      handler() {
        this.setFocus();
        this.setSelectedAttribute();
      }
    }
  },

  computed: {
    ...mapGetters({
      activeView: 'activeView',
      isDisplayOfDronesEnabled: 'getDisplayOfDrones',
      selectedFeatureId: 'selectedFeatureId',
    }),
    isNotUTMFeature() {
      return [
        FeatureType.OPERATION_PLAN_PROPOSED,
        FeatureType.OPERATION_PLAN_ACCEPTED,
        FeatureType.OPERATION_PLAN_ACTIVATED,
        FeatureType.OPERATION_PLAN_OUTLOOK,
        FeatureType.DRONE_ALERT,
        FeatureType.ALERT_REPLY,
        FeatureType.DRONES,
        FeatureType.GEOZONE,
        FeatureType.GEOZONE_OUTLOOK,
        FeatureType.ICAO_FLIGHT_PLANS,
        FeatureType.WEATHER_DATA
      ].indexOf(this.message.featureType) === -1;
    },
    messageId() {
      return this.message.id;
    },
    groupedMessageIds() {
      return this.message.elements ? this.message.elements.map(msg => msg.id) : [];
    },
    feature() {
      return Features.getFeature(this.message.featureType);
    },
    customMessageCard() {
      return CustomMessageCardComponentsConfig.getCustomComponent(this.message);
    },
    isMessageSelected() {
      const selectedMessageId = this.$store.getters.selectedFeatureId;
      return this.messageId === selectedMessageId || this.groupedMessageIds.includes(selectedMessageId);
    }
  },

  methods: {
    isActive(layer) {
      return this.activeView.dynamicLayers.includes(layer);
    },
    isDisplayedOnMap(message) {
      const isUpcomingDronePlan = message.featureType === FeatureType.OPERATION_PLAN_OUTLOOK;
      const displayUpcomingPlans = this.$store.getters.getDisplayOfUpcomingPlansOnMap();
      if (isUpcomingDronePlan && !displayUpcomingPlans) {
        return false;
      }
      return true;
    },
    selectMessageIfNeeded(message) {
      const featuresWithoutMessageSelection = [FeatureType.ICAO_FLIGHT_PLANS, FeatureType.WEATHER_DATA];
      if (featuresWithoutMessageSelection.indexOf(this.message.featureType) === -1) {
        this.selectMessage(message);
      }
    },
    selectMessage(message) {
      if (this.isMessageAConformantDrone(message) && !this.isDisplayOfDronesEnabled) {
        DialogUtils.errorNotification(this.$i18n.t('errorMessages.messageHighlightError'));
        return;
      }
      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
        if (this.isActive(message.featureType) && !message.generated && this.isDisplayedOnMap(message)) {
          HighlightedFeatureService.flyToAndSelect(message);
        }
      } else {
        this.clickTimeout = setTimeout(() => {
          this.doSelectMessage(message)
        }, 400);
      }
    },
    doSelectMessage(message) {
      if (!message.generated) {
        HighlightedFeatureService.selectFeature(message);
        this.$store.dispatch(A.MAP_REFRESH);
      }
      this.clickTimeout = null;
    },
    getMessageBackground(msg) {
      if (msg.generated) return 'bg-secondary';
      return '';
    },
    getStyle(msg) {
      // unseen message highlight
      if (!msg.seen) {
        const featureHighlightColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
          GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.HIGHLIGHT_INFO_PANEL_NEW_MESSAGE
        );
        const style = FeatureUtils.Styling.computeColorAndBgColorStyleByBgColor(featureHighlightColor);
        if (msg.color) {
          style['background-color'] = msg.color;
        }
        return style;
      }

      const style = (this.feature && this.feature.getInformationPanelMessageStyle) ? this.feature.getInformationPanelMessageStyle(msg, {}) : {};

      if (this.isMessageSelected && this.message.color) {
        style['background-color'] = this.message.color;
      }

      const color = FeatureUtils.Styling.getFeatureColor(msg.featureType);
      if (!style['background-color'] && this.isMessageSelected && color) {
        style['background-color'] = 'rgba(' + [color.r, color.g, color.b, color.a].join(',') + ')';
      }

      const informationPanelHighlightColor = GlobalConfigurationUtils.getDisplayConfigColorByIdAndByCurrentTheme(
        GlobalConfigurationIdConfig.DISPLAY_CONFIGURATION_IDS.HIGHLIGHT_INFO_PANEL_SELECTED_MESSAGE
      );
      if (informationPanelHighlightColor !== undefined && this.isMessageSelected) {
        style['color'] = FeatureUtils.Styling.getOppositeColorOfGivenColor(informationPanelHighlightColor);
        style['background-color'] = FeatureUtils.Styling.toRgba(informationPanelHighlightColor);
      }

      return style;
    },
    formatText(message) {
      if (this.feature.getInformationPanelMessageFormat) {
        return this.feature.getInformationPanelMessageFormat(this.message);
      }
      const text = message.tac || '-text missing-';
      /* eslint-disable no-control-regex */
      return text.trim().replace(new RegExp('[\n]', 'g'), '<br>').replace(new RegExp('[ ]', 'g'), '&nbsp').replace(new RegExp(MessageUtils.SPACE(), 'g'), ' ');
    },
    setMessageSeen() {
      if (this.inDOM) {
        this.$store.dispatch(A.INFORMATION_PANEL_MESSAGE_SEEN, this.message);
      }
    },
    setFocus() {
      const focusedMessageId = this.$store.getters.selectedFeatureId;
      if (focusedMessageId === this.messageId) {
        document.getElementById(this.messageId)?.scrollIntoView();
      }
    },
    setSelectedAttribute() {
      this.isMessageSelected ?
        document.getElementById(this.messageId)?.setAttribute('selected', '') :
        document.getElementById(this.messageId)?.removeAttribute('selected');
    },
    isMessageAConformantDrone(message) {
      return message.featureType === FeatureType.DRONES && message.state === DroneState.CONFORMANT;
    },
  },

  mounted: function () {
    this.inDOM = true;
    if (!this.message.seen) {
      setTimeout(this.setMessageSeen, AppConfig.ui.highlightTimeout);
    }
    this.setFocus();
    this.setSelectedAttribute();
  },

  beforeUnmount: function () {
    this.inDOM = false;
  }

};
</script>
<style src="./informationPanel.css"/>