<template>
  <div class="col-10 p-0">
    <div>
      <div class="form-group row w-100 mt-3 ml-0">
        <div class="col aorActiveProperty row">
          <ToggleButtonItem
            id="aor-active"
            :disabled="(!isSupervisor && !isSuperAdmin) || isUtmConfigDisabled"
            :getValue="this.getResponsibilityAreaProperty"
            :propName="'aorEnabled'"
            :setValue="this.setResponsibilityAreaProperty"
            :title="this.$i18n.t('labels.common.active')"
            :values="toggle"
            class="col-8"
          />
          <button class="btn btn-primary mt-2 mb-2 aor-edit-geometry-button" @click="toggleEditGeometry"
                  :disabled="(!isSupervisor && !isSuperAdmin) || isUtmConfigDisabled || isReadOnlyUser">
            {{ $t('labels.configurationPanel.utm.editGeometry') }}
          </button>
        </div>
      </div>
      <div class="utmConfig form-group row w-100">
        <div class="utm-config-aor-properties">
          <div class="utm-config-aor-property-group col-4 ml-3">
            <ToggleButtonItem
              id="max-simultaneous-operations-enabled"
              :disabled="(!isCapacityManager && !isSuperAdmin) || isUtmConfigDisabled"
              :getValue="this.getResponsibilityAreaProperty"
              :propName="'maxSimultaneousOperationsEnabled'"
              :setValue="this.setResponsibilityAreaProperty"
              :title="$t('labels.configurationPanel.utm.maxSimultaneousOperations')"
              :values="toggle"
            />
            <PropertyInput
              :disabled="isMaxSimultaneousOperationsDisabled"
              :inputType="'number'"
              :title="$t('labels.configurationPanel.utm.maxSimultaneousOperations')"
              :prop-name="'maxSimultaneousOperations'"
              :get-value="getResponsibilityAreaProperty"
              :set-value="setInputProperty"
              :input-class="'form-inline'"
              :is-valid="validateField('maxSimultaneousOperations')"
              id="max-simultaneous-operations"
            />
            <ToggleButtonItem
              id="max-noise-level-enabled"
              v-if="displayNoiseLevel"
              :disabled="!isSuperAdmin || isUtmConfigDisabled"
              :get-value="this.getResponsibilityAreaProperty"
              :prop-name="'maxNoiseLevelEnabled'"
              :set-value="this.setResponsibilityAreaProperty"
              :title="$t('labels.configurationPanel.utm.maxNoiseLevel')"
              :values="toggle"
            />
            <PropertyInput
              v-if="displayNoiseLevel"
              :disabled="isMaxNoiseLevelDisabled"
              :input-type="'number'"
              :title="$t('labels.configurationPanel.utm.maxNoiseLevel')"
              :prop-name="'maxNoiseLevel'"
              :get-value="getResponsibilityAreaProperty"
              :set-value="setInputProperty"
              :input-class="'form-inline'"
              :is-valid="validateField('maxNoiseLevel')"
              id="max-noise-level"
            />
          </div>
          <div class="utm-config-aor-property-group col-4">
            <ToggleButtonItem
              id="automatic-approval-enabled"
              :disabled="(!isApprovalAutomationManager && !isSuperAdmin) || isUtmConfigDisabled"
              :getValue="this.getResponsibilityAreaProperty"
              :propName="'automaticApprovalEnabled'"
              :setValue="this.setResponsibilityAreaProperty"
              :title="$t('labels.configurationPanel.utm.automaticApproval')"
              :values="toggle"
            />
            <ToggleButtonItem
              id="automatic-takeoff-approval-enabled"
              :disabled="(!isApprovalAutomationManager && !isSuperAdmin) || isUtmConfigDisabled"
              :getValue="this.getResponsibilityAreaProperty"
              :propName="'automaticTakeoffApprovalEnabled'"
              :setValue="this.setResponsibilityAreaProperty"
              :title="$t('labels.configurationPanel.utm.automaticTakeOffApproval')"
              :values="toggle"
            />
            <ToggleButtonItem
              id="automatic-rejection-enabled"
              :disabled="(!isApprovalAutomationManager && !isSuperAdmin) || isUtmConfigDisabled"
              :getValue="this.getResponsibilityAreaProperty"
              :propName="'automaticRejection'"
              :setValue="this.setResponsibilityAreaProperty"
              :title="$t('labels.configurationPanel.utm.automaticRejection')"
              :values="toggle"
            />
          </div>
          <div class="utm-config-aor-property-group col-4">
            <h5 class="vertical-location">{{ $t('labels.configurationPanel.utm.verticalLocation') }}</h5>
            <PropertyInput
              :disabled="(!isSupervisor && !isSuperAdmin) || isUtmConfigDisabled"
              :inputType="'number'"
              :title="maximumAltitudeLabel"
              :prop-name="'maximumAltitude'"
              :get-value="getResponsibilityAreaProperty"
              :set-value="setInputProperty"
              :input-class="'form-inline form-inline-altitude'"
              :is-valid="validateField('maximumAltitude')"
              id="max-altitude"/>
            <PropertyInput
              :disabled="(!isSupervisor && !isSuperAdmin) || isUtmConfigDisabled"
              :inputType="'number'"
              :title="minimumAltitudeLabel"
              :prop-name="'minimumAltitude'"
              :get-value="getResponsibilityAreaProperty"
              :set-value="setInputProperty"
              :input-class="'form-inline form-inline-altitude'"
              :is-valid="validateField('minimumAltitude')"
              id="min-altitude"/>
          </div>
        </div>
      </div>
    </div>
    <div class="utm-config-error-message" v-if="!isValid" v-html="errorMessage"/>
    <SavePanel
      :cancelAction="cancel"
      :isEditable="isEditable"
      :isModified="isModified"
      :isValid="isValid"
      :saveAction="save"
    />
  </div>
</template>

<script>
import A from '../../../../constants/actions';
import AppConfig from '../../../../config/appConfig';
import DialogUtils from '../../../../utils/DialogUtils';
import Logger from '../../../../utils/LoggerUtils';
import PropertyInput from '../../../../common/PropertyInput.vue';
import PermissionUtils from '../../../../utils/PermissionUtils';
import SavePanel from '../../save-panel/SavePanel.vue';
import PopupUtils from '../../../../utils/PopupUtils';
import ToggleButtonItem from '../../../../common/ToggleButtonItem.vue';
import UtmConfigurationService from '../../../../services/smartsisUtmConfigurationService';
import {mapGetters} from "vuex";
import RespAreaValidationConfig from "../../../../config/ResponsibilityAreaPropertyValidationConfig";
import GeometryImportForm from "../../../information-panel/geometry-import/GeometryImportForm.vue";
import GeoJSON from "ol/format/GeoJSON";
import FeatureType from "../../../../constants/featureType";

export default {
  name: 'utmConfigurationEditor',
  props: ['isUtmConfigDisabled'],
  data() {
    return {
      errorMessage: null,
    }
  },
  components: {
    PropertyInput,
    SavePanel,
    ToggleButtonItem
  },
  computed: {
    ...mapGetters({
      responsibilityAreaProperty: 'getResponsibilityAreaProperty',
    }),
    isSuperAdmin() {
      return PermissionUtils.isSuperAdmin();
    },
    isSupervisor() {
      return PermissionUtils.isSupervisor();
    },
    isCapacityManager() {
      return PermissionUtils.isCapacityManager();
    },
    isApprovalAutomationManager() {
      return PermissionUtils.isApprovalAutomationManager();
    },
    isEditable() {
      return true;
    },
    isModified() {
      const currentConfiguration = this.$store.state.utmConfigurationStore.currentConfiguration;
      const originalConfiguration = this.$store.state.utmConfigurationStore.originalConfiguration;
      return PermissionUtils.isAllowedToEditUtmConfigurations() ?
        JSON.stringify(currentConfiguration) !== JSON.stringify(originalConfiguration) : false;
    },
    isValid() {
      const invalidFields = RespAreaValidationConfig.validationConfig
        .filter(config => !config.isValid(this.responsibilityAreaProperty));
      return invalidFields.length === 0;
    },
    toggle() {
      return {
        on: {
          display: this.$i18n.t('labels.common.enabled'),
          value: true
        },
        off: {
          display: this.$i18n.t('labels.common.disabled'),
          value: false
        }
      }
    },
    isMaxSimultaneousOperationsDisabled() {
      return !this.responsibilityAreaProperty('maxSimultaneousOperationsEnabled') ||
        (!this.isCapacityManager && !this.isSuperAdmin) || this.isUtmConfigDisabled;
    },
    maximumAltitudeLabel() {
      let altitudeUom = this.getResponsibilityAreaProperty('altitudeUom');
      const altitudeReferenceType = this.getResponsibilityAreaProperty('verticalReferenceType');
      return this.$i18n.t('labels.configurationPanel.utm.maximumAltitude') + ` (${altitudeUom} ${altitudeReferenceType})`;
    },
    minimumAltitudeLabel() {
      let altitudeUom = this.getResponsibilityAreaProperty('altitudeUom');
      const altitudeReferenceType = this.getResponsibilityAreaProperty('verticalReferenceType');
      return this.$i18n.t('labels.configurationPanel.utm.minimumAltitude') + ` (${altitudeUom} ${altitudeReferenceType})`;
    },
    isMaxNoiseLevelDisabled() {
      return !this.responsibilityAreaProperty('maxNoiseLevelEnabled') || !this.isSuperAdmin || this.isUtmConfigDisabled;
    },
    displayNoiseLevel() {
      return AppConfig.ui.displayNoiseLevel;
    },
    isReadOnlyUser() {
      return PermissionUtils.isReadOnlyUser();
    }
  },
  methods: {
    getResponsibilityAreaProperty(propName) {
      return this.responsibilityAreaProperty(propName);
    },
    setResponsibilityAreaProperty(name, value) {
      if (name === 'automaticTakeoffApprovalEnabled') {
        DialogUtils.confirmOperation({
          message: this.$i18n.t('confirmationMessages.utmConfigurationChangeAutoTakeOffClearance'),
          yesAction: () => {
            this.$store.dispatch(A.UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY, {name, value});
          }
        });
      } else {
        this.$store.dispatch(A.UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY, {name, value});
      }
      this.constructErrorMessage();
    },
    setInputProperty(name, value) {
      value = isNaN(value) || value < 0 ? null : value;
      this.$store.dispatch(A.UTM_CONFIG_UPDATE_RESPONSIBILITY_AREA_PROPERTY, {name, value});
      this.constructErrorMessage();
    },
    save() {
      PermissionUtils.performActionIfAllowed(!PermissionUtils.isAllowedToEditUtmConfigurations(), this.performSaveAction);
    },
    performSaveAction() {
      const configuration = JSON.parse(JSON.stringify(this.$store.state.utmConfigurationStore.currentConfiguration));
      this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
      UtmConfigurationService.saveUtmConfiguration({
        configuration,
        successCallback: () => {
          Logger.info(this.$i18n.t('logMessages.utmConfigurationSaved'));
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          this.$store.dispatch(A.UTM_CONFIG_SAVE);
          this.updateStaticResponsibilityAreas(configuration);
          PopupUtils.success(this.$i18n.t('popupMessages.utmConfigurationSaved'));
        },
        errorCallback: (error) => {
          this.$store.dispatch(A.PROGRESS_INDICATOR_TOGGLE);
          Logger.error(this.$i18n.t('errorMessages.utmConfigurationSaveError') + ' ' + error);
          DialogUtils.errorNotification(this.$i18n.t('errorMessages.utmConfigurationSaveError') + ' ' + error);
        }
      });
    },
    updateStaticResponsibilityAreas(configuration) {
      this.$store.dispatch(A.UPDATE_CURRENT_RESPONSIBILITY_AREA_FOR_CONFIG, configuration);
    },
    cancel() {
      this.$store.dispatch(A.UTM_CONFIG_CANCEL_EDIT);
    },
    validateField(propName) {
      const fieldValidationConfig = RespAreaValidationConfig.getConfigForField(propName);
      return fieldValidationConfig.isValid(this.responsibilityAreaProperty);
    },
    constructErrorMessage() {
      const invalidFields = RespAreaValidationConfig.validationConfig
        .filter(config => !config.isValid(this.responsibilityAreaProperty));
      let inputError = this.constructInputError(invalidFields);
      inputError = inputError === '' ? '' : inputError + '<br>';
      this.errorMessage = inputError + this.constructApprovalErrorMessage(invalidFields);
    },
    constructInputError(invalidFields) {
      const invalidInputIndex = invalidFields.findIndex(field => field.type === 'value');
      return invalidInputIndex !== -1 ? this.$i18n.t('errorMessages.utmConfigurationError') : '';
    },
    constructApprovalErrorMessage(invalidFields) {
      const invalidApprovalIndex = invalidFields.findIndex(field => field.type === 'approval');
      return invalidApprovalIndex !== -1 ? this.$i18n.t('errorMessages.utmApprovalConfigurationError') : '';
    },
    toggleEditGeometry() {
      DialogUtils.showCustomComponentDialog(GeometryImportForm, {
        onSave: (g) => {
          this.$store.dispatch(A.MAP_UPDATE_LAYER_FEATURE_GEOMETRY, {
            layerId: FeatureType.RESPONSIBILITY_AREA,
            featureId: this.$store.getters.getCurrentResponsibilityArea.id,
            geometry: new GeoJSON().readGeometry(g).transform('EPSG:4326', 'EPSG:3857')
          });
          this.setResponsibilityAreaProperty('geometry', g);
          this.setResponsibilityAreaProperty('extendedGeometry', g);
        },
        onClose: () => DialogUtils.hideDialog(),
      });
    },
  }
}
</script>
<style src="./utmEditor.css"></style>